
import { mapGetters } from 'vuex'
import surveyForm from '~/mixins/survey-form'

export default {
    mixins: [surveyForm],
    middleware: ['isLoggedIn'],
    computed: {
        ...mapGetters(['canAgent', 'yearSummaryId']),
    },
}
