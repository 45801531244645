/* eslint-disable import/no-named-as-default-member */
import Vue from 'vue'
import _ from 'lodash'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { carriers } from '~/util/carriers'
import { fromDob } from '~/util/date'
import { getActiveBreakpoint } from '~/util/mediaQuery'
import { money } from '~/util/string'
import CommonMixin from '~/mixins/common'
import CardMixin from '~/mixins/card'

// const BENEFIT_COST_SHARE_RE = /(\$(?<dollars>[\d,]+(\.\d{2})?)|(?<percent>\d+)%|\$(?<mindollars>[\d,]+)-(?<maxdollars>[\d,]+)((\.\d{2})?)|(?<minpercent>\d+)-(?<maxpercent>\d+)%)( (copay|coinsurance))?/
const BENEFIT_COST_SHARE_RE = /(\$(?<mindollars>[\d,]+)-(?<maxdollars>[\d,]+)((\.\d{2})?)|(?<minpercent>\d+)-(?<maxpercent>\d+)%|\$(?<dollars>[\d,]+(\.\d{2})?)|\$(?<indollars>[\d,]+(\.\d{2})?\s*In-network)|(?<percent>\d+)%)( (copay|coinsurance))?/
const IN_NETWORK_BENEFIT_COST_SHARE_RE = /\$(?<indollars>[\d,]+(\.\d{2})?\s*In-network)/

const BENEFITS_CATEGORIES_ORDER = [
    'DEDUCTIBLE',
    'DOCTOR_VISITS',
    'HOSPITAL', // <-- new category
    'DIAGNOSTIC_PROCEDURES',
    // todo: @john, combined '__AMBULANCE' service
    'EMERGENCY_CARE',
    'MEDICARE_PART_B',
    // todo: @john, combined '__MENTAL_HEALTH' service, renamed it to General
    'MENTAL_HEALTH',
    'REHABILITATION_SERVICES',
    'MEDICAL_EQUIPMENT',
    'FOOT_CARE',

    'OUT_OF_POCKET', // <-- new category
    'VISION',
    'HEARING',
    'BASIC_DENTAL', // <--renamed 'PREVENTIVE_DENTAL'
    'COMPREHENSIVE_DENTAL',

    'DISCOUNT',

    // todo: @john, you didn't mention these?
    'OTHER',
]

const CARRIERS_REPLACEMENTS = [
    [/AARP Medicare Supplement Plans, insured by UnitedHealthcare/i, 'UnitedHealthcare'],
    [/Humana Insurance Company/i, 'Humana'],
    [/BLUE CROSS AND BLUE SHIELD OF MASSACHUSETTS, INC./i, 'Blue Cross Blue Shield of Massachusetts'],
    [/FALLON HEALTH AND LIFE ASSURANCE CO. INC./i, 'Fallon Health'],
]

const PLAN_NAME_REPLACEMENTS = [
    // todo: rm, Agents can now modify Products in SF which will add an entry in PlanExtra agumentation table

    [/AARP Medicare Supplement Plans, insured by UnitedHealthcare/i, 'United AARP Supplemental'],
    [/Harvard Pilgrim Health Care Plan MA_SUPP2/i, 'Harvard Pilgrim Supplemental Plan 1A'],
    [/Harvard Pilgrim Health Care Plan MA_SUPP1/i, 'Harvard Pilgrim Supplemental Plan 1'],
    [/Harvard Pilgrim Health Care Plan MA_CORE/i, 'Harvard Pilgrim Supplemental Plan Core'],
    [/Harvard Pilgrim Health Care/i, 'Harvard Pilgrim'],
    [/MA_SUPP2/i, 'Plan 1A'],
    [/MA_SUPP_1A/i, 'Plan 1A'],
    [/MA_SUPP_2/i, 'Plan 1A'],
    [/MA_SUPP1/i, 'Plan 1'],
    [/MA_SUPP_1/i, 'Plan 1'],
    [/MA_CORE/i, 'Core'],
]

const BENEFITS_CATEGORIES_STEPS_MAP = (() => {
    const mapd = {
        name: 'This is the advantage insurance company.',
        premium: 'This is the monthly premium for your advantage plan.',
        type: 'This plan is considered advantage also known as MAPD',
        carrier: 'This is the name of the insurance company of the advantage plan',
    }
    const ma = {
        name: 'This is the advantage insurance company.',
        premium: 'This is the monthly premium for your advantage plan.',
        type: 'This plan is considered advantage with no-rx also known as MA',
        carrier: 'This is the name of the insurance company of the advantage plan',
    }
    const supp = {
        name: 'This is the supplemental insurance company. You can get your supplement and prescription plan from different companies (and you can mix and match).',
        premium: 'This is the monthly premium for your supplement plan',
        type: 'This plan is considered supplement plan also known as SUPP',
        carrier: 'This is the name of the insurance company of the supplemental plan',
    }
    const pdp = {
        name: 'This is the drug insurance company. You can get your supplement and prescription plan from different companies (and you can mix and match).',
        premium: 'This is the monthly premium for your drug plan',
        type: 'This is a stand alone Part D prescription plan, also known as PDP, that only includes coverage for Prescriptions',
        carrier: 'This is the name of the insurance company of the drug plan',
    }
    const gp = {
        name: 'This is the employer plan insurance company.',
        premium: 'This is the monthly premium for your employer plan',
        type: 'This plan is an employer/group plan also known as GP',
        carrier: 'This is the name of the insurance company of the employer plan',
    }
    const copaysSentence = 'These are the co-pays after you pay your deductible (if you have one)'
    const common = {
        yearly_premium: 'We just took your monthly premium and multiplied it by 12 to help you budget annually',
        full_id: 'These aren\'t as important to help you make a decision but rather serve as "barcodes" to help us identify these plans',
        referral_required: 'This is if the plan requires a specialists referral or not.',
        fee_amount: 'This is a one time fee.',
        drug_info: `
            <div class="text-left">
                This is a breakdown of how each of your medications is covered by the plan.
                <ul>
                    <li>
                        <strong>Tier:</strong> How much you pay for your medications is determined by the tier. Typically the higher the tier, the higher the price.
                    </li>
                    <li>
                        <strong>On Formulary:</strong> This means the medication is covered by the plan.
                    </li>
                    <li>
                        <strong>Prior Authorization:</strong> This means you will need further approval from your provider before the plan will cover this medication.
                    </li>
                    <li>
                        <strong>Quantity Limit/Amount/Days:</strong> This means the plan may have limits on the amount of the medication that they will cover within a certain time period.
                    </li>
                    <li>
                        <strong>Step Therapy:</strong> This means the plan may require that you try a generic medication before they will cover the medication you entered.
                    </li>
                </ul>
            </div>
        `,
        drugs_yearly_by_pharmacy: 'Drug costs shown vary based on the plan and the pharmacy that you use. Contact the plan if you have specific questions about drug costs.',
        drugs_monthly_by_pharmacy: 'Drug costs shown vary based the pharmacy and based on the month of the year. Contact the plan if you have specific questions about drug costs.',
        drugs_monthly_by_drug: 'Drug costs shown are based on your preferred or cheapest found pharmacy, split by month taking into consideration deductibles, gap (gone after 2024) and catastrophic periods if any',
        byKey: {
            GENERAL: 'General plan essential information',
            DEDUCTIBLE: 'This is how much you have to pay out of pocket before insurance pays.',
            DEDUCTIBLE__DRUG_DEDUCTIBLE: 'You\'ll pay 100% of your drug costs until you reach this number. This is a per calendar year and not a per drug cost. This applies to drug tiers',

            HOSPITAL: 'These are your co-pays and deductibles that you have to pay if you\'re hospitalized',
            DOCTOR_VISITS: copaysSentence,
            DIAGNOSTIC_PROCEDURES: copaysSentence,
            EMERGENCY_CARE: copaysSentence,
            MEDICARE_PART_B: copaysSentence,
            MENTAL_HEALTH: copaysSentence,
            REHABILITATION_SERVICES: copaysSentence,
            MEDICAL_EQUIPMENT: copaysSentence,
            FOOT_CARE: copaysSentence,

            VISION: copaysSentence,
            HEARING: copaysSentence,
            BASIC_DENTAL: copaysSentence,
            COMPREHENSIVE_DENTAL: copaysSentence,

            OUT_OF_POCKET: 'This is the most you can spend any year.',
            DISCOUNT: 'Some plans give you a bigger discount if you have a spouse on the same plan or if you live with someone.',
        },
        tiers: 'This is how much you will pay for prescriptions depending on the tier at a preferred pharmacy.',
        providers: 'This will show you if your providers are in network, out of network or undetermined',
        pharmacies: `
            <div class="text-left">
                This is a specific breakdown of your drug costs based on the medications you entered and the pharmacy (or pharmacies) you've selected.
                <p class="mt-2">
                    <strong>In-network:</strong> This means you can fill your prescriptions at this pharmacy
                <br><br>
                    <strong>Preferred:</strong> This means that you'll get the lowest costs because your pharmacy is part of a preferred network
                </p>
            </div>
        `,
    }
    return {
        common,
        mapd,
        supp,
        pdp,
        gp,
        ma
    }
})()

const BENEFITS_CATEGORIES_IMGS_SRCS_MAP = {
    GENERAL: 'Diagnostics.png',
    DEDUCTIBLE: 'Medical Book.png',
    DEDUCTIBLE__DRUG_DEDUCTIBLE: 'Medical Book.png',
    DOCTOR_VISITS: 'Doctor.png',
    HOSPITAL: 'Hospital.png',
    DIAGNOSTIC_PROCEDURES: 'Radiation Theraphy.png',
    EMERGENCY_CARE: 'Ambulance.png',
    MEDICARE_PART_B: 'Blood Cells.png',
    MENTAL_HEALTH: 'Depression.png',
    REHABILITATION_SERVICES: 'Disabled.png',
    MEDICAL_EQUIPMENT: 'Injection.png',
    FOOT_CARE: 'Podiatry.png',
    VISION: 'Optometry.png',
    HEARING: 'Ear.png',
    BASIC_DENTAL: 'Dental.png',
    COMPREHENSIVE_DENTAL: 'Mouth.png',
    OUT_OF_POCKET: 'Price Tag.png',
    DISCOUNT: 'Savings.png',
    PROVIDERS: 'Doctor.png',
    DRUG_TIERS: 'Symptom List.png',
    DRUGS_INFO: 'Online Pharmacy.png',
    DRUGS_YEARLY_BY_PHARMACY: 'Pill Bottle.png',
    DRUGS_MONTHLY_BY_PHARMACY: 'Pill Bottle.png',
    DRUGS_MONTHLY_BY_DRUG: 'Pill Bottle.png',
    DRUGS_COSTS_PER_PHARMACY: 'Pill Bottle.png',
    RATINGS: 'Online Claim.png',
    OTHER: 'Ask A Doctor.png',
    FALLBACK: 'Medical Folder.png',
}

const SUPP_TYPES_NAMES = {
    MA_CORE: 'Core',
    MA_SUPP1: '1',
    MA_SUPP2: '1A',
    MA_SUPP_1A: '1A',
}

const PLAN_TYPE_LABELS = {
    mapd: 'Medicare Advantage Plan',
    supp: 'Medicare Supplement Plan',
    pdp: 'Prescription Drug Plan'
}

const DRUGS_ALTERNATIVES = [
    ['basaglar', 'lantus', 'levemir', 'toujeo', 'tresiba'],
    ['alfuzosin hydrochloride', 'doxazosin', 'tamsulosin hydrochloride'],
    ['alfuzosin', 'doxazosin', 'tamsulosin hydrochloride'],
    ['invokana', 'farxiga', 'jardiance'],
    ['invokamet', 'synjardy', 'xigduo'],
    ['kombiglyze', 'janumet', 'jentadueto'],
    ['movantik', 'lactulose', 'amitiza', 'relistor'],
    ['nexium', 'esomeprazole', 'lansoprazole', 'omeprazole', 'pantoprazole'],
    ['novolin', 'humulin'],
    ['novolog', 'humalog', 'insulin lispro'],
    ['onglyza', 'januvia', 'tradjenta'],
    ['oxycontin', 'xtampza'],
    ['pradaxa', 'eliquis', 'xarelto'],
    ['ventolin', 'proventil', 'albuterol', 'proair'],
    ['qvar', 'arnuity', 'flovent'],
    ['travatan', 'latanoprost', 'lumigan', 'travoprost'],
    ['trazodone hydrochloride', 'zolpidem tartrate', 'belsomra'],
    ['trazodone', 'zolpidem tartrate', 'belsomra'],
]
const DRUGS_ALTERNATIVES_MAP = _.keyBy(DRUGS_ALTERNATIVES, list => list[0])

const SCHEDULE_HELP_TEXT = {
    email: 'Please note this option means you will be matched with one of our enrollment specialists. Once you confirm your request, the specialist will reach out to you directly via email to provide assistance/Medicare guidance within 1 business day. If you would like to speak with a Medicare specialist, please select phone or video.',
    email_in_aep: 'Please note this option means you will be matched with one of our enrollment specialists. Once you confirm your request, the specialist will reach out to you directly via email to provide assistance/Medicare guidance within 1 business day. If you would like to speak with a Medicare specialist, please select phone.',
    phone: 'After you select date & time of your consultation, you will receive an email with a secure link to fill out your pre-consultation survey. Your enrollment specialist will call you on the exact date & time of your consultation.',
    phone_in_aep: 'Our phone consultations are currently booking 2-3 weeks out due to the Medicare annual enrollment period. For a faster recommendation, please request the AEP Survey option and we will follow up with a recommendation via email within 3 business days after you complete the survey.',
    video: 'Please note our videos calls are hosted via Zoom. You do not need to download Zoom for this to work. At the date & time of your consultation, simply click on the link in your consultation confirmation email. You can join the video call via your web browser on a smart phone, tablet, computer or laptop. You will also receive an email with a secure link to fill out your pre-consultation survey.',
    survey_in_aep: 'Once you confirm the AEP Survey request, our system will email you a unique link for you to securely provide us with your medications/providers/pharmacy/health information and our team will follow up with a recommendation via email within 3 business days.',
    email_opt_in: 'By providing us with your email address, you are allowing Doctor\'s Choice to send you information via email. Your email address will not be shared with any third parties.',
    new_client_email: 'Since you haven\'t worked with us before, we would like to talk to you on a phone (or video) call, just this first time.',
    no_slots_note: 'Dear client, our phone/video consultation calendar is booking quickly for this annual enrollment period. If you are looking to review your existing Medicare coverage and you do not see any availability for an appointment, please schedule an email consultation and we will consult you via email.',
}

const DEFAULT_SUMMARY_DATA = {
    survey_updated: null,
    cards: {},
    plans: {},
    group_plans: {},
    config: {
        mode: null,
        aep: null,
        cards: [
            'intro',
            'recap'
        ],
        type: 'FULL',
    }
}

const CARRIER_PROVIDERS_SEARCH_LINKS = { /* todo */ }

const S3_BASE = 'https://doctorschoice.s3.amazonaws.com'

export const state = () => {
    return {
        originalState: null,
        s3Base: S3_BASE,
        soaDocuments: [],
        user: null,
        group: null,
        clients: [],
        crmClient: null,
        matchingClients: [],
        host_url: null,
        effective_host_url: null,
        busy: false,
        year: null,
        years: {},
        lastDonutHoleYear: 2024,
        quotes: [],
        quotesFromCache: false,
        quotesVersion: 2,
        planExtras: [],
        planFiles: [],
        summaryFiles: [],
        summaryScriptsCards: [],
        sources: {},
        summaries: [],
        activityChoices: [],
        activities: [],
        commissions: null,
        magicUrl: null,
        summary: null,
        opportunities: [],
        activeProducts: [],
        alerts: [],
        comparing: {},
        surveys: {},
        survey: null,
        internalGroupName: 'Medicare Checkup',
        defaultSummaryData: DEFAULT_SUMMARY_DATA,
        drugsAlternativesMap: DRUGS_ALTERNATIVES_MAP,
        benefitsCategoriesStepsMap: BENEFITS_CATEGORIES_STEPS_MAP,
        benefitsCategoriesImgsSrcsMap: BENEFITS_CATEGORIES_IMGS_SRCS_MAP,
        scheduleHelpText: SCHEDULE_HELP_TEXT,
        home: {
            href: 'https://www.doctorschoiceusa.com',
            text: 'Doctor\'s Choice'
        },
        support: false,
        crmBase: 'https://doctorschoice.lightning.force.com',
        sidebar: true,
        activeBreakpoint: getActiveBreakpoint(),
        autosave: null,
        autosaveTimeout: null,
        usStates: [],
        county: null,
        groupPlans: [],
        userGroupPlans: [],
        highlightColor: '#ffc078',
    }
}

export const mutations = {
    updateQuoteNetworkProviders (state, { id, inNetworkProviders, outNetworkProviders }) {
        const quoteIndex = _.findIndex(state.quotes, p => p.id === id)
        if (quoteIndex > -1) {
            const quote = state.quotes[quoteIndex]
            quote.in_network_providers = inNetworkProviders
            quote.out_network_providers = outNetworkProviders
            Vue.set(state.quotes[quoteIndex], 'in_network_providers', inNetworkProviders)
            Vue.set(state.quotes[quoteIndex], 'out_network_providers', outNetworkProviders)
        }
    },
    updateQuotePrice (state, { id, price }) {
        const quoteIndex = _.findIndex(state.quotes, p => p.id === id)
        if (quoteIndex > -1) {
            const quote = state.quotes[quoteIndex]
            quote.direct_carrier_annual_premium = price
            Vue.set(state.quotes[quoteIndex], 'direct_carrier_annual_premium', price)
        }
    },
    state (state, STATE) {
        state.originalState = _.cloneDeep(STATE || null)
        if (STATE) {
            for (const k in STATE) {
                state[k] = STATE[k]
            }
        }
    },
    route (state, route) {
        state.route = _.pick(route || {}, ['query', 'params', 'path', 'fullPath'])
    },
    user (state, user) {
        state.user = user
    },
    edge (state, edge) {
        state.user.edge = !!edge
    },
    group (state, group) {
        if (group) {
            group.config = group.config || {}
        }
        state.group = group
    },
    clients (state, clients) {
        state.clients = clients
    },
    crmClient (state, crmClient) {
        state.crmClient = crmClient
    },
    matchingClients (state, clients) {
        state.matchingClients = clients
    },
    soaDocuments (state, soaDocuments = []) {
        state.soaDocuments = soaDocuments
    },
    hostUrl (state, host) {
        state.host_url = host
    },
    effectiveHostUrl (state, host) {
        state.effective_host_url = host
    },
    busy (state, busy) {
        state.busy = busy
    },
    pushAlert (state, payload) {
        if (_.isNil(payload)) {
            return this.commit('clearAlerts')
        }
        if (_.isString(payload)) {
            payload = Object.assign({}, { message: payload })
        }
        if (_.isObject(payload) && payload.message) {
            payload = Object.assign({}, { ...payload, message: payload.message })
        }
        if (!payload.key) {
            payload.key = `page-${uuidv4()}`
        }
        const alert = {
            dismissible: true,
            repeat: false,
            timeout: 10000,
            placement: this.getters.isSize('lte-md') ? 'bottom-full' : 'bottom-right',
            ...(payload || {})
        }
        const { timeout, variant, message, key } = alert
        if (message) {
            if (!alert.repeat && _.find(state.alerts, a => a.message === alert.message)) {
                return
            }
            state.alerts.unshift(alert)
            const q = {}
            q[variant] = message
            if (timeout) {
                q.timeout = timeout
                setTimeout(() => {
                    this.commit('clearAlert', { key })
                }, timeout)
            }
            return
        }
        this.commit('clearAlert', { key })
    },
    clearAlert (state, e) {
        const key = e?.key || e
        const i = _.findIndex(state.alerts, a => a.key === key)
        if (i >= 0) {
            state.alerts.splice(i, 1)
        }
    },
    clearAlerts (state) {
        state.alerts = []
    },
    survey (state, survey = {}) {
        state.survey = survey
        if (survey?.id) {
            Vue.set(state.surveys, survey?.id, survey)
        }
    },
    field (state, { field, value }) {
        Vue.set(state.survey, field, value)
    },
    year (state, year) {
        state.year = year
    },
    years (state, years = {}) {
        state.years = years
    },
    lastDonutHoleYear (state, lastDonutHoleYear) {
        state.lastDonutHoleYear = lastDonutHoleYear
    },
    quotes (state, quotes) {
        state.quotes = quotes
    },
    quotesFromCache (state, fromCache) {
        state.quotesFromCache = !!fromCache
    },
    quotesVersion (state, version) {
        state.quotesVersion = version || 2
    },
    planFiles (state, planFiles) {
        state.planFiles = planFiles || []
    },
    addPlanFile (state, file) {
        const files = state.planFiles.slice(0)
        files.push(file)
        // @vue3-fix
        Vue.set(state, 'planFiles', files)
    },
    editPlanFile (state, file) {
        const files = state.planFiles.slice(0)
        const index = _.findIndex(files, f => f.file_id === file.file_id)
        if (index > -1) {
            files[index] = file
        }
        // @vue3-fix
        Vue.set(state, 'planFiles', files)
    },
    setColor (state, payload) {
        Vue.set(state, payload.isFontColor ? 'fontColor' : 'highlightColor', payload.value)
    },
    setActiveScriptTab (state, tabIndex) {
        Vue.set(state, 'activeScriptTab', tabIndex)
    },
    setActiveSurveyTab (state, tabIndex) {
        Vue.set(state, 'activeSurveyTab', tabIndex)
    },
    removePlanFile (state, file) {
        const files = state.planFiles.slice(0)
        _.remove(files, f => f.file_id === file.file_id)
        // @vue3-fix
        Vue.set(state, 'planFiles', files)
    },
    addGroupPlanFile (state, { plan, file }) {
        const ugp = this.getters.userGroupPlansMap[plan.id]
        if (ugp) {
            const files = (ugp.files || []).slice(0)
            files.push(file)
            this.commit('updateUserGroupPlan', { ...ugp, files })
        }
        const gp = this.getters.groupPlansMap[plan.id]
        if (gp) {
            const files = (gp.files || []).slice(0)
            files.push(file)
            this.commit('updateGroupPlan', { ...gp, files })
        }
    },
    removeGroupPlanFile (state, { plan, file }) {
        const ugp = this.getters.userGroupPlansMap[plan.id]
        if (ugp) {
            const files = (ugp.files || []).slice(0)
            _.remove(files, f => f.file_id === file.file_id)
            this.commit('updateUserGroupPlan', { ...ugp, files })
        }
        const gp = this.getters.groupPlansMap[plan.id]
        if (gp) {
            const files = (gp.files || []).slice(0)
            _.remove(files, f => f.file_id === file.file_id)
            this.commit('updateGroupPlan', { ...gp, files })
        }
    },
    planExtras (state, planExtras) {
        state.planExtras = planExtras
    },
    planExtra (state, payload) {
        const extras = state.planExtras.slice(0)
        const index = _.findIndex(extras, pe => pe.plan_id === payload.plan_id)
        if (index > -1) {
            extras[index] = { ...(extras[index] || {}), ...payload }
        } else {
            extras.push(payload)
        }
        Vue.set(state, 'planExtras', extras)
    },
    summaryScriptsCards (state, cards) {
        state.summaryScriptsCards = cards
    },
    summaryFiles (state, files) {
        Vue.set(state, 'summaryFiles', files)
    },
    addSummaryFile (state, file) {
        const files = state.summaryFiles.slice(0)
        files.push(file)
        // @vue3-fix
        Vue.set(state, 'summaryFiles', files)
    },
    removeSummaryFile (state, file) {
        const files = state.summaryFiles.slice(0)
        _.remove(files, f => f.file_id === file.file_id)
        // @vue3-fix
        Vue.set(state, 'summaryFiles', files)
    },
    sources (state, sources) {
        state.sources = sources
    },
    summaries (state, summaries = []) {
        state.summaries = summaries
    },
    activityChoices (state, activityChoices = []) {
        state.activityChoices = activityChoices
    },
    activities (state, activities = []) {
        state.activities = activities
    },
    commissions (state, commissions = null) {
        state.commissions = commissions
    },
    magicUrl (state, magicUrl) {
        state.magicUrl = magicUrl
    },
    summary (state, summary) {
        state.summary = summary
    },
    summaryData (state, data) {
        Vue.set(state.summary, 'data', data)
    },
    summaryDataCard (state, { card, ...data }, fromDb = false) {
        Vue.set(state.summary.data.cards, card, { ...data, fromDb })
    },
    summaryCards (state, cards = []) {
        Vue.set(state.summary.data.config, 'cards', _.uniq(cards))
    },
    summaryMode (state, mode) {
        Vue.set(state.summary.data.config, 'mode', mode)
    },
    summaryAep (state, aep) {
        Vue.set(state.summary.data.config, 'aep', aep)
    },
    summarySurveyUpdated (state, updated) {
        Vue.set(state.summary.data, 'survey_updated', updated)
    },
    summaryManual (state, manual) {
        Vue.set(state.summary.data, 'manual', manual)
    },
    addSummaryPlan (state, plan) {
        Vue.set(state.summary.data.plans, plan.id, { id: plan.id, recommendation: plan.recommendation })
    },
    removeSummaryPlan (state, plan) {
        const re = new RegExp(`${plan.id}`)
        const idx = _.findIndex(state.summary.data.config.cards, card => re.test(card))
        if (idx > -1) {
            Vue.delete(state.summary.data.config.cards, idx)
        }
        Vue.delete(state.summary.data.plans, plan.id)
    },
    userGroupPlans (state, userGroupPlans) {
        state.userGroupPlans = userGroupPlans
    },
    updateUserGroupPlan (state, userGroupPlan) {
        return this.commit('addUserGroupPlan', userGroupPlan)
    },
    addUserGroupPlan (state, userGroupPlan) {
        if (!userGroupPlan) {
            return
        }
        const userGroupPlans = state.userGroupPlans
        const index = _.findIndex(userGroupPlans, ugp => ugp.id === userGroupPlan.id)
        if (index === -1) {
            userGroupPlans.push(this.getters.parsePlan(userGroupPlan))
            state.userGroupPlans = userGroupPlans
        } else {
            Vue.set(state.userGroupPlans, index, this.getters.parsePlan(userGroupPlan))
        }
    },
    addSummaryUserGroupPlan (state, userGroupPlan) {
        if (!userGroupPlan) {
            return
        }
        this.commit('addUserGroupPlan', userGroupPlan)

        if (!state.summary.data.user_group_plans) {
            Vue.set(state.summary.data, 'user_group_plans', {})
        }
        Vue.set(state.summary.data.user_group_plans, userGroupPlan.id, { id: userGroupPlan.id })
    },
    removeSummaryUserGroupPlan (state, userGroupPlan) {
        Vue.delete(state.summary.data?.user_group_plans || {}, userGroupPlan?.id)
    },
    groupPlans (state, groupPlans) {
        state.groupPlans = groupPlans
    },
    updateGroupPlan (state, groupPlan) {
        return this.commit('addGroupPlan', groupPlan)
    },
    addGroupPlan (state, groupPlan) {
        if (!groupPlan) {
            return
        }
        const groupPlans = state.groupPlans
        const index = _.findIndex(groupPlans, gp => gp.id === groupPlan.id)
        if (index === -1) {
            groupPlans.push(this.getters.parsePlan(groupPlan))
            state.groupPlans = groupPlans
        } else {
            Vue.set(state.groupPlans, index, this.getters.parsePlan(groupPlan))
        }
    },
    support (state, support) {
        state.support = support
        localStorage.setItem('support', support)
    },
    sidebar (state, sidebar) {
        state.sidebar = sidebar
        localStorage.setItem('sidebar', sidebar)
    },
    addComparing (state, plan) {
        Vue.set(state.comparing, plan.id, plan)
    },
    removeComparing (state, plan) {
        Vue.delete(state.comparing, plan.id, plan)
    },
    clearComparing (state) {
        state.comparing = {}
    },
    opportunities (state, opportunities = []) {
        state.opportunities = opportunities
    },
    activeProducts (state, activeProducts = []) {
        state.activeProducts = activeProducts
    },
    activeBreakpoint (state, activeBreakpoint) {
        state.activeBreakpoint = activeBreakpoint
    },
    autosave (state, status) {
        clearTimeout(state.autosaveTimeout)
        state.autosave = status
        state.autosaveTimeout = setTimeout(() => {
            this.commit('autosave', null)
        }, 3000)
    },
    usStates (state, usStates = []) {
        state.usStates = usStates
    },
    county (state, county) {
        state.county = county
    }
}

export const actions = {
    nuxtServerInit ({ commit, dispatch }, { route, req, ssrContext }) {
        const STATE = req?.body?.STATE || ssrContext?.req?.body?.STATE
        commit('state', STATE)
        commit('route', route)
        // return dispatch('loadUsStates')
    },
    async nuxtClientInit ({ commit, dispatch }, { req }) {
        if (req?.body?.STATE) {
            commit('state', req?.body?.STATE)
        } else if (window?.STATE) {
            commit('state', window?.STATE)
        } else {
            await dispatch('loadState')
        }
        if (typeof window !== 'undefined') {
            commit('support', localStorage.getItem('support') === 'true')
        }
        return dispatch('loadUsStates')
    },
    async loadState ({ commit }) {
        const STATE = await this.$try('getState')
        if (!STATE?.error) {
            commit('state', STATE)
        }
    },
    async register ({ commit }, payload) {
        const data = await this.$try('register', payload)
        if (!data?.error) {
            commit('user', data.user)
            this.$router.push('/')
        }
    },
    async login ({ commit }, payload) {
        const data = await this.$try('login', payload)
        if (!data?.error) {
            commit('user', data.user)
            this.$router.push(payload.pushTo || '/')
        }
        return data
    },
    async logout ({ commit }, pushTo) {
        await this.$try('logout')
        commit('user', null)
        if (pushTo !== false) {
            this.$router.push(pushTo || '/')
        }
    },
    async sendVerifyEmail ({ commit }) {
        const data = await this.$try('emailVerifyStart')
        if (!data?.error) {
            commit('pushAlert', { variant: 'info', message: 'Email sent!' })
        }
    },
    busy ({ commit }) {
        commit('busy', true)
    },
    idle ({ commit }) {
        commit('busy', false)
    },
    alert ({ commit }, e) {
        commit('pushAlert', e)
    },
    info ({ commit }, message) {
        commit('pushAlert', { ...(_.isObject(message) ? message : { message }), variant: 'info' })
    },
    warning ({ commit }, message) {
        commit('pushAlert', { ...(_.isObject(message) ? message : { message }), variant: 'warning' })
    },
    error ({ commit }, message) {
        commit('pushAlert', { ...(_.isObject(message) ? message : { message }), variant: 'danger' })
    },
    success ({ commit }, message) {
        commit('pushAlert', { ...(_.isObject(message) ? message : { message }), variant: 'success' })
    },
    unalert ({ commit }) {
        commit('clearAlerts')
    },
    async loadAllClientUserGroupPlans ({ commit, getters }) {
        const results = await this.$try('getAllUserGroupPlans', { group: getters.groupId, user: getters.client?.id }, { spinner: false })
        commit('userGroupPlans', results.map(gp => getters.parsePlan(gp)))
    },
    async getSurvey ({ commit, getters }, { id, spinner = false, aep = false } = {}) {
        aep = aep || (!getters.canAgent && getters.isAEPScheduling && (/aep/i.test(getters.route.path + '__' + JSON.stringify(getters.route.query || {}))))
        // eslint-disable-next-line camelcase
        const { error, survey, years, last_donut_hole_year, summaries, activities, commissions, magic_url } = await this.$try('getSurvey', { id, aep }, { spinner })
        if (!error) {
            // todo: rm hack
            ['providers', 'drugs', 'pharmacies'].forEach((prop) => {
                if (!_.isArray(survey[prop])) {
                    survey[prop] = []
                }
            })
            survey.drugs = (survey.drugs || []).map(drg => getters.parseDrug(drg))
            commit('years', years)
            commit('lastDonutHoleYear', last_donut_hole_year)
            commit('summaries', summaries)
            commit('activities', activities)
            commit('commissions', commissions)
            commit('survey', survey)
            commit('magicUrl', magic_url)
            return survey
        }
    },
    async includeDrug ({ state, getters, dispatch }, drug) {
        const drugs = _.cloneDeep(state.survey?.drugs || [])
        const idx = _.findIndex(drugs, d => getters.getDrugUniquishKey(d) === getters.getDrugUniquishKey(drug))
        if (idx > -1) {
            const drg = drugs[idx]
            drg.excluded = false
            await dispatch('updateSurvey', { id: getters.uid, data: { drugs }, nextTick: true })
            return true
        }
    },
    async excludeDrug ({ state, getters, dispatch }, drug) {
        const drugs = _.cloneDeep(state.survey?.drugs || [])
        const idx = _.findIndex(drugs, d => getters.getDrugUniquishKey(d) === getters.getDrugUniquishKey(drug))
        if (idx > -1) {
            const drg = drugs[idx]
            drg.excluded = true
            await dispatch('updateSurvey', { id: getters.uid, data: { drugs }, nextTick: true })
            return true
        }
    },
    async updateSurvey ({ commit }, { id, data, nextTick }) {
        const commits = () => {
            for (const field in data) {
                commit('field', { field, value: data[field] })
            }
        }
        // why nextTick? because removing items from a list won't emit events from the already removed items
        // so we call the api, then we remove in a nextTick aka setTimeout in a store
        if (!nextTick) {
            commits()
        }
        const response = await this.$api.updateSurvey({ id, data })
        if (nextTick) {
            setTimeout(commits, 0)
        }
        return response
    },
    completeSurvey (options = {}) {
        return this.$api.completeSurvey(options)
    },
    async getQuotes ({ commit, getters, dispatch }, { id, year, fresh, detailed, version, spinner } = {}) {
        if (!id) {
            id = getters.uid
        }
        if (!year) {
            year = getters.year
        }
        fresh = fresh || false
        detailed = detailed || false
        spinner = spinner || false

        const data = await this.$try('getQuotes', { id, year, fresh, detailed, version }, { spinner })
        // eslint-disable-next-line camelcase
        const { quotes, sources, error, plan_files, plan_extras, from_cache } = data
        version = data.version || version || 1
        if (!error) {
            commit('planExtras', plan_extras)
            commit('planFiles', plan_files)
            const plans = quotes.map(getters.parseQuote)
            commit('quotes', plans)
            commit('quotesFromCache', from_cache)
            commit('quotesVersion', version)
            commit('sources', sources)
            await dispatch('loadCountyFromZipCode')
            return { quotes: plans, plans, sources, planFiles: plan_files, planExtras: plan_extras, fromCache: from_cache }
        } else {
            return { error }
        }
    },
    async createSummary ({ commit }, { user, year }) {
        const summary = await this.$api.createSummary({
            year,
            user,
        })
        return summary
    },
    async getSummary ({ state, getters, dispatch, commit }, { id, uid, year } = {}) {
        if (!year) {
            year = getters.year
        }
        if (!uid) {
            uid = getters.uid
        }
        if (!id) {
            id = (await dispatch('createSummary', { user: getters.uid, year: getters.year }))?.id
        }
        const summary = await this.$try('getSummary', id, { spinner: false })
        if (!summary || !_.isObject(summary) || summary.error) {
            console.error('[store.getSummary] Error getting summary', summary)
            return null
        }
        summary.data.plans = _.mapValues(summary.data.plans, (plan) => {
            return getters.parsePlan(plan)
        })
        commit('summary', summary)
        commit('summaryData', getters.summaryMerge(summary?.data))
        return state.summary
    },
    async getSummaryFiles ({ commit }, { id } = {}) {
        const summaryFiles = await this.$try('getSummaryFiles', id, { spinner: false })
        commit('summaryFiles', summaryFiles)
    },
    async updateSummary ({ state, commit, getters }, { id, data }) {
        data = getters.summaryMerge(data)
        const { error } = await this.$try('updateSummary', { id: id || state.summary.id, data })
        if (!error) {
            commit('summaryData', data)
        }
    },
    async resetSummary ({ state, commit, getters }, { id, data }) {
        const results = await this.$try('resetSummary', { id: id || state.summary.id, data })
        if (!results.error) {
            commit('summaryData', results.data)
        }
    },
    addSummaryUserGroupPlan ({ state, commit }, plan) {
        commit('addSummaryUserGroupPlan', plan)
        return this.$api.updateSummary({ id: state.summary.id, data: state.summary.data })
    },
    removeSummaryUserGroupPlan ({ state, commit }, plan) {
        commit('removeSummaryUserGroupPlan', plan)
        return this.$api.updateSummary({ id: state.summary.id, data: state.summary.data })
    },
    addSummaryPlan ({ state, commit }, plan) {
        commit('addSummaryPlan', plan)
        return this.$api.updateSummary({ id: state.summary.id, data: state.summary.data })
    },
    removeSummaryPlan ({ state, commit }, plan) {
        commit('removeSummaryPlan', plan)
        return this.$api.updateSummary({ id: state.summary.id, data: state.summary.data })
    },
    async getOpportunities ({ commit }, uid) {
        const resp = await this.$api.getOpportunities(uid)
        if (resp?.opportunities) {
            commit('opportunities', resp?.opportunities)
        }
    },
    loadSummaryUserGroupPlans ({ state, commit }) {
        return Promise.all(_.map(state.summary.data?.group_plans, async (gp) => {
            const userGroupPlan = await this.$api.getUserGroupPlan(gp.id)
            commit('addUserGroupPlan', userGroupPlan)
        }))
    },
    async loadSummaryScriptsCards ({ state, commit }) {
        const { items } = await this.$api.getItems({ type: 'summary-scripts' })
        commit('summaryScriptsCards', items)
    },
    async getActiveProducts ({ commit }, uid) {
        const { products } = await this.$api.getActiveProducts(uid)
        commit('activeProducts', products)
    },
    onSurveyDone ({ getters }, options = {}) {
        let res
        if (getters.canAgent) {
            res = this.$try('agentCompleteSurvey', getters.uid, options)
        } else {
            res = this.$try('completeSurvey', options)
        }
        if (!res?.error) {
            this.$success('Survey Completed!')
            if (getters.canAgent) {
                return this.$router.push(`/survey/${getters.uid}`)
            }
            this.$router.push(`/thank-you?${options.aep ? 'aep=1' : options.prec ? 'prec=1' : ''}`)
        }
    },
    updateActiveBreakpoint ({ commit }) {
        commit('activeBreakpoint', getActiveBreakpoint())
    },
    async loadUsStates ({ commit }) {
        const usStates = await this.$try('getUsStates')
        commit('usStates', usStates)
    },
    async loadCountyFromZipCode ({ commit, state }) {
        if (state.survey?.zip_code && state.survey?.zip_code !== state.county?.zip_code) {
            const resp = await this.$api.getCountiesForZip(state.survey?.zip_code)
            if (resp) {
                let county = _.find(resp?.counties, county => county.fips === state.survey?.fips)
                if (!county) {
                    county = resp?.counties[0]
                }
                commit('county', { zip_code: state.survey?.zip_code, ...county })
            }
        }
    },
    async ignorePlanOrignalFile ({ commit }, { plan }) {
        await this.$try('updatePlanExtras', { plan_id: plan.id, ignore_original_file: true })
        commit('planExtra', { plan_id: plan.id, ignore_original_file: true })
    },
    async unignorePlanOrignalFile ({ commit }, { plan }) {
        await this.$try('updatePlanExtras', { plan_id: plan.id, ignore_original_file: false })
        commit('planExtra', { plan_id: plan.id, ignore_original_file: false })
    },
    async updatePlanDrugDeductibleApplicableTiers ({ commit }, { plan, tiers }) {
        await this.$try('updatePlanExtras', { plan_id: plan.id, drug_deductible_applicable_tiers: tiers })
        commit('planExtra', { plan_id: plan.id, drug_deductible_applicable_tiers: tiers })
    },
    async getActivityChoices ({ commit }) {
        const activityChoices = await this.$try('getActivityChoices')
        if (!activityChoices?.error) {
            commit('activityChoices', activityChoices || [])
        }
    }
}

export const getters = {
    lastDonutHoleYear (state) { return state.lastDonutHoleYear },
    isDonutHoleYear (state, getters) { return getters.year <= getters.lastDonutHoleYear },
    highlightColor (state) { return state.highlightColor },
    fontColor (state) { return state.fontColor },
    activeScriptTab (state) { return state.activeScriptTab },
    activeSurveyTab (state) { return state.activeSurveyTab },
    s3Base (state) { return state.s3Base },
    user (state) { return state.user },
    agent (state, getters) { return getters.canAgent ? getters.user : getters.client?.agent || getters.user?.agent || state.agent },
    group (state, getters) { return getters.asGroup ? state.group || state.originalState?.group : getters.canAgent ? state.originalState?.group : state.group },
    asGroup (state, getters) { return getters.canAgent && state.group?.id !== state.originalState?.group?.id && getters.slug },
    groupName (state, getters) { return getters.client?.group?.name || getters?.group?.name },
    groupId (state, getters) { return getters.client?.group?.id || getters?.group?.id },
    inGroup (state, getters) { return !!getters.groupId },
    client (state) { return state.survey },
    preferredFirstName (state, getters) { return _.startCase(state.survey?.preferred_first_name || '') || _.startCase(state.survey?.preferred_name || '') || getters.firstName },
    firstName (state) { return _.startCase(state.survey?.first_name || '') || null },
    lastName (state) { return _.startCase(state.survey?.last_name || '') || null },
    clients (state) { return (state.clients || []) },
    crmClient (state) { return state.crmClient },
    matchingClients (state) { return (state.matchingClients || []) },
    alerts (state) { return state.alerts },
    isBusy (state) { return state.busy },
    isServer () { return process.server },
    activeBreakpoint (state) { return state.activeBreakpoint },
    activeBreakpointClassName (state, getters) { return getters.activeBreakpoint?.className || '' },
    activeBreakpointClassMap (state, getters) { return getters.activeBreakpoint?.classMap || {} },
    isSize (state, getters) { return size => !!getters.activeBreakpointClassMap[size] },
    isClient (state, getters) { return state?.user?.role === 'client' },
    isHr (state, getters) { return state?.user?.role === 'hr' },
    isAgent (state, getters) { return state?.user?.role === 'agent' },
    isAdmin (state) { return state?.user?.role === 'admin' },
    isSu (state) { return state?.user?.role === 'su' },
    canClient (state, getters) { return getters.isClient || getters.canHr },
    canHr (state, getters) { return getters.isHr || getters.canAgent },
    canAgent (state, getters) { return getters.isAgent || getters.canAdmin },
    canAdmin (state, getters) { return getters.isAdmin || getters.canSu },
    canSu (state, getters) { return getters.isSu },
    isAgentMode (state, getters) { return getters.routeUid && getters.canAgent },
    support (state) { return state.support },
    survey (state, getters) { return getters.uid ? state.surveys[getters.uid] : state.survey },
    zipCode (state, getters) { return getters.survey?.zip_code },
    county (state, getters) { return state.county || _.pick(getters.client || {}, ['zip_code', 'fips', 'state', 'county']) },
    surveys (state) { return state.surveys },
    planExtras (state, getters) { return state.planExtras || [] },
    planExtrasMap (state, getters) { return _.keyBy(state.planExtras || [], 'plan_id') },
    hasPlanPreferredName (state, getters) { return plan => getters.planExtrasMap[plan.id]?.preferred_name },
    isPlanOriginalFileIgnored (state, getters) { return plan => !!getters.planExtrasMap[plan.id]?.ignore_original_file },
    planDrugDeductibleApplicableTiers (state, getters) {
        return (plan) => {
            const pe = getters.planExtrasMap[plan.id]
            // eslint-disable-next-line
            if (pe && pe.hasOwnProperty('drug_deductible_applicable_tiers')) {
                return pe.drug_deductible_applicable_tiers
            }
            return plan.drug_deductible_applicable_tiers || null
        }
    },
    planFiles (state, getters) { return state.planFiles || [] },
    planFilesMap (state, getters) { return _.keyBy(state.planFiles || [], 'file_id') },
    planFilesByPlanId (state, getters) {
        return _.groupBy(state.planFiles, 'plan_id')
    },
    planFilesInState (state, getters) {
        return getters.planFiles.filter((pf) => {
            if (!pf?.metadata?.available_states?.length) {
                return true
            }
            return pf?.metadata?.available_states.some(usState => usState === getters.county?.state)
        })
    },
    planFilesOutState (state, getters) {
        return getters.planFiles.filter((pf) => {
            if (!pf?.metadata?.available_states?.length) {
                return false
            }
            return pf?.metadata?.available_states.every(usState => usState !== getters.county?.state)
        })
    },
    planFilesByPlanIdInState (state, getters) {
        return _.groupBy(getters.planFilesInState, 'plan_id')
    },
    planFilesByPlanIdOutState (state, getters) {
        return _.groupBy(getters.planFilesOutState, 'plan_id')
    },
    summaryScriptsCards (state) { return state.summaryScriptsCards || [] },
    summaryScriptsCardsMap (state, getters) {
        return _.keyBy(getters.summaryScriptsCards, 'key')
    },
    summaryFiles (state, getters) { return state.summaryFiles || [] },
    summaryFilesMap (state, getters) { return _.keyBy(getters.summaryFiles, 'file_id') },
    summarySwitchedOnCardsList (state, getters) {
        return _.uniq((getters.summary?.data?.config?.cards || []).filter(c => !!c))
    },
    summarySwitchedOnCardsMap (state, getters) {
        return _.keyBy(getters.summarySwitchedOnCardsList, card => card)
    },
    summarySwitchedOnCardsCombos (state, getters) {
        return getters.summarySwitchedOnCardsList.filter(c => /combo_/.test(c))
    },
    summaryCombosPlans (state, getters) {
        let plans = [];
        (getters.summarySwitchedOnCardsCombos || []).forEach((combo) => {
            plans = plans.concat(getters.getSummaryComboPlansByCard(combo))
        })
        return _.uniqBy(plans, 'id')
    },
    getSummaryComboPlanIdsByCard (state, getters) {
        return card => card.replace(/combo_/, '').split('_')
    },
    getSummaryComboPlansByCard (state, getters) {
        return (card) => {
            const ids = getters.getSummaryComboPlanIdsByCard(card)
            const plans = ids.map(id => getters.summaryPlansMap[id]).filter(p => p)
            return plans
        }
    },
    summaryAttachments (state, getters) {
        const plansList = getters.summaryCombosPlans
        let attachments = []
        plansList.forEach((plan) => {
            const planFiles = (getters.planFilesByPlanIdInState[plan.id] || []).slice(0)
            if (plan.summary_pdf && !getters.isPlanOriginalFileIgnored(plan)) {
                planFiles.unshift({
                    full_path: plan.summary_pdf,
                    file_name: `${plan.name} Summary Of Benefits.pdf`
                })
            }
            attachments = attachments.concat(planFiles)
            return planFiles
        })
        attachments = attachments.concat(getters.summaryFiles || [])
        if (getters.summaryCardsMap.step_ichra && !getters.survey?.collecting_ssi) {
            attachments = attachments.concat({
                full_path: 'https://doctorschoice.s3.amazonaws.com/common-pdfs/Medicare.EasyPay.OneTime.Payment.Setup.Tutorial.pdf',
                file_name: 'ICHRA Medicare EasyPay Tutorial.pdf'
            })
        }
        if (getters.summaryCardsMap.step_part_b_signup) {
            attachments = attachments.concat({
                full_path: 'https://www.cms.gov/Medicare/CMS-Forms/CMS-Forms/Downloads/CMS40B-E.pdf',
                file_name: 'CMS40B-E.pdf'
            })
            attachments = attachments.concat({
                full_path: 'https://www.cms.gov/medicare/CMS-Forms/CMS-Forms/Downloads/CMS-L564E.pdf',
                file_name: 'CMS-L564E.pdf'
            })
        }
        return attachments
    },
    plans (state, getters) { return state.quotes.map(getters.parsePlan) },
    plansMap (state, getters) { return _.keyBy(getters.plans, q => q.id) },
    plansSourcesMap (state, getters) { return _.keyBy(getters.plans, q => q.source_id) },
    sources (state) { return state.sources },
    summary (state) { return state.summary },
    activities (state) { return state.activities },
    commissions (state) { return state.commissions },
    lastCheckupCompleteActivity (state) { return _.find(state.activities, activity => activity.activity === 'complete_survey') },
    quotesVersion (state) { return state.quotesVersion },
    quotesFromCache (state) { return state.quotesFromCache || false },
    paying (state, getters) {
        return !!getters.client?.group?.paying
    },
    paying_employee_or_spouse (state, getters) {
        return !!getters.client?.group?.paying && (getters.client.group_relationship === 'EMPLOYEE_SPOUSE' || getters.client.group_relationship === 'EMPLOYEE')
    },
    cms564Assist (state, getters) {
        return !!getters.client?.group?.cms564_assist
    },
    ichra (state) {
        return state.survey?.ichra
    },
    surveyRoutes (state, getters) {
        const id = getters.uid
        const isConsultationScripts = getters.activeRoutePath.split('/').pop() === 'consultation-scripts'
        // const edge = getters.edge
        let list = []
        if ((id || isConsultationScripts) && getters.canAgent) {
            list = [
                { path: `/survey/${id}/`, text: 'Profile', icon: 'user', nav: true, label: true },

                { path: `/survey/${id}/demographics`, text: 'Demographics', nav: true, icon: 'clipboard-user' },
                { path: `/survey/${id}/enrollment-period`, text: 'Enrollment Period', nav: true, icon: 'calendar-plus' },
                { path: `/survey/${id}/income-surcharges`, text: 'Income Surcharges', nav: true, icon: 'sack-dollar' },
                { path: `/survey/${id}/health-lifestyle`, text: 'Health/Lifestyle', nav: true, icon: 'heartbeat' },

                { path: `/survey/${id}/providers`, text: 'Providers', icon: 'user-md', nav: true, label: false },
                { path: `/survey/${id}/drugs`, text: 'Drugs', icon: 'prescription-bottle-alt', nav: true, label: false },
                { path: `/survey/${id}/pharmacies`, text: 'Pharmacies', icon: 'store', nav: true, label: false },
                { path: `/survey/${id}/plans`, text: 'Plans', icon: 'list', nav: true, label: false },
                { path: `/survey/${id}/summary`, text: 'Summary', icon: 'file-contract', nav: true, label: false },

                { path: `/survey/${id}/activity`, query: { strict: false, activity: 'send_summary' }, text: 'Activity', icon: 'chart-line', nav: true, label: false },

                { path: `/survey/${id}/step-by-step`, text: 'Wizard', icon: 'hat-wizard', nav: true, label: false },
                { path: `/survey/${id}/aep`, text: 'AEP', icon: 'cauldron', nav: false, label: false },
                { path: `/survey/${id}/quick-aep`, text: 'Quick AEP', nav: true, icon: 'cauldron', label: false },
            ]
        } else {
            list = [
                // { path: '/survey/step-by-step', text: 'Questionnaire', icon: 'hat-wizard', nav: true },
                { path: '/survey/demographics', text: 'My Profile', icon: 'address-card', nav: true },
                { path: '/survey/income', text: 'My Income', icon: 'sack-dollar', nav: true },
                { path: '/survey/providers', text: 'My Providers', icon: 'user-md', nav: true },
                { path: '/survey/drugs', text: 'My Prescriptions', icon: 'prescription-bottle-alt', nav: true },
                { path: '/survey/pharmacies', text: 'My Pharmacies', icon: 'clinic-medical', nav: true },
                // { path: '/survey/summary', text: 'My Summary', icon: 'file-contract', nav: true },
                // { path: '/survey/plans', text: 'Plans', icon: 'list-ol', nav: true },
            ]
        }
        return list.filter(item => item)
    },
    surveyRoutesMap (state, getters) {
        return _.keyBy(getters.surveyRoutes, (sr) => {
            return sr.path.split('/').pop()
        })
    },
    surveyRoutesPathsMap (state, getters) {
        return _.keyBy(getters.surveyRoutes, (sr) => {
            return sr.path.replace(/\/$/, '')
        })
    },
    surveyRoutesNavItems (state, getters) {
        return getters.surveyRoutes.filter(sr => sr.nav)
    },
    isSurveyRoute (state, getters) {
        return !!getters.surveyRoutesPathsMap[getters.activeRoutePath.replace(/\/$/, '')]
    },
    surveyRouteIndex (state, getters) {
        return (route) => {
            return _.findIndex(getters.surveyRoutesNavItems, surveyRoute => surveyRoute.path === route?.path)
        }
    },
    currentSurveyRouteIndex (state, getters) {
        if (!getters.isSurveyRoute) {
            return -1
        }
        return getters.surveyRouteIndex(getters.activeRoute)
    },
    previousSurveyRoute (state, getters) {
        return getters.surveyRoutesNavItems[getters.currentSurveyRouteIndex - 1]
    },
    currentSurveyRoute (state, getters) {
        return getters.surveyRoutesNavItems[getters.currentSurveyRouteIndex]
    },
    nextSurveyRoute (state, getters) {
        return getters.surveyRoutesNavItems[getters.currentSurveyRouteIndex + 1]
    },
    activeRoute (state, getters) {
        return getters.route
    },
    activeRoutePath (state, getters) {
        return getters.activeRoute?.path
    },
    params (state, getters) {
        return getters.route.params
    },
    query (state, getters) {
        return getters.route.query
    },
    hostUrl (state, getters) {
        return state.host_url || state.host_url || `${getters.location.protocol}://${getters.location.hostname}`
    },
    effectiveHostUrl (state, getters) {
        return state.effective_host_url || state.host_url || `${getters.location.protocol}://${getters.location.hostname}`
    },
    route (state) {
        if (typeof window !== 'undefined') {
            return window.$nuxt.$route
        }
        return state.route || { query: {}, params: {}, path: '' }
    },
    location (state, getters) {
        return getters.window?.location || {}
    },
    // don't like this when isServer
    window () {
        return typeof window === 'undefined' ? global : window
    },
    lightTheme (state, getters) {
        // [todo@aziz] change this to a stored value?
        return true
    },
    darkTheme (state, getters) {
        return !getters.lightTheme
    },
    crmBase (state) {
        return state.crmBase
    },
    autosave (state) {
        return state.autosave
    },
    routeUid (state, getters) {
        // todo: risky?
        return getters.query?.id || getters.params?.uid
    },
    uid (state, getters) {
        if (getters.canAgent) {
            return getters.routeUid || state.survey?.id || state.summary?.user_id
        }
        return getters.routeUid
    },
    slug (state, getters) {
        if (getters.canAgent) {
            return getters.query?.slug || getters.params?.slug
        }
        return getters.group?.slug
    },
    edge (state, getters) {
        return getters.user?.edge
    },
    year (state, getters) { return parseInt(state.year || getters.query?.year, 10) || getters.latestYear },
    years (state, getters) {
        const years = Object.keys(state.years || {}).map(year => parseInt(year))
        if (years.length) {
            return years
        }
        return [
            getters.lastYear,
            getters.thisYear,
            // ...(getters.isAEP ? [getters.nextYear] : []),
        ]
    },
    latestYear (state, getters) {
        const years = _.sortBy(getters.years, y => y)
        return years[years.length - 1]
    },
    lastYear (state, getters) {
        return getters.thisYear - 1
    },
    thisYear () {
        return (new Date()).getFullYear()
    },
    nextYear (state, getters) {
        return getters.thisYear + 1
    },
    yearMinus2 (state, getters) {
        return getters.year - 2
    },
    yearsOptions () {
        return getters.years.map(year => ({ text: year, value: year }))
    },
    parseError () {
        return (e) => {
            if (e.parsed) {
                return e
            }
            e.parsed = true
            if (_.isArray(e?.response?.data?.detail)) {
                const errors = _.map(e.response.data.detail, (suberror) => {
                    // Remove body / query prefix, and __root__ if it's a top level error
                    let loc = _.slice(suberror.loc, 1)
                    if (loc[0] === '__root__') loc = _.slice(loc, 1)
                    let msg = suberror.msg
                    if (loc.length) msg = `${loc.join('.')}: ${msg}`
                    return msg
                })
                e.message = errors.join('\n')
            } else {
                e.message = e?.response?.data?.detail || e?.response?.data?.message || e?.message
            }
            if (/duplicate key/i.test(e.message)) {
                e.message = 'We already have a profile with this email address and date of birth'
            }
            return e
        }
    },
    isNotAEP (state, getters) {
        return !getters.isAEP
    },
    isPreAEP (state, getters) {
        return getters.isBetweenOct1Dec6
    },
    isAEP (state, getters) {
        return getters.isBetweenOct15Dec7
    },
    isOEP (state, getters) {
        return getters.isBetweenJan1Mar31
    },
    isAEPScheduling (state, getters) {
        return getters.isBetweenAug15Dec6
    },
    isOEPScheduling (state, getters) {
        return getters.isBetweenJan1Mar31
    },
    onLargeEmployerCoverage (state, getters) {
        return state.survey?.pre_medicare_coverage === 'EMPLOYER' && getters.survey?.employer_size_gt20
    },
    isSEP (state, getters) {
        return getters.isSEPLEC || getters.isSEPMoving
    },
    isSEPLEC (state, getters) {
        return state.survey?.enrollment_period === 'SEP_LEC' || ((getters.dobx.t65 || getters.dobx.gte65) && getters.onLargeEmployerCoverage && state.survey?.working_p65 && state.survey?.employer_coverage_p65)
    },
    isSEPMoving (state, getters) {
        return state.survey?.enrollment_period === 'SEP_MOVING' || (getters.hasMedicare && getters.hasSuppOrMapd && state.survey?.moving)
    },
    isIEP (state, getters) {
        return state.survey?.enrollment_period === 'IEP' || getters.dobx.lt65
    },
    lt62 (state, getters) {
        return getters.dobx.lt62
    },
    lt65 (state, getters) {
        return getters.dobx.lt65
    },
    gte65 () {
        return getters.dobx.lt65
    },
    hasPartA (state) {
        return state.survey?.part_a_date && moment(state.survey?.part_a_date).isBefore(moment())
    },
    hasPartB (state) {
        return !!state.survey?.part_b_date && moment(state.survey?.part_b_date).isBefore(moment())
    },
    hasMedicare (state, getters) {
        return getters.hasPartA || getters.hasPartB
    },
    hasSuppOrMapd (state) {
        return state.survey?.current_medicare_coverage === 'SUPP' || state.survey?.current_medicare_coverage === 'MAPD' || state.survey?.current_medicare_coverage === 'SUPP_PDP'
    },
    isBetweenSep1Dec31 () {
        return moment().isBetween(moment().set({ month: 8, date: 1 }).startOf('day'), moment().set({ month: 11, date: 31 }).endOf('day'))
    },
    isBetweenAug15Sep30 () {
        return moment().isBetween(moment().set({ month: 7, date: 15 }).startOf('day'), moment().set({ month: 8, date: 30 }).endOf('day'))
    },
    isBetweenAug15Dec6 () {
        return moment().isBetween(moment().set({ month: 7, date: 15 }).startOf('day'), moment().set({ month: 11, date: 6 }).endOf('day'))
    },
    isBetweenAug15Oct12 () {
        return moment().isBetween(moment().set({ month: 7, date: 15 }).startOf('day'), moment().set({ month: 9, date: 12 }).endOf('day'))
    },
    isBetweenSep1Dec7 () {
        return moment().isBetween(moment().set({ month: 8, date: 1 }).startOf('day'), moment().set({ month: 11, date: 7 }).endOf('day'))
    },
    isBetweenOct15Dec7 () {
        return moment().isBetween(moment().set({ month: 9, date: 15 }).startOf('day'), moment().set({ month: 11, date: 7 }).endOf('day'))
    },
    isBetweenOct1Dec6 () {
        return moment().isBetween(moment().set({ month: 9, date: 1 }).startOf('day'), moment().set({ month: 11, date: 6 }).endOf('day'))
    },
    isBetweenJan1Mar31 () {
        return moment().isBetween(moment().set({ month: 0, date: 1 }).startOf('day'), moment().set({ month: 2, date: 31 }).endOf('day'))
    },
    isBetweenOct1Nov30 () {
        return moment().isBetween(moment().set({ month: 9, date: 1 }).startOf('day'), moment().set({ month: 10, date: 30 }).endOf('day'))
    },
    partAPremiumLt30 (state, getters) {
        return _.get(state, `years.${getters.year}.part_a_premiums.QUARTERS_LT_30`)
    },
    partAPremiumGte30Lte39 (state, getters) {
        return _.get(state, `years.${getters.year}.part_a_premiums.QUARTERS_30_39`)
    },
    partAPremiumGt40 (state, getters) {
        return _.get(state, `years.${getters.year}.part_a_premiums.QUARTERS_40`)
    },
    partAPremium (state, getters) {
        return _.get(state, `years.${getters.year}.part_a_premiums.${state.survey?.tax_quarters_paid}`) || 0
    },
    partADeductible (state, getters) {
        return _.get(state, `years.${getters.year}.part_a_deductible`)
    },
    partBPremium (state, getters) {
        return _.get(state, `years.${getters.year}.part_b_premium`)
    },
    partBDeductible (state, getters) {
        return _.get(state, `years.${getters.year}.part_b_deductible`)
    },
    partDGapEntrance (state, getters) {
        return _.get(state, `years.${getters.year}.part_d_gap_entrance`)
    },
    partDGapBrandDrugPercent (state, getters) {
        return _.get(state, `years.${getters.year}.part_d_gap_brand_drug_percent`)
    },
    partDGapGenericDrugPercent (state, getters) {
        return _.get(state, `years.${getters.year}.part_d_gap_generic_drug_percent`)
    },
    partDCatastrophicEntrance (state, getters) {
        return _.get(state, `years.${getters.year}.part_d_catastrophic_entrance`)
    },
    partDCatastrophicCoveredDrugPercent (state, getters) {
        return _.get(state, `years.${getters.year}.part_d_catastrophic_covered_drug_percent`)
    },
    partDCatastrophicGenericDrugCopay (state, getters) {
        return _.get(state, `years.${getters.year}.part_d_catastrophic_generic_drug_copay`)
    },
    partDCatastrophicBrandDrugCopay (state, getters) {
        return _.get(state, `years.${getters.year}.part_d_catastrophic_brand_drug_copay`)
    },
    partBDatePast6m (state) {
        if (!state.survey?.part_b_date) {
            return null
        }
        return moment().diff(moment(state.survey?.part_b_date), 'months') > 6
    },
    summaries (state) {
        return state.summaries || []
    },
    summariesMap (state) {
        return _.keyBy(state.summaries || [], 'id')
    },
    summaryAep (state) {
        return state.summary?.data?.config?.aep
    },
    summaryCards (state) {
        return _.uniq(state.summary?.data?.config?.cards || [])
    },
    summaryCardsMap (state, getters) {
        return _.keyBy(getters.summaryCards, c => c)
    },
    summaryMode (state) {
        return state.summary?.data?.config?.mode || null
    },
    summaryDataCards (state) {
        return state.summary?.data?.cards || {}
    },
    summarySurveyUpdated (state) {
        return state.summary?.data?.survey_updated
    },
    summaryManual (state) {
        return state.summary?.data?.manual
    },
    userGroupPlans (state) {
        return state.userGroupPlans || []
    },
    userGroupPlansMap (state) {
        return _.keyBy(state.userGroupPlans, 'id')
    },
    summaryUserGroupPlans (state, getters) {
        const userGroupPlans = {}
        _.each(state.summary?.data?.user_group_plans || {}, (p, k) => {
            if (getters.userGroupPlansMap[p.id]) {
                const userGroupPlan = _.cloneDeep(getters.userGroupPlansMap[p.id])
                userGroupPlans[k] = getters.parsePlan(userGroupPlan)
            }
        })
        return userGroupPlans
    },
    summaryUserGroupPlansMap (state, getters) {
        return getters.summaryUserGroupPlans
    },
    summaryUserGroupPlansList (state, getters) {
        return _.values(getters.summaryUserGroupPlansMap || {})
    },
    groupPlans (state) {
        return state.groupPlans || []
    },
    groupPlansMap (state) {
        return _.keyBy(state.groupPlans, 'id')
    },
    summaryPlans (state, getters) {
        const plans = {}
        const plansMap = getters.plansMap
        _.each(state.summary?.data?.plans || {}, (p, k) => {
            if (plansMap[p.id]) {
                const plan = getters.parsePlan(_.cloneDeep(plansMap[p.id]))
                plan.recommendation = p.recommendation
                plans[k] = plan
                return plan
            }
            // console.warn(`Summary plan id:${p.id} was not found in the most recent quotes pulled, possibly wrong year or wrong plan_id?`)
        })
        return plans
    },
    summaryPlansMap (state, getters) {
        return getters.summaryPlans
    },
    summaryPlansList (state, getters) {
        const plans = _.values(getters.summaryPlansMap || {})
        plans.sort((p1, p2) => {
            if (_.isNil(p1.recommendation)) {
                return 0
            }
            if (_.isNil(p2.recommendation)) {
                return -1
            }
            return p1.recommendation < p2.recommendation ? -1 : 1
        })
        return plans
    },
    summaryMapdPlans (state, getters) {
        return getters.summaryPlansList.filter(p => p.type === 'mapd' || p.type === 'ma')
    },
    summarySuppPlans (state, getters) {
        return getters.summaryPlansList.filter(p => p.type === 'supp')
    },
    summaryPdpPlans (state, getters) {
        return getters.summaryPlansList.filter(p => p.type === 'pdp')
    },
    summarySuppPdpPlans (state, getters) {
        return getters.summaryPlansList.filter(p => p.type === 'pdp' || p.type === 'supp')
    },
    summaryCombosMapAndList (state, getters) {
        const plans = getters.summaryPlansList
        const combos = []
        const combosMap = {}
        const flat = []
        plans.forEach((plan) => {
            if (combosMap[plan.id]) {
                return
            }
            let combo = []
            if (plan.type === 'mapd' || plan.type === 'ma') {
                combo = [plan]
            } else if (plan.type === 'supp') {
                const pdp = plans.filter(p => p.type === 'pdp')[0]
                combo = [plan, pdp].filter(p => p)
            } else if (plan.type === 'pdp') {
                const supp = plans.filter(p => p.type === 'supp')[0]
                combo = [supp, plan].filter(p => p)
            }
            if (getters.summaryAep) {
                combo = getters.modeFilterSummaryPlans(combo)
            }
            combo.forEach((p) => {
                combosMap[p.id] = combo
                flat.push(p)
            })
            if (combo.length) {
                combosMap[combo.map(p => p.id).join('_')] = combo
                combos.push(combo)
            }
        })
        return { combos, combosMap, flat }
    },
    summaryCombos (state, getters) {
        return getters.summaryCombosMapAndList.combos
    },
    summaryCombosMap (state, getters) {
        return getters.summaryCombosMapAndList.combosMap
    },
    modeFilteredSummaryPlans (state, getters) {
        return getters.summaryCombosMapAndList.flat
    },
    modeFilterSummaryPlans (state, getters) {
        return (plans) => {
            if (getters.summaryMode === 'SWITCH_PDP' || getters.summaryMode === 'STAY_PDP') {
                return plans.filter(p => p.pdp)
            }
            if (getters.summaryMode === 'SWITCH_MAPD') {
                return plans.filter(p => p.mapd_or_ma)
            }
            if (getters.summaryMode === 'SWITCH_SUPP') {
                return plans.filter(p => p.supp)
            }
            if (getters.summaryMode === 'SWITCH_SUPP_PDP' || getters.summaryMode === 'STAY_SUPP_PDP') {
                return plans.filter(p => p.supp || p.pdp)
            }
            if (getters.summaryMode === 'STAY_MAPD') {
                return plans.filter(p => p.mapd_or_ma)
            }
            return plans
        }
    },
    hasMapd (state, getters) {
        return !!getters.summaryMapdPlans.length
    },
    hasSupp (state, getters) {
        return !!getters.summarySuppPlans.length
    },
    hasPdp (state, getters) {
        return !!getters.summaryPdpPlans.length
    },
    hasAllPlanTypes (state, getters) {
        return getters.hasMapd && getters.hasSupp && getters.hasPdp
    },
    missingTypes (state, getters) {
        return [
            getters.hasNotMapd ? 'mapd' : null,
            getters.hasNotSupp ? 'supp' : null,
            getters.hasNotPdp ? 'pdp' : null,
        ].filter(t => t)
    },
    allowedTypes (state, getters) {
        return _.uniq(getters.missingTypes.concat(getters.summaryAep && getters.hasNotMin2Ppds ? ['pdp'] : []))
    },
    hasNotMapd (state, getters) {
        return !getters.summaryMapdPlans.length
    },
    hasNotSupp (state, getters) {
        return !getters.summarySuppPlans.length
    },
    hasNotPdp (state, getters) {
        return !getters.summaryPdpPlans.length
    },
    hasMin2Ppds (state, getters) {
        return getters.summaryPdpPlans.length > 1
    },
    hasNotMin2Ppds (state, getters) {
        return true
        // return !getters.hasMin2Ppds
    },
    summaryRecommendedPlans (state, getters) {
        return getters.summaryPlansList.filter(p => _.isNumber(p.recommendation))
    },
    yearSummaryId (state, getters) {
        return _.find(getters.summaries || [], summary => summary.year === getters.year && !summary.auto)?.id
    },
    summaryMerge () {
        return (data) => {
            return {
                ...DEFAULT_SUMMARY_DATA,
                ...(data || {}),
                ...{
                    config: {
                        ...DEFAULT_SUMMARY_DATA.config,
                        ...((data || {}).config || {})
                    }
                },
            }
        }
    },
    providersMap (state) {
        return _.keyBy(state.survey?.providers || [], 'npi')
    },
    pharmaciesMap (state) {
        return _.keyBy(state.survey?.pharmacies || [], 'npi')
    },
    packagesMap (state, getters) {
        return (state.survey?.drugs || []).reduce((map, drug) => {
            const drg = getters.parseDrug(_.cloneDeep(drug))
            map[drg?.package?.ndc] = {
                ...(drg.package || {}),
                dosage: drg.dosage ? { ...drg.dosage } : null,
                custom_dosage: drg.custom_dosage,
                custom_package: drg.custom_package,
                drug: { ...drg }
            }
            return map
        }, {})
    },
    dosageLabel () {
        return (dsg) => {
            return `${dsg.strength.toLowerCase()}${dsg.strength_units.toLowerCase()} ${_.lowerCase(dsg.form)}${dsg.name ? ` (${dsg.name})` : ''}`
        }
    },
    packageLabel () {
        return (pkg, dsg) => {
            let label = ''
            if (pkg.unit === 'EA') {
                label = `${_.capitalize(pkg.package_type)} of ${pkg.size} ${_.lowerCase(dsg.form)}`
            } else {
                label = `${pkg.unit && pkg.unit !== 'N/A' ? `${_.lowerCase(pkg.unit)} ` : ''}${pkg.package_type ? _.lowerCase(pkg.package_type) : ''}`
                label = pkg.size ? `${pkg.size}${label}` : label
            }
            if (pkg.quantity > 1 && pkg.use_quantity) {
                label = `${pkg.quantity}x ${label}`
            }
            return `${label}`
        }
    },
    parsePackage (state, getters) {
        return (pkg, dosage) => {
            if (pkg.parsed) {
                return pkg
            }
            pkg.parsed = true
            pkg.text = pkg.text || getters.packageLabel(pkg, dosage)
            return pkg
        }
    },
    parseDosage (state, getters) {
        return (dsg, packages) => {
            if (dsg.parsed) {
                return dsg
            }
            dsg.text = dsg.text || getters.dosageLabel(dsg)
            dsg.hyphens = dsg.text.match(/-/g)?.length || 0
            dsg.packages = (dsg.packages || packages || []).map(pkg => getters.parsePackage(pkg, dsg))
            return dsg
        }
    },
    parseDrug (state, getters) {
        return (drg) => {
            if (drg.parsed) {
                return drg
            }
            drg.parsed = true
            drg.name = drg.name && drg.name.replace(/\n$/, '')
            drg.text = drg.name
            drg.dosage = drg.dosage ? getters.parseDosage(drg.dosage) : drg.dosage
            drg.package = drg.package && drg.dosage ? getters.parsePackage(drg.package, drg.dosage) : drg.package
            drg.dosages = (drg.dosages || []).map(dsg => getters.parseDosage(dsg))
            drg.dosages.forEach((dsg) => {
                if (dsg.hyphens) {
                    drg.max_hyphens = Math.max(dsg.hyphens, drg.max_hyphens || 0)
                }
            })
            drg.frequency_days = drg.frequency ? parseInt((drg.frequency.match(/(\d+)/) || [])[1], 10) || 30 : null
            drg.daily_average = drg.frequency_days ? drg.quantity / drg.frequency_days : null
            return drg
        }
    },
    iramaaBThresholds (state) {
        /* eslint-disable */
        return ({ year, tax_filing_status }) => {
            const list = _.cloneDeep(_.get(state, `years.${year}.irmaa_part_b_thresholds.${tax_filing_status}`) || {})
            const keys = Object.keys(list)
            keys.unshift(0)
            return {
                keys,
                list
            }
        }
    },
    iramaaDThresholds (state) {
        /* eslint-disable */
        return ({ year, tax_filing_status }) => {
            const list = _.cloneDeep(_.get(state, `years.${year}.irmaa_part_d_thresholds.${tax_filing_status}`) || {})
            const keys = Object.keys(list)
            keys.unshift(0)
            return {
                keys,
                list
            }
        }
    },
    calcIrmaaB (state, getters) {
        /* eslint-disable */
        return ({ year, current_income, tax_filing_status }) => {
            const thresholds = getters.iramaaBThresholds({ year, tax_filing_status })
            const key = thresholds.keys[current_income]
            return thresholds.list[key] || 0
        }
    },
    irmaaB (state, getters) {
        return getters.calcIrmaaB({ year: getters.year, ...state.survey })
    },
    getProjectedIrmaaB (state, getters) {
        return () => getters.calcIrmaaB({ year: getters.year, current_income: state.survey?.projected_income, tax_filing_status: state.survey?.projected_tax_filing_status })
    },
    projectedIncomeChange (state) {
        return state.survey?.projected_income !== state.survey?.current_income
    },
    projectedIncomeDrop (state) {
        return state.survey?.projected_income < state.survey?.current_income
    },
    dob (state, getters) {
        return getters.client?.dob
    },
    dobx (state, getters) {
        return fromDob(getters.dob) || {}
    },
    lt65 (state, getters) {
        return getters.dobx.lt65
    },
    gte65 (state, getters) {
        return getters.dobx.gte65
    },
    calcIrmaaD (state, getters) {
        /* eslint-disable */
        return ({ year, current_income, tax_filing_status  }) => {
            const thresholds = getters.iramaaDThresholds({ year, current_income, tax_filing_status })
            const key = thresholds.keys[current_income]
            return thresholds.list[key] || 0
        }
    },
    irmaaD (state, getters) {
        return getters.calcIrmaaD({ year: getters.year, ...state.survey })
    },
    getProjectedIrmaaD (state, getters) {
        return () => getters.calcIrmaaD({ year: getters.year, current_income: state.survey?.projected_income, tax_filing_status: state.survey?.projected_tax_filing_status })
    },
    income (state, getters) {
        return (getters.incomeOptionsFiledAsMap[state.survey?.tax_filing_status] || {})[state.survey?.current_income]?.text
    },
    projectedIncome (state, getters) {
        return (getters.incomeOptionsFiledAsMap[state.survey?.projected_tax_filing_status] || {})[state.survey?.projected_income]?.text
    },
    incomeOptionsFiledAs (state, getters) {
        return (filedAs, year = getters.year) => {
            const keys = Object.keys(_.get(state, `years.${year}.irmaa_part_d_thresholds.${filedAs}`) || {}).map(k => parseInt(k))
            keys.sort((a1, a2) => a1 > a2 ? 1 : -1)
            return keys.reduce((arr, k, i) => {
                const previous = keys[i - 1]
                const amount = k
                let text, min, max
                if (!i) {
                    min = 0
                    max = amount - 1
                    text = `Less than ${money(amount, { minimumFractionDigits: 0 })}`
                    arr.push({ text, min, max })
                } else if (i === keys.length - 1) {
                    min = previous
                    max = amount - 1
                    text = `Between ${money(min, { minimumFractionDigits: 0 })} and ${money(max, { minimumFractionDigits: 0 })}`
                    arr.push({ text, min, max })
                    min = amount
                    max = null
                    text = `More than ${money(amount, { minimumFractionDigits: 0 })}`
                    arr.push({ text, min, max })
                } else {
                    min = previous
                    max = amount - 1
                    text = `Between ${money(min, { minimumFractionDigits: 0 })} and ${money(max, { minimumFractionDigits: 0 })}`
                    arr.push({ text, min, max })
                }
                return arr.map((bracket, index) => {
                    bracket.value = index
                    return bracket
                })
            }, [])
        }
    },
    incomeOptionsFiledAsMap (state, getters) {
        return getters.filings.reduce((map, filing) => {
            map[filing.value] = getters.incomeOptionsFiledAs(filing.value)
            return map
        }, {})
    },
    parseQuote (state, getters) {
        return getters.parsePlan
    },
    carriersReplace (state, getters) {
        return (str = '') => CARRIERS_REPLACEMENTS.reduce((s, patternReplace) => {
            return s.replace(patternReplace[0], patternReplace[1])
        }, str)
    },
    planNameReplace (state, getters) {
        return (plan) => {
            const preferred_name = getters.hasPlanPreferredName(plan)
            if (preferred_name) {
                return preferred_name
            }
            if (!plan.name) {
                return plan.name
            }
            return PLAN_NAME_REPLACEMENTS.reduce((s, patternReplace) => {
                return s.replace(patternReplace[0], patternReplace[1])
            }, plan.name || '')
        }
    },
    planCarriersReplace (state, getters) {
        return (p) => {
            p.original_name = p.name
            p.name = getters.carriersReplace(getters.planNameReplace(p))
            p.carrier = getters.carriersReplace(p.carrier)
            return p
        }
    },
    parsePlan (state, getters) {
        return (plan) => {
            if (!plan || plan.plan_type === 'gp' || plan.parsed) {
                return plan
            }
            plan.parsed = true

            plan.eft_discount = 0
            plan.rules = plan.rules || []
            plan.rules.forEach(rule => {
                const match = rule.message.match(BENEFIT_COST_SHARE_RE)
                if (match?.groups?.dollars && /EFT/.test(rule.message)) {
                    plan.eft_discount = parseInt(match.groups.dollars.replace(/,/g, '')) || 0
                }
            })

            let categories
            if (_.isNil(plan.monthly_premium)) {
                plan.yearly_premium = (parseFloat(plan.annual_premium) || 0) + ((plan.eft_discount || 0) * 12)
                plan.monthly_premium = plan.yearly_premium / 12
            } else {
                plan.yearly_premium = plan.monthly_premium * 12
            }
            plan.effective_date_annual_premium = parseFloat(plan.effective_date_annual_premium || plan.yearly_premium || 0)

            plan.drug_cost_by_pharmacy = plan.drug_cost_by_pharmacy || {}
            plan.drug_data_by_pharmacy = plan.drug_data_by_pharmacy || {}
            plan.drug_info = plan.drug_info || {}
            plan.factors = plan.factors || {}
            plan.deductible = plan.deductible || plan.plan_deductible || plan.part_b_deductible || ''
            plan.drug_cost_by_pharmacy_list = []
            plan.key = plan.id

            // const effectiveDate = getters.survey.effective_date ? moment(getters.survey.effective_date) : null
            function processPharmacyCosts (p, options = {}) {
                // // moved to backend
                // p.original_estimated_monthly_costs = _.cloneDeep(p.estimated_monthly_costs)
                // let d0
                // let monthsDiff = 0
                // if (effectiveDate && p.estimated_monthly_costs && p.estimated_monthly_costs[0]) {
                //     d0 = moment(p.estimated_monthly_costs[0].date)
                //     monthsDiff = effectiveDate.diff(d0, 'months')
                // }
                // if (monthsDiff > 0) {
                //     // const uptoMonth = 12 - monthsDiff
                //     // p.estimated_monthly_costs = p.estimated_monthly_costs.slice(0, uptoMonth)
                //     p.estimated_monthly_costs = p.estimated_monthly_costs.filter((mc, i) => {
                //         const m = moment(mc.date).add(monthsDiff, 'months')
                //         if (m.year() == getters.year) {
                //             mc.date = m.format('YYYY-MM-DD')
                //             return true
                //         } else {
                //             p.estimated_monthly_costs_per_drug_table.splice(i, 1)
                //             p.estimated_monthly_costs_per_drug_differences.splice(i, 1)
                //             return false
                //         }
                //     })
                //     // p.estimated_monthly_costs_per_drug_table = p.estimated_monthly_costs_per_drug_table.slice(0, uptoMonth)
                //     // p.estimated_monthly_costs_per_drug_differences = p.estimated_monthly_costs_per_drug_differences.slice(0, uptoMonth)
                //     if (!p.estimated_monthly_costs_shifted) {
                //         p.estimated_monthly_costs_shifted = true
                //     }
                //     // todo: fix drug_costs[] estimated yearly total
                // }
                // p.estimated_yearly_total = _.sumBy(p.estimated_monthly_costs, mc => mc.total)

                p.cost_yearly = p.estimated_yearly_total || p.cost || 0
                p.cost_monthy = p.cost_yearly / p.estimated_monthly_costs.length
                p.tier5s = []

                // p.drug_costs = _.sortBy(p.drug_costs || [], dc => dc.full_cost)

                p.drug_costs = p.drug_costs.map(dc => {
                    dc = getters.parseDrug(dc)
                    dc.package = getters.packagesMap[dc.ndc]
                    if (options.fix && dc.drug_info && dc.drug_info.tier === 5 && plan.mail_order_pharmacy) {
                        dc = _.clone(plan.mail_order_pharmacy.packages[dc.ndc])
                    } else {
                        dc.drug_info = plan.drug_info[dc.ndc]
                        dc.alternatives = getters.findDrugAlternatives(dc)
                        if (dc.drug_info.tier === 5) {
                            p.tier5s.push(dc)
                        }
                        if (options.add && dc.drug_info && dc.drug_info.tier === 5 && plan.mail_order_pharmacy) {
                            dc.mail_order_drug_cost = _.clone(plan.mail_order_pharmacy.packages[dc.ndc])
                        }
                    }

                    if (dc.coverage_reason === 'COVERED') {
                        dc.is_covered = true
                    }
                    dc.is_not_covered = !dc.is_covered
                    dc.does_count_towards_deductible = _.round(dc.deductible_cost, 1) !== _.round(dc.initial_cost, 1)
                    if (_.round(dc.deductible_cost, 1) === 0 && dc.full_cost > 0) {
                        dc.does_count_towards_deductible = false
                    }
                    dc.does_not_count_towards_deductible = !dc.does_count_towards_deductible
                    return dc
                })

                if (p.monthly_dates_processed) {
                    return
                }
                p.monthly_dates_processed = true

                // backup mg dates
                // p.phase_information.mg_initial_coverage_start = p.phase_information.initial_coverage_start
                // p.phase_information.mg_gap_start = p.phase_information.gap_start
                // p.phase_information.mg_catastrophic_start = p.phase_information.catastrophic_start
                // p.phase_information.initial_coverage_start = null
                // p.phase_information.gap_start = null
                // p.phase_information.catastrophic_start = null
                // p.phase_information.initial_coverage_start = null
                // p.phase_information.deductible_amount = p.phase_information.deductible_amount || plan.drug_deductible || 0
                // p.phase_information.gap_amount = p.phase_information.gap_amount || getters.partDGapEntrance || 0
                // p.phase_information.catastrophic_amount = p.phase_information.catastrophic_amount || getters.partDCatastrophicEntrance || 0
                // p.cumulative_deductible = 0
                // p.cumulative_gap = 0
                // p.cumulative_oop = 0
                // p.cumulative_75percent = 0
                // p.cumulative_catastrophic = 0

                const phases = p.phase_information || {}

                const pemc = p.estimated_monthly_costs
                // const pemc = moment.monthsShort().map((m, i)=> ({ date: `${getters.year}-${_.padStart(`${i + 1}`, 2, '0')}-01` }))

                p.monthly_dates = pemc

                // Avonex is not covered
                // // moved to backend
                // pemc.map(mc => ({ date: mc.date, cost: 0 }))
                //     .map((mc) => {
                //         p.drug_costs.forEach(dc => {
                //             dc.estimated_monthly_costs_map = dc.estimated_monthly_costs_map || {}

                //             let frequencyDays = 30
                //             const farr = dc.frequency.match(/(\d+)/)
                //             if (farr && farr.length) {
                //                 frequencyDays = parseInt(farr[1])
                //             }

                //             if (dc.past_date) {
                //                 const pastDate = moment(dc.past_date)
                //                 const thisDate = moment(mc.date)
                //                 const diff = thisDate.diff(pastDate, 'months') * 30
                //                 if (diff < frequencyDays) {
                //                     return
                //                 }
                //             }

                //             dc.past_date = mc.date
                //             const dmc = _.cloneDeep(mc)

                //             if (dc.does_count_towards_deductible) {
                //                 p.cumulative_deductible += (dc.full_cost || 0)
                //             }
                //             p.cumulative_gap += (dc.full_cost || 0)

                //             dmc.cumulative_deductible = p.cumulative_deductible
                //             dmc.cumulative_gap = p.cumulative_gap

                //             if (p.phase_information.gap_start) {
                //                 p.cumulative_75percent += 75 / 100 * (dc.full_cost || 0)
                //             } else {
                //                 p.cumulative_75percent += 0
                //             }
                //             p.cumulative_catastrophic = p.cumulative_oop + p.cumulative_75percent

                //             dmc.cumulative_oop = p.cumulative_oop
                //             dmc.cumulative_75percent = p.cumulative_75percent
                //             dmc.cumulative_catastrophic = p.cumulative_catastrophic

                //             // i.e Avonex
                //             if ((p.cumulative_deductible >= (phases.deductible_amount || 0)) || p.cumulative_gap >= (phases.gap_amount || 0) || p.cumulative_catastrophic >= (phases.catastrophic_amount || 0)) {
                //                 if (p.cumulative_catastrophic >= (phases.catastrophic_amount || 0)) {
                //                     if (!p.phase_information.catastrophic_start) {
                //                         p.phase_information.catastrophic_start = mc.date
                //                         p.phase_information.catastrophic_start_manual = true
                //                     }
                //                     if (!p.phase_information.catastrophic_with) {
                //                         p.phase_information.catastrophic_with = dc.ndc
                //                         const toCatastrophic = (phases.catastrophic_amount - (p.cumulative_catastrophic - dc.full_cost))
                //                         const left = dc.full_cost - toCatastrophic
                //                         const diff = toCatastrophic + (getters.partDCatastrophicCoveredDrugPercent / 100 * left)
                //                         dmc.cost = diff < dc.gap_cost && diff >= 0 ? diff : dc.gap_cost
                //                         dmc.cost_context = {
                //                             catastrophic_amount: phases.catastrophic_amount,
                //                             cumulative_catastrophic: p.cumulative_catastrophic,
                //                             full_cost: dc.full_cost,
                //                             gap_cost: dc.gap_cost,
                //                             partDCatastrophicCoveredDrugPercent: getters.partDCatastrophicCoveredDrugPercent,
                //                             toCatastrophic,
                //                             toCatastrophic_work: '${catastrophic_amount} - (${cumulative_catastrophic} - ${full_cost})',
                //                             left,
                //                             left_work: '${full_cost} - ${toCatastrophic}',
                //                             diff,
                //                             diff_work: '${toCatastrophic} + (${partDCatastrophicCoveredDrugPercent} / 100 * ${left})',
                //                             cost: dmc.cost,
                //                             cost_work: '${diff} < ${gap_cost} && ${diff} >= 0 ? ${diff} : ${gap_cost}',
                //                         }
                //                         // dmc.cost = dc.catastrophic_cost < dc.full_cost ? diff : dc.catastrophic_cost
                //                     } else {
                //                         dmc.cost = dc.catastrophic_cost
                //                         dmc.cost_context = {
                //                             catastrophic_cost: dc.catastrophic_cost,
                //                             cost: dc.catastrophic_cost,
                //                             cost_work: '${catastrophic_cost}',
                //                         }
                //                     }
                //                 } else if (p.cumulative_gap >= (phases.gap_amount || 0)) {
                //                     if (!p.phase_information.gap_start) {
                //                         p.phase_information.gap_start = mc.date
                //                         p.phase_information.gap_start_manual = true
                //                     }
                //                     if (!p.phase_information.gap_with) {
                //                         p.phase_information.gap_with = dc.ndc
                //                         const toGap = (phases.gap_amount - (p.cumulative_gap - dc.full_cost))
                //                         const left = dc.full_cost - toGap
                //                         const diff = toGap + (getters.partDGapBrandDrugPercent / 100 * left)
                //                         dmc.cost = diff < dc.initial_cost && diff >= 0 ? diff : dc.initial_cost
                //                         // dmc.cost = dc.gap_cost < dc.full_cost ? diff : dc.gap_cost
                //                         dmc.cost_context = {
                //                             full_cost: dc.full_cost,
                //                             cumulative_gap: p.cumulative_gap,
                //                             gap_amount: phases.gap_amount,
                //                             partDGapBrandDrugPercent: getters.partDGapBrandDrugPercent,
                //                             toGap,
                //                             toGap_work: '${gap_amount} - (${p.cumulative_gap} - ${dc.full_cost}))',
                //                             left,
                //                             left_work: '${full_cost} - ${toGap}',
                //                             diff,
                //                             diff_work: '${toGap} + (${partDGapBrandDrugPercent} / 100 * ${left})',
                //                             cost: dmc.cost,
                //                             cost_work: '${diff} < ${initial_cost} && ${diff} >= 0 ? ${diff} : ${initial_cost}',
                //                         }

                //                     } else {
                //                         dmc.cost = dc.gap_cost
                //                         dmc.cost_context = {
                //                             gap_cost: dc.gap_cost,
                //                             cost: dc.gap_cost,
                //                             cost_work: '${gap_cost}',
                //                         }
                //                     }
                //                 } else {
                //                     if (!p.phase_information.initial_coverage_start) {
                //                         p.phase_information.initial_coverage_start = mc.date
                //                         p.phase_information.initial_coverage_start_manual = true
                //                     }
                //                     if (!p.phase_information.initial_coverage_with) {
                //                         p.phase_information.initial_coverage_with = dc.ndc

                //                         if (dc.does_count_towards_deductible) {
                //                             const toDeductible = (phases.deductible_amount - (p.cumulative_deductible - dc.full_cost))
                //                             dmc.cost = phases.deductible_amount ? dc.initial_cost + toDeductible > dc.deductible_cost ? dc.deductible_cost : toDeductible + dc.initial_cost : dc.initial_cost
                //                             dmc.cost_context = {
                //                                 initial_cost: dc.initial_cost,
                //                                 full_cost: dc.full_cost,
                //                                 cumulative_deductible: p.cumulative_deductible,
                //                                 deductible_amount: phases.deductible_amount,
                //                                 toDeductible,
                //                                 toDeductible_work: '${deductible_amount} - (${cumulative_deductible} - ${full_cost})',
                //                                 cost: dmc.cost,
                //                                 cost_work: '${deductible_amount} ? ${initial_cost} + ${toDeductible} > ${deductible_cost} ? ${deductible_cost} : ${toDeductible} + ${initial_cost} : ${initial_cost}',
                //                             }
                //                         } else {
                //                             dmc.cost = dc.initial_cost
                //                             dmc.cost_context = {
                //                                 initial_cost: dc.initial_cost,
                //                                 cost: dc.initial_cost,
                //                                 cost_work: '${initial_cost}',
                //                             }
                //                         }
                //                     } else {
                //                         dmc.cost = dc.initial_cost
                //                         dmc.cost_context = {
                //                             initial_cost: dc.initial_cost,
                //                             cost: dc.initial_cost,
                //                             cost_work: '${initial_cost}',
                //                         }
                //                     }
                //                 }
                //             } else {
                //                 dmc.cost = dc.deductible_cost
                //                 dmc.cost_context = {
                //                     deductible_cost: dc.deductible_cost,
                //                     cost: dc.deductible_cost,
                //                     cost_work: '${deductible_cost}',
                //                 }
                //             }
                //             dc.estimated_monthly_costs_map[mc.date] = dmc
                //             p.cumulative_oop += dmc.cost
                //         })
                //         return mc
                //     })

                p.estimated_yearly_total = options.fix ? _.sumBy(p.drug_costs, dc => dc.estimated_yearly_total) : p.estimated_yearly_total
                p.estimated_monthly_costs = options.fix ? p.estimated_monthly_costs.map((mc, i) => {
                    // subtract the monthly cost of the tier5 drug from this pharmacy, and add its monthly cost from the mail_order_pharmacy
                    // we have to do it for catastrophic, deductible, gap, initial and total
                    // TODO: HALP! we don't have per-drug-month-by-month values
                    return mc
                }) : p.estimated_monthly_costs

                p.estimated_monthly_costs_map = _.keyBy(p.estimated_monthly_costs, 'date')

                p.tier5sMap = _.keyBy(p.tier5s, 'ndc')
                p.packages = _.keyBy(p.drug_costs, 'ndc')
                p.drug_totals = {
                    full_cost: 0,
                    deductible_cost: 0,
                    initial_cost: 0,
                    gap_cost: 0,
                    catastrophic_cost: 0,
                }
                _.each(p.drug_totals, (val, key) => {
                    _.each(p.drug_costs, (cc) => {
                        p.drug_totals[key] += cc[key]
                    })
                })
            }

            // todo: why the f is medicare sending this wrong??
            // todo: track it down in oracle and fix it
            // if (plan?.drug_tiers?.tiers?.length) {
            //     const ts = plan?.drug_tiers?.tiers.slice(0)
            //     const t1 = ts.shift()
            //     ts.push(t1)
            //     plan.drug_tiers.tiers = ts
            // }

            _.each(plan.drug_data_by_pharmacy, (cost, npi) => {
                cost = !_.isNil(cost?.cost) ? cost.cost : cost || 0
                if (!_.isNumber(cost)) {
                    cost = parseFloat(cost) || 0
                }
                npi = npi || 'mail_order'
                const pharmacyData = plan.drug_data_by_pharmacy[npi] || plan.drug_data_by_pharmacy['']
                const pharmacy = getters.pharmaciesMap[npi] || { npi, name: 'Mail Order' }
                const ph = { npi, pharmacy, ...pharmacyData, cost }
                processPharmacyCosts(ph)
                plan.drug_cost_by_pharmacy[npi] = ph
                plan.drug_cost_by_pharmacy_list.push(ph)
            })
            plan.drug_deductible_applicable_tiers = getters.planDrugDeductibleApplicableTiers(plan)
            let guess_drug_deductible_applicable_tiers = true
            if (plan.drug_deductible_applicable_tiers) {
                guess_drug_deductible_applicable_tiers = false
            } else {
                plan.drug_deductible_applicable_tiers = []
            }

            if (plan.drug_cost_by_pharmacy_list.length) {
                const ph0 = plan.drug_cost_by_pharmacy_list[0]
                const drugCosts0 = ph0.drug_costs || []
                drugCosts0.forEach(dc => {
                    plan.drug_info[dc.ndc].is_covered = dc.is_covered
                    plan.drug_info[dc.ndc].is_not_covered = dc.is_not_covered
                    plan.drug_info[dc.ndc].coverage_reason = dc.coverage_reason

                    if (dc.does_count_towards_deductible) {
                        if (guess_drug_deductible_applicable_tiers) {
                            plan.drug_deductible_applicable_tiers.push(plan.drug_info[dc.ndc].tier)
                        }
                        plan.drug_info[dc.ndc].does_count_towards_deductible = true
                        plan.drug_info[dc.ndc].does_not_count_towards_deductible = false
                    } else {
                        plan.drug_info[dc.ndc].does_count_towards_deductible = false
                        plan.drug_info[dc.ndc].does_not_count_towards_deductible = true
                    }
                })
            }
            plan.drug_deductible_applicable_tiers = _.sortBy(_.uniq(plan.drug_deductible_applicable_tiers), v => v)

            plan.mail_order_pharmacy = plan.drug_cost_by_pharmacy_list.filter(pc => pc.pharmacy.npi === 'mail_order')[0]
            if (!plan.mail_order_pharmacy) {
                plan.mail_order_pharmacy = plan.drug_cost_by_pharmacy_list[0]
            }
            delete plan.drug_cost_by_pharmacy['']

            plan.drug_cost_by_pharmacy_list.forEach(p => {
                if (!p.tier5s || !p.tier5s.length || p.npi === 'mail_order') {
                    return p
                }
                // // p.pharmacy_pre_tier5_override = _.cloneDeep(p)
                // subsctract the yearly_cost of each tier5 drug of this pharmacy, then add their cost of the mail_order pharmacy instead
                // // p.cost = p.cost - _.sum(p.tier5s.map(dc => dc.estimated_yearly_total)) + _.sum(p.tier5s.map(dc => plan.mail_order_pharmacy.tier5sMap[dc.package_id].estimated_yearly_total))
                // then re-process with fix=true
                processPharmacyCosts(p, { add: true /* fix: true */ })
            })

            _.each(plan.drug_info, (drugInfo, pid) => {
                drugInfo.initial_package_id = pid
                drugInfo.package = getters.packagesMap[drugInfo.ndc]
                drugInfo.alternatives = getters.findDrugAlternatives(drugInfo)
            })
            const prefer_mail_order = getters.client?.prefer_mail_order
            plan.cheapest_pharmacy = _.minBy(plan.drug_cost_by_pharmacy_list.filter(pc => prefer_mail_order ? pc : pc.pharmacy.npi !== 'mail_order'), 'cost_yearly')
            if (!plan.cheapest_pharmacy) {
                plan.cheapest_pharmacy = plan.mail_order_pharmacy
            }
            plan.estimated_yearly_cost = parseFloat(plan.annual_premium) + (plan.cheapest_pharmacy?.estimated_yearly_total || 0)
            plan.estimated_monthy_cost = plan.estimated_yearly_cost / 12

            plan.effective_date_estimated_yearly_cost = plan.effective_date_annual_premium + (plan.cheapest_pharmacy?.estimated_yearly_total || 0)

            plan.has_preferred_pharmacy = _.some(_.omit(plan.drug_data_by_pharmacy || {}, ['']), 'preferred')

            if (/HMO/i.test(plan.name)) {
                plan.has_any_out_of_network_coverage = true
            }
            if (plan.plan_type === 'mapd' || plan.plan_type === 'ma') {
                plan.has_rx = plan.drug_tiers && !/no rx|norx/i.test(plan.name) ? true : false
            } else if( plan.plan_type === 'pdp') {
                plan.has_rx = true
            }
            if (plan.name) {
                const mt = plan.name.match(/\((HMO POS|HMOPOS|HMO-POS|Regional PPO|PPO|HMO|MSA|PFFS)\)$/i)
                if (mt) {
                    plan.mapd_type = mt[1]
                }
            }
            if (plan.resources?.length) {
                const url = _.find(plan.resources, resource => resource.name === 'Plan Summary')?.url
                plan.document = url ? { url, type: plan.plan_type } : null
            }

            const augmentPlanBenefitCostShareNumeric = function (cost_share, prop_cost_share) {
                const match = cost_share.match(BENEFIT_COST_SHARE_RE)

                let percent = false
                if (match?.groups?.maxdollars) {
                    plan[prop_cost_share] = parseInt(match.groups.maxdollars.replace(/,/g, ''))
                    plan[`${prop_cost_share}_upper`] = parseInt(match.groups.maxdollars.replace(/,/g, ''))
                } else if (match?.groups?.maxpercent) {
                    plan[prop_cost_share] = parseInt(match.groups.maxpercent.replace(/,/g, ''))
                    plan[`${prop_cost_share}_upper`] = parseInt(match.groups.maxpercent.replace(/,/g, ''))
                    percent = true
                }
                if (match?.groups?.mindollars) {
                    plan[prop_cost_share] = parseInt(match.groups.mindollars.replace(/,/g, ''))
                    plan[`${prop_cost_share}_lower`] = parseInt(match.groups.mindollars.replace(/,/g, ''))
                } else if (match?.groups?.minpercent) {
                    plan[prop_cost_share] = parseInt(match.groups.minpercent.replace(/,/g, ''))
                    plan[`${prop_cost_share}_lower`] = parseInt(match.groups.minpercent.replace(/,/g, ''))
                    percent = true
                }
                const inmatch = cost_share.match(IN_NETWORK_BENEFIT_COST_SHARE_RE)
                if (inmatch) {
                    plan[prop_cost_share] = parseInt(inmatch.groups.indollars.replace(/,/g, ''))
                } else {
                    if (match?.groups?.indollars) {
                        plan[prop_cost_share] = parseInt(match.groups.indollars.replace(/,/g, ''))
                    } else if (match?.groups?.dollars) {
                        plan[prop_cost_share] = parseInt(match.groups.dollars.replace(/,/g, ''))
                    } else if (match?.groups?.percent) {
                        plan[prop_cost_share] = parseInt(match.groups.percent.replace(/,/g, ''))
                        percent = true
                    }
                }
                plan[prop_cost_share] = plan[`${prop_cost_share}_upper`] || plan[`${prop_cost_share}_lower`] || plan[prop_cost_share]

                if (percent) {
                    plan[`${prop_cost_share}_percent`] = true
                }
                const note = _.replace(cost_share, /<br( )*\/*>/i, ', ').replace(/--/, '')
                if (note) {
                    plan[`${prop_cost_share}_note`] = note
                }
            }        

            if (plan.benefits) {
                plan.services_list = []
                categories = {}
                if (_.has(plan, 'drug_deductible')) {
                    let cost_share = `${money(plan.drug_deductible)}`
                    let cost_share_note = plan.drug_deductible_applicable_tiers?.length ? `Applies to tiers ${plan.drug_deductible_applicable_tiers[0]}+ medications` : ''

                    plan.benefits['DEDUCTIBLE__DRUG_DEDUCTIBLE'] = { 
                        cost_share,
                        cost_share_note,
                    }
                    augmentPlanBenefitCostShareNumeric(cost_share, 'drug_deductible_cost_share')
                    plan.drug_deductible_cost_share_note = cost_share_note
                }
                _.each(plan.benefits, (value, key) => {
                    let [category, name] = key.split('__')
                    if (!name) {
                        name = category
                        category = 'GENERAL'
                    }
                    if (!category) {
                        category = 'GENERAL'
                    }
                    const originalKey = key
                    const originalCategory = category

                    if (/PREVENTIVE_CARE/.test(name)) {
                        category = 'GENERAL'
                    }
                    if (/HEALTH_PLAN_DEDUCTIBLE|OTHER_DEDUCTIBLES|DEDUCTIBLE/.test(name)) {
                        if (/HEALTH_PLAN_DEDUCTIBLE/.test(name)) {
                            name = ''
                            plan.deductible = value.cost_share
                            augmentPlanBenefitCostShareNumeric(value.cost_share, 'deductible_cost_share')
                        }
                        category = 'GENERAL'
                    }
                    if (/AMBULANCE/.test(name)) {
                        category = 'EMERGENCY_CARE'
                    }
                    if (/EMERGENCY$/.test(name) && !/FOREIGN_TRAVEL_EMERGENCY/.test(name)) {
                        augmentPlanBenefitCostShareNumeric(value.cost_share, 'emergency_copay_cost_share')
                    }
                    if (/EMERGENCY$/.test(name) && /FOREIGN_TRAVEL_EMERGENCY/.test(name)) {
                        augmentPlanBenefitCostShareNumeric(value.cost_share, 'foreign_travel_emergency_copay_cost_share')
                    }
                    if (/URGENT_CARE/.test(name)) {
                        augmentPlanBenefitCostShareNumeric(value.cost_share, 'urgent_care_copay_cost_share')
                    }
                    if (/INPATIENT_HOSPITAL/.test(name)) {
                        augmentPlanBenefitCostShareNumeric(value.cost_share, 'hospitalization_copay_cost_share')
                    }
                    if (/OUTPATIENT_HOSPITAL/.test(name)) {
                        augmentPlanBenefitCostShareNumeric(value.cost_share, 'outpatient_copay_cost_share')
                    }
                    if (/RADIOLOGY_SERVICES/.test(name)) {
                        augmentPlanBenefitCostShareNumeric(value.cost_share, 'radiology_copay_cost_share')
                    }
                    if (/LAB_SERVICES/.test(name)) {
                        augmentPlanBenefitCostShareNumeric(value.cost_share, 'bloodwork_copay_cost_share')
                    }
                    if (/OUTPATIENT_XRAYS/.test(name)) {
                        augmentPlanBenefitCostShareNumeric(value.cost_share, 'xrays_copay_cost_share')
                    }
                    if (/PHYSICAL_THERAPY/.test(name)) {
                        augmentPlanBenefitCostShareNumeric(value.cost_share, 'physical_therapy_copay_cost_share')
                    }
                    if (/PRIMARY/.test(name)) {
                        augmentPlanBenefitCostShareNumeric(value.cost_share, 'pcp_copay_cost_share')
                    }
                    if (/HEARING_AIDS/.test(name)) {
                        augmentPlanBenefitCostShareNumeric(value.cost_share, 'hearing_aids_cost_share')
                    }
                    if (/SPECIALIST/.test(name)) {
                        plan.referral_required = !!value.referral_required
                        plan.referral_not_required = !plan.referral_required
                        augmentPlanBenefitCostShareNumeric(value.cost_share, 'specialist_copay_cost_share')
                    }
                    if (/MAXIMUM_OOPC/.test(name)) {
                        category = 'OUT_OF_POCKET'
                        // "$10,000 In and Out-of-network<br />$6,700 In-network"
                        // "$7,550 In-network"
                        if (/<br \/>/.test(value.cost_share)) {
                            const parts = value.cost_share.split('<br />')
                            value.cost_share = parts[1] + '<br />' + parts[0]
                        }
                        plan.maximum_oopc = _.replace(value.cost_share, /<br( )*\/*>/i, ', ').replace(/--/, '');

                        // todo: i don't think we need this anymore
                        const parts = _.replace(value.cost_share, /<br( )*\/*>/i, '|').replace(/--/, '').split('|')
                        parts.forEach(part => {
                            const match = part.match(BENEFIT_COST_SHARE_RE)
                            if (match?.groups?.dollars && match.input) {
                                const k = match.input.slice(match.index + match[0].length).toLowerCase().replace(/-/g, '_').replace(/\s/g, '')
                                plan[`maximum_oopc_${k}`] = parseInt(match.groups.dollars.replace(/,/g, ''))
                            }
                        })
                        augmentPlanBenefitCostShareNumeric(value.cost_share, 'maximum_oopc_cost_share')
                    }
                    if (/MENTAL_HEALTH/.test(name)) {
                        name = 'GENERAL'
                        category = 'MENTAL_HEALTH'
                    }
                    if (/INPATIENT_HOSPITAL|OUTPATIENT_HOSPITAL|SKILLED_NURSING_FACILITY/.test(name)) {
                        category = 'HOSPITAL'
                    }
                    if (/WELLNESS_PROGRAMS|OPTIONAL_SUPPLEMENTAL_BENEFITS/.test(name)) {
                        category = 'OTHER'
                    }
                    if (category === 'PREVENTIVE_DENTAL') {
                        category = 'BASIC_DENTAL'
                    }

                    const has = value => {
                        return (value && Object.keys(value).filter(k => k === 'cost_share').every(k => value[k] && !/Not covered/i.test(value[k])))
                    }

                    // yup
                    if (/VISION/.test(category)) {
                        plan.has_any_vision = !!(plan.has_any_vision || has(value))
                    }
                    if (/HEARING/.test(category)) {
                        plan.has_any_hearing = !!(plan.has_any_hearing || has(value))
                    }
                    if (/BASIC_DENTAL/.test(category)) {
                        plan.has_any_basic_dental = !!(plan.has_any_basic_dental || has(value))
                    }
                    if (/COMPREHENSIVE_DENTAL/.test(category)) {
                        plan.has_any_comprehensive_dental = !!(plan.has_any_comprehensive_dental || has(value))
                    }
                    if (/WELLNESS_PROGRAMS/.test(name)) {
                        plan.has_any_fitness = true
                    }
                    if (/DISCOUNT/.test(category)) {
                        plan.has_hd_discount = true
                    }

                    key = `${category ? category + '__' : ''}${name}`

                    const flags = []
                    if (value.in_network_only) {
                        flags.push('In-Network only')
                    }
                    if (value.plan_limits) {
                        flags.push('Has cap')
                    }
                    if (value.referral_required) {
                        flags.push('Referral required')
                    }
                    if (value.authorization_required) {
                        flags.push('Authorization required')
                    }
                    if (value.cost_share && value.cost_share.replace) {
                        value.cost_share = value.cost_share.replace(/\n/g, ', ')
                    }
                    value.flags = flags
                    plan.benefits[key] = value

                    categories[category] = categories[category] || { services_list: [], services: {}, name: category }
                    const service = { ...value, category, name, key, flags, originalKey, originalCategory }
                    if (!service.name) {
                        service.name = service.benefit
                    }
                    plan.services_list.push(service)
                    categories[category].services_list.push(service)
                    categories[category].services[service.name] = service
                })

                plan.copays_cost_shares = (plan.emergency_copay_cost_share || 0) + (plan.pcp_copay_cost_share || 0) + (plan.specialist_copay_cost_share || 0)
                plan.copays_and_monthly = (plan.monthly_premium || 0) + plan.copays_cost_shares
                

                plan.categories = categories

                plan.categories_list = _.reduce(plan.categories, (arr, value) => {
                    value.services_list = _.sortBy(value.services_list, 'name')
                    arr.push(value)
                    return arr
                }, [])

                // meh
                const sorted = _.sortBy(plan.categories_list, function(category) {
                    const index = BENEFITS_CATEGORIES_ORDER.indexOf(category.name)
                    // no category, always on the top, index is -1 ? put it last (before drugs)
                    return category.name ? index === -1 ? Number.MAX_SAFE_INTEGER : index : -2
                });
                plan.categories_list = sorted
            }
            plan = getters.planCarriersReplace(plan)

            plan.full_id = plan.full_id || plan.full_plan_id || plan.source_id || plan.supp_type || 'UNKNOWN'
            plan.type = plan.plan_type
            plan.supp = plan.type === 'supp'
            plan.pdp = plan.type === 'pdp'
            plan.mapd = plan.type === 'mapd'
            plan.ma = plan.type === 'ma'
            plan.mapd_or_ma = plan.mapd || plan.ma
            plan.ma_or_mapd = plan.mapd || plan.ma
            plan.plan_type_label = PLAN_TYPE_LABELS[plan.plan_type]
            plan.supp_type = plan.supp_type || plan.id
            plan.supp_name = SUPP_TYPES_NAMES[plan.supp_type] || plan.supp_type

            const carrierData = {}
            plan.carrier_provider_network_link = plan.carrier_provider_network_link || carrierData?.provider_network_link

            if (plan.score_breakdown) {
                delete plan.score_breakdown['total']
                _.forEach(plan.score_breakdown, (score, key) => {
                    plan.score_breakdown[key] = parseFloat(score).toFixed(1)
                })
            }
            if (!plan.score && plan.score_breakdown) {
                plan.score = _.sum(_.values(plan.score_breakdown))
            }

            plan.yearly_deductibles = ('' + (plan.deductible || '')).split('\n').filter(d => d).map(sentence => parseInt(sentence.replace(/[^0-9.-]+/g, '')))
            plan.yearly_deductible = _.sum(plan.yearly_deductibles)

            plan.gap_start = _.get(plan, 'drug_cost_by_pharmacy_list.0.phase_information.gap_start')
            plan.catastrophic_start = _.get(plan, 'drug_cost_by_pharmacy_list.0.phase_information.catastrophic_start')

            if (plan.factors) {
                plan.factors_total = _.sum(_.toArray(plan.factors || {}))
            }

            if (plan.star_ratings) {
                plan.star_ratings = _.reduce(plan.star_ratings, (m, rating, key) => {
                    const k = key.replace(/^STAR_/, '').replace(/_RATING$/, '')
                    m[k] = rating.rating
                    return m
                }, {})
            }

            plan.carrierData = getters.planCarrierData(plan)
            plan.logo = getters.carrierData?.logo || require('~/assets/images/no-img.png')
            plan.logos = [plan.logo]
            return plan
        }
    },
    planCarrierData() {
        return plan => {
            const words = [_.snakeCase(plan.carrier), _.snakeCase(plan.parent_org)].concat(_.words(plan.carrier).map(_.snakeCase))
            let entry
            words.some(word => {
                if (carriers[word]) {
                    entry = carriers[word]
                    return true
                }
            })
            return entry
        }
    },
    isSurveyDirty(state) {
        return state.survey?.updated !== state.summary?.data?.survey_updated
    },
    isProviderInNetwork() {
        return (provider, plan) =>  { 
            return (plan?.in_network_providers || []).some(p => p == provider.npi) 
        }
    },
    isProviderOutNetwork(state, getters) {
        return (provider, plan) =>  { 
            return plan?.out_network_providers ? (plan?.out_network_providers || []).some(p => p == provider.npi) : !getters.isProviderInNetwork(provider, plan)
        }
    },
    isProviderUnsureNetwork(state, getters) {
        return (provider, plan) => !plan?.in_network_providers || !provider?.npi || (!getters.isProviderInNetwork(provider, plan) && !getters.isProviderOutNetwork(provider, plan))
    },
    providers (state) {
        return [].concat(state.survey?.providers || []).concat(state.survey?.unmatched_providers || []).filter(p => p)
    },
    providersInNetwork(state, getters) {
        return plan => getters.providers.filter(p => getters.isProviderInNetwork(p, plan))
    },
    providersOutNetwork(state, getters) {
        return plan => getters.providers.filter(p => getters.isProviderOutNetwork(p, plan))
    },
    providersUnsureNetwork(state, getters) {
        return plan => getters.providers.filter(p => getters.isProviderUnsureNetwork(p, plan))
    },
    getDrugUniquishKey() {
        return drug => `drug-${drug?.rxcui || drug?.package?.drug?.rxcui}-dosage-${drug?.dosage?.rxcui || drug?.package?.dosage?.rxcui}-package-${drug?.package?.ndc}-quantity-${drug?.quantity}-frequency-${drug?.frequency}-excluded-${drug?.excluded || false}`
    },
    isPlanInSummary (state) {
        return plan => (state.summary?.data?.plans || {})[plan.id]
    },
    isUserGroupPlanInSummary (state) {
        return plan => (state.summary?.data?.user_group_plans || {})[plan.id]
    },
    isPlanInOpportunities (state) {
        return plan => _.find(state.opportunities, op => op.informatics_plan_id__c == plan.id)
    },
    aepOpportunities (state, getters) {
        return state.opportunities.filter(opp => opp.Enrollment_Period__c === 'AEP' && (!opp.Effective_Date__c || moment(opp.Effective_Date__c).year() === getters.nextYear || moment(opp.Effective_Date__c).year() === getters.year))
    },
    excludedDrugs (state) {
        return (state?.survey?.drugs || []).filter(d => d.excluded)
    },
    findDrugAlternatives (state) {
        return (drug) => {
            const label = _.toLower(drug?.label || drug?.name || drug?.package?.label || drug || '')
            if (!label) {
                console.warn('Was not able to find a label for drug:', drug)
                return []
            }
            const words = label.split(' ')
            if (state.drugsAlternativesMap[label]) {
                return state.drugsAlternativesMap[label].slice(1)
            }
            let alternatives = []
            words.some((word) => {
                if (state.drugsAlternativesMap[word]) {
                    alternatives = state.drugsAlternativesMap[word].slice(1)
                    return true
                }
                return false
            })
            return alternatives
        }
    },
    paymentMethods () {
        return [
            { value: 'QUARTERLY_MAIL', text: 'Quarterly Mail' },
            { value: 'MONTHLY_SSD', text: 'Monthly Social Security Deduction' },
            { value: 'MONTHLY_EFT', text: 'Monthly EFT (Electronic Funds Transfer)' },
            { value: 'MONTHLY_MAIL', text: 'Monthly Mail' },
            { value: 'UNDECIDED', text: 'Undecided' },
            // { value: 'CREDIT_CARD', text: 'Credit Card' }
        ]
    },
    paymentMethodsMap (state, getters) {
        return _.keyBy(getters.paymentMethods, 'value')
    },
    paymentMethodsPerProduct(state, getters) {
        const all = getters.paymentMethodsMap

        const methods = {
            part_a: [
                // todo: @luke [no-question] we don't ask ss_retirement_benefits
                state.survey?.ss_retirement_benefits ? all.MONTHLY_SSD : all.QUARTERLY_MAIL,
                all.UNDECIDED
            ],
            part_b: [
                state.survey?.ss_retirement_benefits ? all.MONTHLY_SSD : all.QUARTERLY_MAIL,
                all.UNDECIDED,
            ],
            irmaa_part_b: [
                state.survey?.ss_retirement_benefits ? all.MONTHLY_SSD : all.QUARTERLY_MAIL,
                all.UNDECIDED,
            ],
            irmaa_part_d: [
                all.MONTHLY_EFT,
                all.MONTHLY_MAIL,
                all.UNDECIDED,
            ],
            supp: [
                all.MONTHLY_EFT,
                all.MONTHLY_MAIL,
                all.UNDECIDED,
            ],
            pdp: getters.survey?.collecting_ssi
                ? [
                    all.MONTHLY_EFT,
                    all.MONTHLY_SSD,
                    all.MONTHLY_MAIL,
                    all.UNDECIDED,
                ]
                : [
                    all.MONTHLY_EFT,
                    // all.CREDIT_CARD,
                    all.MONTHLY_MAIL,
                    all.UNDECIDED,
                ],
            supp_pdp: [
                all.MONTHLY_EFT,
                all.MONTHLY_MAIL,
                all.UNDECIDED,
            ],
            mapd: getters.survey?.collecting_ssi
                ? [
                    all.MONTHLY_EFT,
                    all.MONTHLY_SSD,
                    all.MONTHLY_MAIL,
                    all.UNDECIDED,
                ]
                : [
                    all.MONTHLY_EFT,
                    // all.CREDIT_CARD,
                    all.MONTHLY_MAIL,
                    all.UNDECIDED,
                ],
            gp: [
                all.MONTHLY_EFT,
            ],
            dental: [
                all.MONTHLY_EFT,
                all.MONTHLY_MAIL,
                all.UNDECIDED,
            ],
            vision: [
                all.MONTHLY_EFT,
                all.MONTHLY_MAIL,
                all.UNDECIDED,
            ],
            tricare: [],
            champva: [],
        }
        return methods
    },
    paymentMethodsPerProductText () {
        return _.map(getters.paymentMethodsPerProduct)
    },
    quarters () {
        return [
            { text: 'Yes', value: 'QUARTERS_40' },
            { text: "No, but I've paid for at least 7 years and 6 months (30 quarters)", value: 'QUARTERS_30_39' },
            { text: "No, I've paid for 7 years and 3 months or less (29 quarters or less)", value: 'QUARTERS_LT_30' },
            { text: 'Not sure', value: null },
        ]
    },
    filings () {
        return [
            { text: 'Filing Single', value: 'SINGLE' },
            { text: 'Filing Jointly', value: 'MARRIED_JOINT' },
            { text: 'Married filing separately', value: 'MARRIED_SEPARATELY' },
        ]
    },
    clientRelationships () {
        return [
            { text: 'My Spouse', value: 'SPOUSE' },
            { text: 'My Parent', value: 'PARENT' },
            { text: 'My Child', value: 'CHILD' },
            { text: 'My Friend', value: 'FRIEND' },
            { text: 'Other', value: 'OTHER' },
        ]
    },
    groupRelationships () {
        return [
            { text: 'Employee', value: 'EMPLOYEE' },
            { text: 'Spouse of employee', value: 'EMPLOYEE_SPOUSE' },
            { text: 'Dependant of employee', value: 'EMPLOYEE_DEPENDENT' },
            { text: 'Parent of employee', value: 'EMPLOYEE_PARENT' },
            { text: 'Other relative of employee', value: 'OTHER_RELATIVE' },
            { text: 'Acquaintance of employee', value: 'ACQUAINTANCE_OF_EMPLOYEE' },
            { text: 'Referred by client', value: 'REFERRED_BY_CLIENT' },
        ]
    },
    genders () {
        return [
            { text: 'Female', value: 'F' },
            { text: 'Male', value: 'M' },
        ]
    },
    yesnos () {
        return [
            { text: 'Yes', value: true },
            { text: 'No', value: false },
        ]
    },
    tobaccos () {
        return [
            { text: 'Yes', value: true },
            { text: 'No', value: false },
        ]
    },
    enrollmentPeriods () {
        return [
            { text: 'Initial Enrollment Period (IEP)', value: 'IEP' },
            { text: 'Annual Open Enrollment Period (AEP)', value: 'AEP' },
            { text: 'Open Enrollment Period (OEP)', value: 'OEP' },
            { text: 'General Enrollment Period (GEP)', value: 'GEP' },
            { text: 'Special Enrollment Period (SEP)/Leaving Employment', value: 'SEP_LEC' },
            { text: 'Special Enrollment Period (SEP)/Moving', value: 'SEP_MOVING' },
            { text: 'Special Enrollment Period (SEP)/Lost Coverage', value: 'SEP_LOST_COVERAGE' },
            { text: 'Special Enrollment Period (SEP)/Lost Medicaid', value: 'SEP_LOST_MEDICAID' },
            { text: '5-Star', value: '5-Star' },

        ]
    },
    reasons () {
        return [
            { text: 'Left employer coverage', value: 'LEFT_EMPLOYER_COVERAGE' },
            { text: 'Switch MAPD', value: 'SWITCH_MAPD' },
            { text: 'Moved', value: 'MOVED' },
            { text: 'Released from incarceration', value: 'INCARCERATION_RELEASED' },
            { text: 'Expat returned', value: 'EXPAT_RETURNED' },
            { text: 'Extra help qualified', value: 'EXTRA_HELP_QUALIFIED' },
            { text: 'Both Medicare & Medicaid', value: 'BOTH_MEDICARE_MEDICAID' },
            { text: 'Moved LTC', value: 'MOVED_LTC' },
            { text: 'Left PAGE', value: 'LEFT_PACE' },
            { text: 'Lost drug coverage', value: 'LOST_DRUG_COVERAGE' },
            { text: 'Pharmacy assistance', value: 'PHARMACY_ASSISTANCE' },
            { text: 'ICHRA', value: 'ICHRA' },
        ]
    },
    distances () {
        return [
            { text: '10 miles', value: 10 },
            { text: '25 miles', value: 25 },
            { text: '50 miles', value: 50 },
            { text: '100 miles', value: 100 },
        ]
    },
    frequencies () {
        return [
            { text: 'Every month', value: 'FREQUENCY_30_DAYS' },
            { text: 'Every 2 months', value: 'FREQUENCY_60_DAYS' },
            { text: 'Every 3 months', value: 'FREQUENCY_90_DAYS' },
            { text: 'Every 6 months', value: 'FREQUENCY_180_DAYS' },
            { text: 'Every 12 months', value: 'FREQUENCY_360_DAYS' },
        ]
    },
    travelTargets () {
        return [
            { text: 'Domestic', value: 'DOMESTIC' },
            { text: 'International', value: 'INTERNATIONAL' },
            { text: 'Both', value: 'BOTH' },
        ]
    },
    preferredPlanClasses () {
        return [
            { text: 'Low Premium & High Copays', value: 'LOW_PREMIUM_HIGH_COPAYS' },
            { text: 'High Premium & Low Out of Pocket Costs', value: 'HIGH_PREMIUM_LOW_OOP' },
            { text: 'No Preference', value: 'NONE' },
        ]
    },
    sourceTypes () {
        return [
            { text: 'Employer', value: 'EMPLOYER' },
            { text: 'Family Member', value: 'FAMILY_MEMBER' },
            { text: 'Financial Advisor', value: 'FINANCIAL_ADVISOR' },
            { text: 'Family First', value: 'FAMILY_FIRST' },
            { text: 'Friend', value: 'FRIEND' },
            { text: 'Doctor', value: 'DOCTOR' },
            { text: 'Google', value: 'GOOGLE' },
            { text: 'Facebook', value: 'FACEBOOK' },
            { text: 'Youtube', value: 'YOUTUBE' },
            { text: 'Linkedin', value: 'LINKEDIN' },
            { text: 'Doctor\'s Choice University', value: 'DCU' },
            { text: 'NFP', value: 'NFP' },
            { text: 'MiEdge', value: 'MIEdge' },
            { text: 'Crunchbase', value: 'CRUNCHBASE' },
            { text: 'Kyruus', value: 'KYRUUS' },
            { text: 'Chatbot', value: 'CHATBOT' },
            { text: 'Webinar', value: 'WEBINAR' },
            { text: 'Other', value: 'OTHER' },
        ]
    },
    sourceTypesMap (state, getters) {
        return _.keyBy(getters.sourceTypes, 'value')
    },
    householdDiscounts () {
        return [
            { text: 'No discount', value: null },
            { text: 'Spouse', value: 'SPOUSE' },
            { text: 'Roommate', value: 'ROOMMATE' },
        ]
    },
    planTypes () {
        return [
            { text: 'Any', value: null },
            { text: 'Supplemental (SUPP)', value: 'SUPP' },
            { text: 'Drug (PDP)', value: 'PDP' },
            { text: 'Advantage (MAPD)', value: 'MAPD' },
            { text: 'N/A', value: 'NA' },
        ]
    },
    abPartsOptions () {
        return [
            { value: 'A', text: 'Part A Only' },
            { value: 'AB', text: 'Parts A and B' },
            { value: null, text: 'None' }
        ]
    },
    preMedicareCoverages () {
        return [
            {
                value: 'EMPLOYER',
                text: 'Insurance from my/my spouse/family member employer'
            },
            {
                value: 'INDIVIDUAL',
                text: 'Individual'
            },
            {
                value: 'COBRA',
                text: 'COBRA'
            },
            {
                value: 'MEDICAID',
                text: 'Medicaid'
            },
            {
                value: 'OTHER',
                text: 'Other insurance, including marketplace'
            },
            {
                value: 'NONE',
                text: 'I don\'t have any insurance'
            }
        ]
    },
    postMedicareCoverages () {
        return [
            {
                value: 'SUPP_PDP',
                text: 'Supplemental Plan + Prescription Drug Plan'
            },
            {
                value: 'MAPD',
                text: 'Medicare Advantage Plan (MAPD)'
            },
            {
                value: 'SUPP',
                text: 'Only Supplemental Plan (SUPP)'
            },
            {
                value: 'PDP',
                text: 'Only Drug Plan (PDP)'
            },
            {
                value: 'NONE',
                text: 'None, just original Medicare (Part A & B)'
            },
            {
                value: 'UNSURE',
                text: 'I\'m not sure'
            },
        ]
    },
    opportunitiesTypes () {
        return [
            {
                value: 'New Business - New to Medicare',
                text: 'New Business - New to Medicare'
            },
            {
                value: 'New Business - Renewal Enrollment',
                text: 'New Business - Renewal Enrollment'
            },
            {
                value: 'Existing Business - Renewal Enrollment',
                text: 'Existing Business - Renewal Enrollment'
            },
        ]
    },
    usStates (state) {
        return (_.isArray(state.usStates) ? state.usStates || [] : []).map(v => ({ text: v, value: v }))
    },
    option(state, getters) {
        return (getter, value, fallback) => {
            return _.find(getters[getter] || [], option => option.value === value)?.text || fallback
        }
    },
    activityChoices (state) {
        return state.activityChoices.map(a => ({ text: a, value: a }))
    },
    carriersProvidersSearchLinks () {
        return CARRIER_PROVIDERS_SEARCH_LINKS
    },
    soaDocuments (state, getters) {
        return (state.soaDocuments || []).map(getters.parseSoaDocument)
    },
    soaDocumentsMap (state, getters) {
        return _.keyBy(getters.soaDocuments, 'id')
    },
    parseSoaDocument () {
        return doc => {
            const now = moment()
            const soa_exp_date = doc.expiration_date ? moment(doc.expiration_date) : null
            const soa_expired = now > soa_exp_date
            const soa_status = doc.status ? doc.status.replace(/document\./, '') : ''
            const soa_gone = soa_status === 'voided' || soa_status === 'declined' || soa_status === 'rejected' || soa_expired
            return {
                ...doc,
                soa_link: (doc.recipients || []).filter(r => r.role === 'Client')[0]?.shared_link,
                soa_exp_date,
                soa_expired,
                soa_status,
                soa_emailable: !soa_gone && (soa_status == 'viewed' || soa_status == 'sent' || soa_status == 'draft'),
                soa_viewed: soa_status == 'viewed',
                soa_completed: soa_status == 'completed',
                soa_gone,
            }
        }
    },
    soaDocument0 (state, getters) {
        return getters.soaDocuments?.length ? getters.soaDocuments[0] : null
    },
    soaDocument0Link (state, getters) {
        return getters.soaDocument0?.soa_link
    },
    scheduleHelpText (state) {
        return state.scheduleHelpText
    },
    scheduleHelpTextEmailOptIn (state) {
        return state.scheduleHelpText?.email_opt_in
    },
    commonMixinComputed () {
        return CommonMixin.computed
    },
    cardMixinComputed () {
        return { ...CardMixin.mixins[0].computed, ...CardMixin.computed }
    },
    commonMixinKeys (state, getters) {
        return Object.keys(getters.commonMixinComputed)
    },
    cardMixinKeys (state, getters) {
        return Object.keys(getters.cardMixinComputed)
    },
    surveyKeys (state, getters) {
        return state.survey ? Object.keys(state.survey).filter(k => !getters.commonMixinComputed.hasOwnProperty(k) && !getters.cardMixinComputed.hasOwnProperty(k)) : []
    },
    variables (state, getters) {
        return _.uniq(getters.surveyKeys.concat(getters.cardMixinKeys).concat(getters.commonMixinKeys))
    },
    variablesOptions (state, getters) {
        return getters.variables.map(k => ({ label: k, raw: `{{ ${k} }}`, value: getters.window.$root.vueTemplateToHtml(`{{ ${k} }}`) }))
    },
    variablesComputed (state, getters) {
        return {
            ...getters.commonMixinComputed,
            ...getters.cardMixinComputed,
        }
    },
    variablesData (state, getters) {
        const d = {...(state.survey || {})}
        getters.cardMixinKeys.forEach(k => {
            delete d[k]
        })
        getters.commonMixinKeys.forEach(k => {
            delete d[k]
        })
        return d
    }
}
