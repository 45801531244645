
import _ from 'lodash'

export default {
    props: {
        results: {
            type: Array,
            default: () => []
        },
        value: {
            type: Array,
            default: () => []
        },
        removable: {
            type: Boolean,
            default: false
        },
        showSelectedOnly: {
            type: Boolean,
            default: false
        },
    },
    methods: {
        findPharmacyIndex (pharmacy) {
            return _.findIndex(this.value, p => p.npi === pharmacy.npi)
        },
        isPharmacyInList (pharmacy) {
            return this.findPharmacyIndex(pharmacy) > -1
        }
    }
}
