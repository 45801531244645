
export default function ({ store, $axios /*, redirect */ }, inject) {
    $axios.defaults.xsrfHeaderName = 'x-csrftoken'
    $axios.defaults.xsrfCookieName = 'csrftoken'

    // e.response.data.detail

    $axios.onRequest((config) => {
        config.withCredentials = process?.env?.NODE_ENV === 'development'
        // console.log('Making request to ' + config.url, config)
    })

    // $axios.onError(error => {
    //     const code = parseInt(error.response && error.response.status)
    //     if (code === 400) {
    //         redirect('/400')
    //     }
    // })
}
