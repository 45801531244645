import _ from 'lodash'
import { hashCode } from '@/util/string'

const createApi = function ({ store, $axios }) {
    const api = {
        $pendingGETPromises: {},
        $pendingSources: {},
        $cancelPending () {
            for (const key in api.$pendingSources) {
                api.$pendingSources[key].cancel('Request canceled')
                delete api.$pendingSources[key]
            }
        },
        async $send (retries, method, ...args) {
            if (typeof retries === 'string') {
                args.unshift(method)
                method = retries
                retries = 0
            }
            const uri = args[0]
            const hashed = hashCode(JSON.stringify({ method, args }))
            const sourcePrefix = `${method}::${uri}`
            const sourceKey = `${sourcePrefix}__${hashed}`

            try {
                const source = $axios.CancelToken.source()
                const cancelToken = source.token
                api.$pendingSources[sourceKey] = source
                const ret = await $axios[method](...[...args, { cancelToken }])
                // const ret = await $axios[method](...args)
                const d = ret?.data || ret
                if (d && _.isObject(d)) {
                    d.from_cache = !!parseInt(ret.headers['from-cache']) || !!d.from_cache
                }
                return d
            } catch (e) {
                if ($axios.isCancel(e)) {
                    console.log('[app] request canceled', e.message)
                } else if (e.response && (e.response.status === 504 || e.response.status === 502) && retries < 3) {
                    console.warn(`[app] caught error ${e.response.status}, retrying...`, e)
                    return api.$send(retries + 1, method, ...args)
                } else {
                    throw store.getters.parseError(e)
                }
            } finally {
                setTimeout(() => {
                    delete api.$pendingSources[sourceKey]
                })
            }
        },
        $fget (...args) {
            return api.$send('get', ...args)
        },
        $get (...args) {
            // for GET calls, if many are made at the "same" time,
            // only make the first, make the rest wait for its response
            const hashed = hashCode(JSON.stringify(args))
            if (api.$pendingGETPromises[hashed]) {
                api.$pendingGETPromises[hashed].count++
                console.warn('[app] back-to-back identical unfinished GET requests, ' +
                    "I'm just going to send one of them, the latter will wait and get the same results, " +
                    `Count=${api.$pendingGETPromises[hashed].count}, index=${api.$pendingGETPromises[hashed].count - 1}, args=${[...args]}`)
                return new Promise((resolve, reject) => {
                    api.$pendingGETPromises[hashed].promise.then((d) => {
                        resolve(_.cloneDeep(d))
                    }).catch(reject)
                })
            }
            const p = api.$send('get', ...args).finally(() => delete api.$pendingGETPromises[hashed])
            api.$pendingGETPromises[hashed] = {
                promise: p,
                count: 1
            }
            return p
        },
        $post (...args) {
            return api.$send('post', ...args)
        },
        $put (...args) {
            return api.$send('put', ...args)
        },
        $patch (...args) {
            return api.$send('patch', ...args)
        },
        $delete (...args) {
            return api.$send('delete', ...args)
        },
        getState () {
            return api.$get('/api/accounts/state/')
        },
        register (data) {
            return api.$post('/api/accounts/register/', data)
        },
        login (data) {
            return api.$post('/api/accounts/login/', data)
        },
        logout () {
            return api.$post('/api/accounts/logout/')
        },
        onEdge () {
            return api.$post('/api/accounts/on-edge/')
        },
        offEdge () {
            return api.$post('/api/accounts/off-edge/')
        },
        changePassword (data) {
            return api.$post('/api/accounts/change-password/', data)
        },
        resetPasswordStart (data) {
            return api.$post('/api/accounts/reset-password/start/', data)
        },
        resetPasswordFinish (data) {
            return api.$post('/api/accounts/reset-password/finish/', data)
        },
        clientIntake (data) {
            return api.$post('/api/accounts/intake/', data)
        },
        getMagicRedirectUrl (token, redirect) {
            return `${store.getters.hostUrl}/api/accounts/magic_redirect/${token}/${redirect ? encodeURIComponent(redirect) : ''}`
        },
        getPasswordResetUrl (token) {
            return `${store.getters.effectiveHostUrl}/reset-password/${token}`
        },
        getVerifyEmailUrl (token) {
            return `${store.getters.hostUrl}/api/accounts/email-verify/finish/${token}/`
        },
        magicRedirect (token, redirect) {
            return api.$get(api.getMagicRedirectUrl(token, redirect))
        },
        emailVerifyStart () {
            return api.$post('/api/accounts/email-verify/start/')
        },
        sendEmail (data) {
            return api.$post('/api/accounts/send-email/', data)
        },
        sendLoginLink (data) {
            return api.$post('/api/accounts/send-magic-link/', data)
        },
        sendSupportEmail (data) {
            return api.$post('/api/accounts/send-group-support-email/', data)
        },
        renderEmail (route, data) {
            return api.$post(`/render-api/render-email/${route}`, data)
        },
        generatePDF (data) {
            return api.$post('/api/extras/generate-pdf/', data)
        },
        getExistingClientToken (data) {
            return api.$post('/api/scheduling/client-token/', data)
        },
        // eslint-disable-next-line camelcase
        getAgentsForFips ({ fips, active_ok }) {
            return api.$post('/api/scheduling/agents/', { fips, active_ok })
        },
        getSchedulingAvailableDates (data) {
            return api.$post('/api/scheduling/dates/', data)
        },
        getSchedulingAvailableTimes (data) {
            return api.$post('/api/scheduling/times/', data)
        },
        bookSchedulingTime (data) {
            return api.$post('/api/scheduling/book/', data)
        },
        getSurvey ({ id, aep }) {
            return api.$get(`/api/consults/survey/${id ? `${id}/` : ''}${aep ? `?aep=${aep}` : ''}`)
        },
        getNlRecStatus (id) {
            return api.$get(`/api/consults/survey/${id}/nl-rec-status/`)
        },
        getClient ({ id, aep }) {
            return api.getSurvey({ id, aep })
        },
        getAgent (crmId) {
            return api.$get(`/api/accounts/agent/${crmId ? `${crmId}/` : ''}`)
        },
        deactivateClient (id, data = {}) {
            return api.$post(`/api/accounts/deactivate/${id}/`, data)
        },
        reactivateClient (id) {
            return api.$post(`/api/accounts/reactivate/${id}/`)
        },
        getAgents () {
            return api.$post('/api/consults/agents/')
        },
        updateSurvey ({ id, data }) {
            return api.$patch(`/api/consults/survey/${id ? `${id}/` : ''}`, data)
        },
        completeSurvey (data = {}) {
            return api.$post('/api/consults/survey/complete/', data)
        },
        agentCompleteSurvey (uid, data = {}) {
            return api.$post(`/api/consults/survey/agent-complete/${uid}/`, data)
        },
        getSummary (id) {
            return api.$get(`/api/consults/summary/${id ? `${id}/` : ''}`)
        },
        resetSummary ({ id, data }) {
            return api.$patch(`/api/consults/summary/${id}/reset`, { data })
        },
        updateSummary ({ id, data }) {
            return api.$patch(`/api/consults/summary/${id}/`, { data })
        },
        uploadSummaryFile ({ id, data }) {
            return api.$post(`/api/consults/summary/${id}/files`, { data })
        },
        deleteSummaryFile ({ id, data }) {
            return api.$delete(`/api/consults/summary/${id}/files`, { data })
        },
        createSummary (data) {
            return api.$post('/api/consults/summary/', data)
        },
        sendSummary ({ id, data }) {
            return api.$post(`/api/consults/summary/${id}/send/`, data)
        },
        sendPreConsultSurvey ({ id, data }) {
            return api.$post(`/api/consults/send-pre-consult-survey/${id}/`, data)
        },
        keepPlans (body) {
            return api.$post('/api/consults/keep-plans/')
        },
        agentKeepPlans (body) {
            return api.$post('/api/consults/agent-keep-plans/', body)
        },
        startEnrollment (body) {
            return api.$post('/api/consults/start-enrollment/', body)
        },
        agentStartEnrollment (body) {
            return api.$post('/api/consults/agent-start-enrollment/', body)
        },
        getPlan (id) {
            return api.$get(`/api/plan/${id}/`)
        },
        getGroup (id) {
            return api.$get(`/api/accounts/group/${id}/`)
        },
        updateGroup (group) {
            return api.$patch(`/api/accounts/group/${group?.id}/`, group)
        },
        getGroupWebinars (id, params = {}) {
            return api.$get(`/api/accounts/group/${id}/webinars/`, { params })
        },
        deactivateGroup (id) {
            return api.$post(`/api/accounts/group/deactivate/${id}/`)
        },
        reactivateGroup (id) {
            return api.$post(`/api/accounts/group/reactivate/${id}/`)
        },
        getSummaryFiles (id) {
            return api.$get(`/api/consults/summary/${id}/files/`)
        },
        roseAiCreateCompletion (data) {
            return api.$post('/api/extras/rose-ai/completion/', data)
        },
        aiCreateCompletion (data) {
            return api.$post('/api/extras/ai/completion/', data)
        },
        uploadSentryShot (form) {
            return api.$post('/api/extras/sentry-shots/', form)
        },
        uploadFiles (form = {}, params = {}) {
            return api.$post('/api/extras/files/', form, { params })
        },
        deleteFile (id) {
            return api.$delete(`/api/extras/files/${id}/`)
        },
        updateFile (file) {
            return api.$put(`/api/extras/files/${file.file_id || file.id}/`, file?.metadata?.available_states || [])
        },
        createGroupPlan (body) {
            return api.$post('/api/extras/group-plans/', body)
        },
        updateGroupPlan (body) {
            return api.$patch('/api/extras/group-plans/', body)
        },
        searchGroupPlans (params) {
            return api.$get('/api/extras/group-plans/', { params })
        },
        getAllGroupPlanFiles (params) {
            return api.$get('/api/extras/files/all/', { params })
        },
        updateUserGroupPlan (body) {
            return api.$patch('/api/extras/user-group-plans/', body)
        },
        searchUserGroupPlans (params) {
            return api.$get('/api/extras/user-group-plans/', { params })
        },
        getAllUserGroupPlans (params) {
            return api.$get('/api/extras/user-group-plans/all/', { params })
        },
        getAllGroupPlans (params) {
            return api.$get('/api/extras/group-plans/all/', { params })
        },
        getUserGroupPlan (id) {
            return api.$get(`/api/extras/user-group-plans/${id}/`)
        },
        getGroupPlan (id) {
            return api.$get(`/api/extras/group-plans/${id}/`)
        },
        deleteGroupPlan (id) {
            return api.$delete(`/api/extras/group-plans/${id}/`)
        },
        updatePlanExtras (body) {
            return api.$post('/api/extras/plans/', body)
        },
        getSoaDocuments (clientId, params = {}) {
            return api.$get(`/api/accounts/soa-documents/${clientId}/`, { params })
        },
        getLatestSoaDocuments (clientId) {
            return api.$get(`/api/accounts/soa-documents-latest/${clientId}/`)
        },
        getSoaDocument (documentId) {
            return api.$get(`/api/accounts/soa-document/${documentId}/`)
        },
        generateSoaDocumentLink (clientId) {
            return api.$post(`/api/accounts/soa-document/${clientId}/`)
        },
        getItem (key) {
            return api.$get(`/api/extras/item/${key}/`)
        },
        getItems (params) {
            return api.$get('/api/extras/items/', { params })
        },
        setItem (key, value, options = {}) {
            return api.$post(`/api/extras/item/${key}/`, { value, ...options })
        },
        deleteItem (key) {
            return api.$delete(`/api/extras/item/${key}/`)
        },
        getGroupReport (params) {
            return api.$get('/api/accounts/group-report/', { params })
        },
        emailGroupReport (body) {
            return api.$post('/api/accounts/email-group-report/', body)
        },
        searchGroups (params) {
            return api.$get('/api/accounts/group/', { params })
        },
        searchCommissions (params) {
            if (params.group_by) {
                return api.$get('/api/accounts/commissions-grouped/', { params })
            }
            return api.$get('/api/accounts/commissions/', { params })
        },
        getCrmOpportunities (params) {
            return api.$get('/api/consults/crm-opportunities/', { params })
        },
        updateCrmOpportunities (data) {
            return api.$patch('/api/consults/crm-opportunities/', data)
        },
        updateCrmOpportunitiesCorrectedProducts (data) {
            return api.$patch('/api/consults/crm-opportunities-correct-products/', data)
        },
        updateCrmOpportunitiesCorrectedV2Products (data) {
            return api.$patch('/api/consults/crm-opportunities-correct-v2-products/', data)
        },
        getCrmOpportunitiesStages (params = {}) {
            return api.$get('/api/consults/crm-opportunities/stages/', { params })
        },
        getCommands () {
            return api.$get('/api/accounts/commands/')
        },
        getCurrentCommands () {
            return api.$get('/api/accounts/commands/current/')
        },
        executeCommand (body) {
            return api.$post('/api/accounts/command/', body)
        },
        getActivityChoices () {
            return api.$get('/api/accounts/activity-choices/')
        },
        getActivity (params) {
            return api.$get('/api/accounts/activity/', { params })
        },
        getActivityAll (params) {
            return api.$get('/api/accounts/activity-all/', { params })
        },
        getRelatedClients (id) {
            return api.$get(`/api/accounts/related-clients/${id}/`)
        },
        getCrmClient (id) {
            return api.$get(`/api/accounts/crm-client/${id}/`)
        },
        getMatchingClients (id) {
            return api.$get(`/api/accounts/matching-clients/${id}/`)
        },
        getActiveCall () {
            return api.$get('/api/accounts/active-call/')
        },
        getOpportunities (id) {
            return api.$get(`/api/accounts/opportunities/${id ? `${id}/` : ''}`)
        },
        getCmsSsaOpportunities (id) {
            return api.$get(`/api/accounts/cms_ssa_opportunities/${id ? `${id}/` : ''}`)
        },
        ensureCmsSsaOpportunities (id, data) {
            return api.$post(`/api/accounts/cms_ssa_opportunities/${id ? `${id}/` : ''}ensure/`, data)
        },
        getActiveProducts (id) {
            return api.$get(`/api/accounts/active-products/${id ? `${id}/` : ''}`)
        },
        searchClients (params) {
            return api.$get('/api/accounts/client/', { params })
        },
        searchMatchingClients (params) {
            return api.$get('/api/accounts/matching-clients/', { params })
        },
        searchDuplicateClients (params) {
            return api.$get('/api/accounts/duplicate-clients/', { params })
        },
        searchAgents (params) {
            return api.$get('/api/accounts/agent/', { params })
        },
        getQuotes ({ id, year, fresh, detailed, version = 2 }) {
            return api.$get(`/api/consults/quotes/${id ? `${id}/` : ''}`, { params: { year, fresh, detailed, version }, timeout: 180000 })
        },
        getSetting ({ key }) {
            return api.$get(`/api/settings/setting/${key}/`)
        },
        setSetting ({ key, value }) {
            return api.$post(`/api/settings/setting/${key}/`, { value })
        },
        getCountiesForZip (zipcode) {
            return api.$get(`/api/proxies/oracle/counties_for_zip/${zipcode}/`)
        },
        getTimezoneForZip (zipcode) {
            return api.$get(`/api/proxies/oracle/timezone_for_zip/${zipcode}/`)
        },
        searchProviders (params) {
            return api.$get('/api/proxies/oracle/provider_search/', { params })
        },
        searchProviders3 (params) {
            return api.$get('/api/proxies/oracle/provider_search3/', { params })
        },
        searchPharmacies (params) {
            return api.$get('/api/proxies/oracle/pharmacy_search/', { params })
        },
        searchDrugs (params) {
            return api.$get('/api/proxies/oracle/drug_search/', { params })
        },
        getDrugOptions ({ year, rxcui }) {
            return api.$get(`/api/proxies/oracle/drug_options/${year}/${rxcui}/`)
        },
        getDrugCosts (params) {
            return api.$get('/api/proxies/oracle/drug_cost/', { params })
        },
        searchConditions (params) {
            return api.$get('/api/proxies/oracle/conditions_search/', { params })
        },
        searchProcedures (params) {
            return api.$get('/api/proxies/oracle/procedures_search/', { params })
        },
        getProcedureCost (code) {
            return api.$get('/api/proxies/oracle/procedure_costs/', { params: { code } })
        },
        getUsStates () {
            return api.$get('/api/consults/survey/states/')
        },
        slackConnect () {
            return api.$get('/api/accounts/slack-auth/start/')
        },
        doubleCheckProviders (data) {
            return api.$post('/api/proxies/oracle/providers_check/', data)
        },
        doubleCheckPrice (data) {
            return api.$post('/api/proxies/oracle/carrier_price/', data)
        },
        async getEnrollmentPeriodRecommendation (id) {
            const res = await api.$get(`/api/extras/ai/recommend-enrollment-period/${id}/?user=${id}`)
            return res.choices[0].message.content
        },
    }
    return api
}

export default (context, inject) => {
    const $api = createApi(context)
    inject('api', $api)
}
