
import { mapGetters } from 'vuex'
import surveyForm from '~/mixins/survey-form'

export default {
    mixins: [surveyForm],
    autosave: true,
    data () {
        return {
            form: {
                notes: null,
            },
            schedulingContexts: [],
        }
    },
    computed: {
        ...mapGetters([
            'uid',
            'survey',
        ]),
    },
    mounted () {
        this.getRecentSchedulerAcivities()
    },
    methods: {
        async getRecentSchedulerAcivities () {
            const { items } = await this.$try('getActivity', { activity: 'schedule', client_id: this.uid, size: 3 }, { spinner: false })
            const contexts = await Promise.all(items.map(a => a.context?.data?.notes ? a.contex : this.getActivityContext(a)))
            this.schedulingContexts = [].concat(contexts.filter(c => c?.data?.notes))
        },
        async getActivityContext (activity) {
            const res = await this.$try('$get', `/api/accounts/activity/${activity.id}/context.json`, { spinner: false, alert: false })
            if (!res?.error) {
                return res
            }
        }
    }
}
