
import moment from 'moment'
import Inputmask from 'inputmask'

const now = moment()

const TYPES = {
    'date-us': {
        format: 'MM/DD/YYYY',
        outFormat: 'YYYY-MM-DD',
        pattern: '[0-9]{2}/[0-9]{2}/[0-9]{4}',
        placeholder: now.format('MM/DD/YYYY'),
    },
    'datetime-us': {
        format: 'MM/DD/YYYY HH:mm',
        pattern: '[0-9]{2}/[0-9]{2}/[0-9]{4} [0-9]{2}:[0-9]{2}',
        placeholder: now.format('MM/DD/YYYY HH:mm'),
    },
    'datetime-local': {
        format: moment.HTML5_FMT.DATETIME_LOCAL,
        pattern: '[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}',
        placeholder: now.format(moment.HTML5_FMT.DATETIME_LOCAL),
    },
    'datetime-local-seconds': {
        format: moment.HTML5_FMT.DATETIME_LOCAL_SECONDS,
        pattern: '[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}',
        placeholder: now.format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS),
    },
    'datetime-local-ms': {
        format: moment.HTML5_FMT.DATETIME_LOCAL_MS,
        pattern: '[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{3}',
        placeholder: now.format(moment.HTML5_FMT.DATETIME_LOCAL_MS),
    },
    date: {
        format: moment.HTML5_FMT.DATE,
        pattern: '[0-9]{4}-[0-9]{2}-[0-9]{2}',
        placeholder: now.format(moment.HTML5_FMT.DATE),
    },
    time: {
        format: moment.HTML5_FMT.TIME,
        pattern: '[0-9]{2}:[0-9]{2}',
        placeholder: now.format(moment.HTML5_FMT.TIME),
    },
    'time-seconds': {
        format: moment.HTML5_FMT.TIME_SECONDS,
        pattern: '[0-9]{2}:[0-9]{2}:[0-9]{2}',
        placeholder: now.format(moment.HTML5_FMT.TIME_SECONDS),
    },
    'time-ms': {
        format: moment.HTML5_FMT.TIME_MS,
        pattern: '[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{3}',
        placeholder: now.format(moment.HTML5_FMT.TIME_MS),
    },
    week: {
        format: moment.HTML5_FMT.WEEK,
        pattern: '[0-9]{4}-W[0-9]{2}',
        placeholder: now.format(moment.HTML5_FMT.WEEK),
    },
    month: {
        format: moment.HTML5_FMT.MONTH,
        pattern: '[0-9]{4}-[0-9]{2}',
        placeholder: now.format(moment.HTML5_FMT.MONTH),
    },
}

const KEYS = Object.keys(TYPES)
KEYS.forEach((KEY) => {
    TYPES[KEY].regexp = new RegExp(TYPES[KEY].pattern)
})

export default {
    props: {
        value: {
            type: [String, Object, Date],
            default: null,
        },
        type: {
            type: String,
            default: 'date-us',
            validator (value) {
                return KEYS.includes(value)
            },
        },
        id: {
            type: String,
            default: null,
        },
        name: {
            type: String,
            default: null,
        },
        format: {
            type: String,
            default: null,
        },
        outFormat: {
            type: String,
            default: null,
        },
        className: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
        list: {
            type: String,
            default: null,
        },
        step: {
            type: [String, Number],
            default: null,
        },
        min: {
            type: [String, Object, Date, Number],
            default: null,
        },
        max: {
            type: [String, Object, Date, Number],
            default: null,
        },
        disabled: {
            type: [Boolean, String],
            default: false,
        },
        required: {
            type: [Boolean, String],
            default: false,
        },
        readonly: {
            type: [Boolean, String],
            default: false,
        },
        autocomplete: {
            type: [Boolean, String],
            default: 'off',
        },
        utc: {
            type: Boolean,
            default: false,
        },
        valueAsMoment: {
            type: Boolean,
            default: false,
        },
        valueAsDate: {
            type: Boolean,
            default: false,
        },
        valueAsNumber: {
            type: Boolean,
            default: false,
        },
        state: {
            type: Boolean,
            default: null,
        },
    },

    data () {
        return {}
    },

    computed: {
        model: {
            get () {
                let m
                if (this.value && ((typeof this.value === 'string' /* && this.isPatternMatch(this.value) */) || moment.isMoment(this.value) || moment.isDate(this.value))) {
                    m = this.toMoment(this.value)
                } else {
                    // eslint-disable-next-line
                    this.$refs.input && (this.$refs.input.invalid = true)
                }
                return m && m.isValid() ? m.format(this.dformat) : this.value
            },
            set (value) {
                let m
                let val
                if (value) {
                    m = this.toMoment(value)
                } else {
                    this.$emit('input', null)
                    return null
                }
                if (!m || !m.isValid()) {
                    val = value
                } else if (this.$refs.input && !this.$refs.input.validity.valid) {
                    val = value
                    this.$emit('invalid', value)
                } else {
                    if (m) {
                        if (this.valueAsMoment) {
                            val = m.clone()
                        } else if (this.valueAsDate) {
                            val = m.toDate()
                        } else if (this.valueAsNumber) {
                            val = m.unix()
                        } else {
                            val = m.format(this.doutFormat)
                        }
                    } else {
                        val = value
                    }
                    this.$emit('input', val)
                }
                return val
            },
        },
        dtype () {
            let t = this.type
            if (this.dstep) {
                if (this.dstepDecimals === 3) {
                    t = `${t.replace(/-ms/, '')}-ms`
                } else if (!this.dstepDecimals) {
                    t = `${t.replace(/-seconds/, '')}-seconds`
                }
            }
            return TYPES[t] || TYPES[this.type] || TYPES['date-us']
        },
        dstepDecimals () {
            return this.dstep && this.dstep % 1 ? this.dstep.toString().split('.')[1].length : 0
        },
        dstep () {
            return this.step ? parseFloat(this.step) : null
        },
        dmin () {
            return this.min ? this.toMoment(this.min).format(this.dformat) : null
        },
        dmax () {
            return this.max ? this.toMoment(this.max).format(this.dformat) : null
        },
        dformat () {
            return this.format || this.dtype.format
        },
        doutFormat () {
            return this.outFormat || this.dtype.outFormat || this.dformat
        },
        dpattern () {
            return this.pattern || this.dtype.pattern
        },
        dplaceholder () {
            return this.placeholder || this.dtype.placeholder
        },
        dregexp () {
            return this.dtype.regexp
        },
    },

    mounted () {
        this.setupMask()
    },
    methods: {
        setupMask () {
            if (this.$refs.input) {
                return Inputmask(this.dtype.placeholder.replace(/\d/g, '9'))
                    .mask(this.$refs.input)
            }
            setTimeout(() => {
                this.setupMask()
            }, 500)
        },
        toMoment (...args) {
            return this.utc ? moment.utc(...args) : moment(...args)
        },
        isPatternMatch (value) {
            return this.dregexp.test(value)
        },
        select (...args) {
            return this.$refs.input.select(...args)
        },
        stepDown (...args) {
            return this.$refs.input.stepDown(...args)
        },
        stepUp (...args) {
            return this.$refs.input.stepUp(...args)
        },
    },
}
