
import Vue from 'vue'
import _ from 'lodash'
import * as GmapVue from '~/node_modules/gmap-vue'

Vue.use(GmapVue, {
    load: {
        key: 'AIzaSyC1NpIFKKGi8BmfXG3y3B8GEWDyThXChPk',
        libraries: 'places'
    },
})
export default {
    components: {
        GmapVue,
        GmapCluster: GmapVue.Cluster,
        GmapMarker: GmapVue.Marker,
        GmapInfoWindow: GmapVue.InfoWindow,
    },
    props: {
        markers: {
            type: Array,
            default () { return [] }
        },
        center: {
            type: Object,
            default: null,
        },
        fitMakers: {
            type: Boolean,
            default: true
        },
    },
    data () {
        return {
            infoWindows: {}
        }
    },
    computed: {
        positions () {
            return this.markers.map(m => m.position)
        },
        bounds () {
            /* eslint-disable */
            if (!google) {
                return null
            }
            const bounds = new google.maps.LatLngBounds()
            this.positions.forEach((position) => {
                bounds.extend(position)
            })
            return bounds
        },
        marker0 () {
            return this.markers[0]
        },
        position0 () {
            return this.marker0?.position
        },
        center0 () {
            return this.center || this.position0 || { lat: 0, lng: 0 }
        },
        minLatMarker () {
            return _.maxBy(this.markers, m => m.position.lat)
        },
        maxLngMarker () {
            return _.minBy(this.markers, m => m.position.lng)
        },
        map () {
            return this.gmap
        },
    },
    watch: {
        markers () {
            return this.fitBounds()
        },
    },
    errorCaptured (err) {
        // I don't want this error to propagate, bury it here
        // this error occurs when the Map->GampMap is trying to lazy-load and the page gets switched,
        // for some reason, it's still trying to load but the parent-element is not there anymore
        if (/Map: Expected mapDiv/.test(err?.message)) {
            console.warn(err)
            return false
        }
    },
    mounted () {
        return this.fitBounds()
    },
    methods: {
        async fitBounds () {
            if (this.fitMakers) {
                const map = await this.getMap()
                map.fitBounds(this.bounds, 50)
                if (map.getZoom() > 17) map.setZoom(17)
            }
        },
        async getMap () {
            if (this.gmap) {
                return this.gmap
            }
            this.gmap = await this.$refs.mmm.$mapPromise
            return this.gmap
        },
    }
}
