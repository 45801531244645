
import { mapGetters } from 'vuex'
import { name as toName } from '~/util/string'

export default {
    props: {
        summary: {
            type: Object,
            default: null
        },
        client: {
            type: Object,
            default: null
        },
    },
    data () {
        return {
            form: {
                to: this.client?.email || '',
                subject: `${this.canAgent ? `Medicare Overview: ${toName(this.client, { skipPreferred: true })}` : `${toName(this.client, { skipPreferred: true })}'s ` + (this.$store.getters.summaryAep ? `AEP Plan Summary ${this.$store.getters.year}` : `Medicare Overview for ${this.summary.year}`)}`,
                light: true,
                message: `
                        <p>Dear ${this.$_.startCase(this.client.preferred_name || this.client.first_name)},</p>
                        <p>It was wonderful speaking with you today! I would like to follow up after our consultation and provide you with the following documents (attached):</p>

                        <ul>
                            ${this.$store.getters.summaryAep ? '<li>AEP Plan Summary</li>' : '<li>Medicare Consultation Summary</li>'}
                            ${this.$store.getters.summaryAep ? '' : '<li>Plan Benefit Brochures</li>'}
                        </ul>

                        <p>
                            Please don't hesitate to reach out if you have any questions. ${new Date().getDay() < 5 ? 'Hope you have a wonderful rest of your week!' : 'Hope you have a wonderful weekend!'}
                        <br>
                        <p>
                            <span>${toName(this.$store.getters.user, { skipPreferred: true })}</span>
                            </br>
                            <span>Office: <a target="_blank" rel="noopener noreferrer nofollow" href="tel:+14014047373">401-404-7373</a></span>
                            </br>
                            <span>Email: <a target="_blank" rel="noopener noreferrer nofollow" href="mailto:${this.$store.getters.user.email}">${this.$store.getters.user.email}</a></span>
                        </p>
                `
            },
        }
    },
    computed: {
        ...mapGetters(['canAgent', 'summaryAep', 'summaryPlansList']),
        emails () {
            return (this.form.to || '').split(',').map(to => to.trim()).filter(to => !!to)
        },
        possibleNonDownloadableFilesPlans () {
            return this.summaryPlansList.filter(plan => /humana/i.test(plan.carrier) || /humana/i.test(plan.name))
        }
    },
    methods: {
        async send () {
            const { error } = await this.$try('sendSummary', {
                id: this.summary.id,
                data: {
                    ...this.form,
                    to: this.emails
                }
            })
            if (!error) {
                this.$success(`Summary ${this.canAgent ? this.summary.id + ' ' : ' '}sent!`)
                this.$emit('success')
            }
        }
    },
}
