
import _ from 'lodash'
export default {
    props: {
        client: {
            type: Object,
            default: null
        },
        attendees: {
            type: Array,
            default: () => []
        },
        autoAdvance: {
            type: Boolean,
            default: true
        },
        addingAttendees: {
            type: Boolean,
            default: true,
        }
    },
    data () {
        return {
            clientsList: [],
            checking: false,
        }
    },
    computed: {
        returningClients: {
            get () {
                return this.clientsList.filter(c => c.returning)
            },
            cache: false,
            immediate: true

        }
    },
    watch: {
        attendees: 'updateClientsList',
        client: 'updateClientsList'
    },
    mounted () {
        this.updateClientsList()
        if (!this.returningClients.length) {
            return this.gtfo()
        }
        return this.checkClients()
    },
    methods: {
        updateClientsList () {
            this.clientsList = [{ ...this.client, client0: true }]
            if (this.addingAttendees) {
                this.clientsList = this.clientsList.concat(_.cloneDeep(this.attendees))
            }
        },
        getClientSetValuePrefix (client) {
            if (!client.client0) {
                return {
                    list: 'attendees',
                    index: _.findIndex(this.attendees, c => c.email === client.email && c.dob === client.dob)
                }
            }
            return {}
        },
        async checkClients () {
            if (this.surveyModel?.lastDirection === -1) {
                this.previous()
            } else {
                this.checking = true
                const results = await Promise.all(this.returningClients.map(c => this.$try('getExistingClientToken', { email: c.email, dob: c.dob }, { alert: false })))
                results.forEach((data, i) => {
                    const e = data?.error || data?.detail || data?.detail?.error
                    if (e) {
                        const c = this.returningClients[i]
                        c.token = null
                        c.error = typeof e === 'string' ? e : e?.message || 'Something went wrong while checking your info.'
                        return
                    }

                    const message = `Welcome back ${this.$_.startCase(this.returningClients[i].preferred_name || this.returningClients[i].first_name)}!`
                    const setValuePrefix = this.getClientSetValuePrefix(this.returningClients[i])

                    this.$emit('set-value', { ...setValuePrefix, key: 'message', value: message })
                    this.$emit('set-value', { ...setValuePrefix, key: 'token', value: data.token })
                    this.$emit('set-value', { ...setValuePrefix, key: 'agent', value: data.agent })
                    this.$emit('set-value', { ...setValuePrefix, key: 'agents', value: data.agents })
                    this.$emit('set-value', { ...setValuePrefix, key: 'preferred_name', value: data.preferred_name })
                    this.$emit('set-value', { ...setValuePrefix, key: 'effective_date', value: data.effective_date })
                    this.$emit('set-value', { ...setValuePrefix, key: 'zip_code', value: data.zip_code })
                    this.$emit('set-value', { ...setValuePrefix, key: 'fips', value: data.fips })
                    this.$emit('set-value', { ...setValuePrefix, key: 'source_type', value: data.source_type })
                    this.$emit('set-value', { ...setValuePrefix, key: 'source_name', value: data.source_name })
                    if (data.first_name) {
                        this.$emit('set-value', { ...setValuePrefix, key: 'first_name', value: data.first_name })
                    }

                    this.$emit('client', this.returningClients[i])
                    if (this.returningClients[i].client0) {
                        this.$emit('input', data.token)
                    }

                    this.returningClients[i].token = data.token
                    this.returningClients[i].first_name = data.first_name
                    this.returningClients[i].preferred_name = data.preferred_name
                    this.returningClients[i].effective_date = data.effective_date
                    this.returningClients[i].zip_code = data.zip_code
                    this.returningClients[i].fips = data.fips
                    this.returningClients[i].source_type = data.source_type
                    this.returningClients[i].source_name = data.source_name
                    this.returningClients[i].message = message
                })

                if (this.autoAdvance && this.clientsCheckedFound()) {
                    this.next()
                }
                this.checking = false
            }
        },
        clientsCheckedFound () {
            return this.returningClients.every(c => c.token)
        },
        tokens () {
            return this.returningClients.map(c => c.token)
        },
        gtfo () {
            if (this.surveyModel?.lastDirection === -1) {
                this.previous()
            } else {
                this.next()
            }
        },
        previous () {
            // eslint-disable-next-line no-constant-condition
            if (this.surveyModel) {
                this.surveyModel.prevPage()
            } else {
                // todo: i know i know this will never reach,
                // but it's not working anyways, event is not reaching vcomponent vm.$on('previous', handler)
                this.$emit('previous')
            }
        },
        next () {
            if (this.surveyModel) {
                this.surveyModel.nextPage()
            } else {
                this.$emit('next')
            }
        }
    }
}
