
import card from '~/mixins/card'

// national averages
const averages = {
    pcp_copay_cost_share: 171,
    specialist_copay_cost_share: 171,
    radiology_copay_cost_share: 650,
    bloodwork_copay_cost_share: 432,
    emergency_copay_cost_share: 1200,
    xrays_copay_cost_share: 290,
    outpatient_copay_cost_share: 5000, // 4000-170000
    hospitalization_copay_cost_share: 2883, // per day
}

export default {
    mixins: [card],
    props: {
        displayPlans: {
            type: Array,
            default: () => []
        },
        showPrint: {
            type: Boolean,
            default: false
        },
    },
    data () {
        return {
            userGroupPlanModal: false,
            editingUserGroupPlan: null,
            rows: {
                deductible: 'Annual Medical Deductible',
                drug_deductible_cost_share: 'Annual Prescription Deductible',
                maximum_oopc_cost_share: 'Annual Out of Pocket Maximum',
                pcp_copay_cost_share: 'Primary Care Visit',
                specialist_copay_cost_share: 'Specialist Visit',
                emergency_copay_cost_share: 'Emergency Room',
                hospitalization_copay_cost_share: 'Hospitalization',
                outpatient_copay_cost_share: 'Outpatient Procedures (Surgery)',
                radiology_copay_cost_share: 'Radiology',
                bloodwork_copay_cost_share: 'Bloodwork',
                xrays_copay_cost_share: 'X-Rays',
                physical_therapy_copay_cost_share: 'Physical Therapy'

            },
            boolRows: {
                has_any_basic_dental: 'Dental',
                has_any_vision: 'Vision',
                has_any_hearing: 'Hearing',
                has_any_fitness: 'Gym/Fitness Benefits',
                has_any_otc: 'Over the Counter Benefits'
            },
            averages
        }
    },
    computed: {
        comparingPlans () {
            return this.displayPlans
        },
    },
    methods: {
        canShowPropRow (prop) {
            return this.comparingPlans.every((plan) => {
                if (plan?.plan_type === 'gp') {
                    if (plan.config && (!plan.config.ignore || !plan.config.ignore[prop])) {
                        return true
                    }
                    return false
                }
                return true
            })
        },
        comboName (c) {
            return c.name || c.map(p => p.name).join(' + ')
        },
        comboId (c) {
            return c.id || c.map(p => p.id).join('_')
        },
    }
}
