
export default {
    props: {
        value: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            val: this.value
        }
    },
    watch: {
        val () {
            this.$emit('input', this.val)
        }
    }
}
