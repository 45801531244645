
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
    props: {
        value: {
            type: [Object],
            default: null
        },
    },
    data () {
        return {
            use_age: null,
            form: {
                dob: null,
                manual_age: null,
            },
        }
    },
    computed: {
        ...mapGetters(['dobx']),
        usingAge: {
            get () {
                return false
                // if (_.isBoolean(this.use_age)) {
                //     return this.use_age
                // }
                // return this.form.manual_age && !this.form.dob
            }
        },
    },
    watch: {
        use_age (v) {
            if (v === true) {
                this.$set(this.form, 'dob', null)
            }
        },
        value (v) {
            if (!_.isEqual(v, this.form)) {
                this.updateValue()
            }
        },
    },
    mounted () {
        this.updateValue()
        this.$watch('form', {
            deep: true,
            handler () {
                if (!_.isEqual(this.form, this.value)) {
                    return this.emitInput()
                }
            }
        })
    },
    methods: {
        updateValue () {
            this.form = _.cloneDeep(_.pick(this.value || {}, ['dob', 'manual_age']))
        },
        emitInput () {
            this.$emit('input', this.form)
        }
    }
}
