
import { mapGetters } from 'vuex'

import card from '~/mixins/card'
export default {
    mixins: [card],
    computed: {
        ...mapGetters([
            's3Base',
            'summaryAttachments'
        ]),
    },
    methods: {
        isAttachmentExcluded (attachment) {
            return (this.card_config_value?.excluded || []).includes(attachment.full_path)
        },
    }
}
