
/* eslint-disable import/no-named-as-default */
import { mapGetters } from 'vuex'
import {
    Editor,
    EditorContent,
    BubbleMenu,
    FloatingMenu,
    VueRenderer,
    VueNodeViewRenderer
} from '@tiptap/vue-2'
import tippy from 'tippy.js'
import StarterKit from '@tiptap/starter-kit'
import TaskItem from '@tiptap/extension-task-item'
import BulletList from '@tiptap/extension-bullet-list'
import OrderedList from '@tiptap/extension-ordered-list'
import ListItem from '@tiptap/extension-list-item'
import TaskList from '@tiptap/extension-task-list'
import Image from '@tiptap/extension-image'
import Link from '@tiptap/extension-link'
import Table from '@tiptap/extension-table'
import TableHeader from '@tiptap/extension-table-header'
import TableCell from '@tiptap/extension-table-cell'
import TableRow from '@tiptap/extension-table-row'
import Underline from '@tiptap/extension-underline'
import Highlight from '@tiptap/extension-highlight'
import { Color } from '@tiptap/extension-color'
import TextStyle from '@tiptap/extension-text-style'
import Heading from '@tiptap/extension-heading'
import TextAlign from '@tiptap/extension-text-align'
import { Variable } from '@talqui-oss/tiptap-extension-variable'
import { Node, mergeAttributes } from '@tiptap/core'
import EditorMenu from './EditorMenu'
import VariablesList from './VariablesList'
import NodeView from './NodeView.vue'

const ConditionalBlock = Node.create({
    name: 'ConditionalBlock',
    group: 'block',
    content: 'block*',
    draggable: true,
    addAttributes () {
        return {
            condition: {
                default: null,
            },
            content: {
                default: ''
            },
            tag: {
                default: 1,
                parseHTML: element => element.getAttribute('tag'),
                renderHTML: (attributes) => {
                    if (!attributes.tag) {
                        return {}
                    }

                    return {
                        tag: attributes.tag,
                    }
                },
            },
        }
    },
    parseHTML () {
        return [
            {
                tag: 'div',
                getAttrs: node => node.hasAttribute('tag')
            },
        ]
    },
    renderHTML ({ node, HTMLAttributes }) {
        const tag = node.attrs.tag
        const directive = tag === '1' ? 'v-if' : tag === '2' ? 'v-else-if' : 'v-else'
        HTMLAttributes[directive] = tag !== '3' ? node.attrs.condition : ''
        node.attrs[directive] = tag !== '3' ? node.attrs.condition : true
        const el = document.createElement('div')
        el.innerHTML = HTMLAttributes.content
        HTMLAttributes.content = ''
        return ['div', mergeAttributes(HTMLAttributes, { 'data-type': 'draggable-item' }), el]
    },
    addNodeView () {
        return VueNodeViewRenderer(NodeView)
    },
})

const customDiv = Node.create({
    name: 'customDiv',
    group: 'block',
    content: 'block*',
    draggable: false,
    parseHTML () {
        return [
            {
                tag: 'div',
                getAttrs: node => !(/tiptap/.test(node.classList) || node.hasAttribute('tag'))

            },
        ]
    },

    renderHTML ({ HTMLAttributes }) {
        return ['div', HTMLAttributes, 0]
    },
})

// by default the text extension of tiptap converts text nodes and spans to p tags, this is why this extension is added
const spanText = Node.create({
    name: 'spanText',
    group: 'block',
    content: 'inline*',
    draggable: false,
    parseHTML () {
        return [
            {
                tag: 'span',
            },
        ]
    },

    renderHTML ({ HTMLAttributes }) {
        return ['span', HTMLAttributes, 0]
    },
})

const extendExtension = function (Extension) {
    if (['highlight', 'textAlign'].includes(Extension.name)) {
        return Extension
    }
    return Extension.extend({
        addAttributes () {
            return {
                ...this.parent?.(),
                // maintain stuff
                style: {
                    default: null,
                    keepOnSplit: true,
                    parseHTML: element => element.getAttribute('style') || null
                },
                href: {
                    default: null,
                    keepOnSplit: true,
                    parseHTML: element => element.getAttribute('href') || null
                },
                class: {
                    default: null,
                    keepOnSplit: true,
                    parseHTML: (element) => {
                        return element.getAttribute('class') || null
                    },
                },
                id: {
                    default: null,
                    parseHTML: element => element.getAttribute('id') || null
                },
                scope: {
                    default: null,
                    keepOnSplit: true,
                    parseHTML: element => element.getAttribute('scope') || null
                },
            }
        },
    })
}
const variableExtensionConfig = (customItems) => {
    return {
        customItems,
        renderHTML ({ options, node }) {
            return [
                'span',
                { style: '' },
                `${node.attrs.id}`,
            ]
        },
        suggestion: {
            render: () => {
                let component
                let popup

                return {
                    onStart: (props) => {
                        component = new VueRenderer(VariablesList, { command: () => {}, ...props })

                        if (!props.clientRect) {
                            return
                        }

                        popup = tippy('body', {
                            getReferenceClientRect: props.clientRect,
                            content: component.element,
                            showOnCreate: true,
                            interactive: true,
                            trigger: 'manual',
                            placement: 'bottom-start',
                        })
                    },

                    onUpdate (props) {
                        component.updateProps(props)

                        if (!props.clientRect) {
                            return
                        }

                        popup[0].setProps({
                            getReferenceClientRect: props.clientRect,
                        })
                    },

                    onKeyDown (props) {
                        if (props.event.key === 'Escape') {
                            popup[0].hide()

                            return true
                        }

                        return component?.ref?.onKeyDown(props)
                    },

                    onExit () {
                        popup[0]?.destroy()
                        component.destroy()
                    },
                }
            },
        },
    }
}

export default {
    components: {
        EditorMenu,
        EditorContent,
        BubbleMenu,
        FloatingMenu,
        NodeView
    },
    props: {
        value: {
            type: String,
            default: null
        },
        editable: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        advanced: {
            type: Boolean,
            default: false
        }
    },
    data () {
        const advancedExtensions = [
            ConditionalBlock,
            Variable.configure(variableExtensionConfig(this.customItems)),
        ]
        return {
            editor: null,
            extensions: [
                BulletList,
                OrderedList,
                ListItem,
                TaskItem,
                TaskList,
                Image,
                Link,
                Table,
                TableHeader,
                TableCell,
                TableRow,
                Underline,
                TextStyle,
                Heading,
                customDiv,
                spanText,
                TextAlign.configure({ types: ['heading', 'paragraph'] }),
                Color.configure({ types: ['textStyle'] }),
                Highlight.configure({ multicolor: true }),
                ...(this.advanced ? advancedExtensions : []),
            ].map(extension => extendExtension(extension))
        }
    },
    computed: {
        ...mapGetters(['variablesOptions']),
    },
    watch: {
        value (value) {
            if (this.editor.getHTML() === value) {
                return
            }
            this.editor.commands.setContent(this.value, false)
            this.$emit('update', this.editor)
        },
    },
    mounted () {
        this.editor = new Editor({
            useBuiltInExtensions: false,
            content: this.value,
            editable: this.editable,
            onUpdate: () => {
                this.$emit('input', this.editor.getHTML())
            },
            onFocus: () => {
                this.$emit('editor', this.editor)
            },
            extensions: [
                StarterKit.configure({ heading: false, bulletList: false, orderedList: false, listItem: false }),
                ...this.extensions,
            ],
        })
        this.$emit('editor', this.editor)
    },
    beforeDestroy () {
        this.editor.destroy()
    },
    methods: {
        customItems: ({ query }) => {
            if (query.length > 1) {
                return this.variablesOptions.filter(item => item.label.toLowerCase().startsWith(query.toLowerCase())).slice(0, 5)
            }
            return this.variablesOptions
        },
    },
}
