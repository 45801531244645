
import { mapGetters } from 'vuex'
export default {
    async fetch () {
        if (this.uid) {
            const resp = await this.$try('getCrmClient', this.uid, { spinner: false })
            if (resp?.client) {
                this.$store.commit('crmClient', resp?.client)
            }
        }
    },
    computed: {
        ...mapGetters(['crmClient', 'client', 'crmBase', 'uid'])
    },
}
