import { render, staticRenderFns } from "./EditFileForm.vue?vue&type=template&id=037bacaf&lang=pug&"
import script from "./EditFileForm.vue?vue&type=script&lang=js&"
export * from "./EditFileForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports