
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
    props: {
        name: {
            type: String,
            default: ''
        },
        html: {
            type: String,
            default: null
        },
        magicUrl: {
            type: String,
            default: '/'
        },
        type: {
            type: String,
            default: 'default'
        },
        mode: {
            type: [String, Object],
            default: 'default'
        },
        aep: {
            type: Boolean,
            default: null
        },
        index: {
            type: Number,
            default: null
        },
        title: {
            type: String,
            default: null
        },
        params: {
            type: Object,
            default: () => ({})
        },
        edited: {
            type: Boolean,
            default: false
        },
        editable: {
            type: Boolean,
            default: false
        },
        draggable: {
            type: Boolean,
            default: false
        },
        checkable: {
            type: Boolean,
            default: false
        },
        configurable: {
            type: Boolean,
            default: false
        },
        removable: {
            type: Boolean,
            default: false
        },
        comboPlans: {
            type: Array,
            default: () => []
        },
        comboIndex: {
            type: Number,
            default: null
        },
    },
    data () {
        return {}
    },
    computed: {
        ...mapGetters([
            'survey',
        ]),
        readonly () {
            return !this.editable && !this.draggable && !this.checkable && !this.configurable && !this.removable
        },
        componentName () {
            const txt = _.camelCase(this.name || '')
            return txt.charAt(0).toUpperCase() + txt.substr(1)
        },
        cardComponentName () {
            return `Card${this.componentName}`
        },
        isComponentRegistered () {
            return !!this.$options.components[this.cardComponentName]
        },
    },
    methods: {}
}
