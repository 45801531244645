
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
    props: {
        value: {
            type: Object,
            default () { return {} }
        },
        type: {
            type: String,
            default: 'pdp'
        },
    },
    data () {
        return {
            unwatch: null,
            form: {
                plan: null,
                plan1: null,
                plan2: null,
            }
        }
    },
    computed: {
        ...mapGetters(['summaryPlansList']),
        plans () {
            return this.summaryPlansList.filter(plan => plan.plan_type === this.type)
        },
        plansMap () {
            return _.keyBy(this.plans, 'id')
        },
        plans12 () {
            return [this.plansMap[this.form.plan1], this.plansMap[this.form.plan2]].filter(plan => !!plan)
        },
        plansOptions () {
            return this.plans12.map(this.option)
        },
    },
    watch: {
        value () {
            if (!_.isEqual(this.value, this.form)) {
                this.updateForm()
            }
        },
    },
    mounted () {
        this.updateForm()
        this.unwatch = this.$watch('form', {
            deep: true,
            handler () {
                this.$emit('input', _.cloneDeep(this.form))
            }
        })
    },
    destroyed () {
        this.unwatch && this.unwatch()
    },
    methods: {
        updateForm () {
            this.form = _.cloneDeep(this.value || {})
            if (this.plansOptions.length === 1) {
                this.$set(this.form, 'plan', this.plansOptions[0].id)
            }
        },
        option (plan, idx) {
            const text = `${idx ? 'Switch to ' : 'Keep '}${plan.name}`
            return { id: plan.id, text, name: text }
        }
    }
}
