
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { hashObject } from '@/util/string'

export default {
    data () {
        return {
            system: 'rose',
            messages: [],
            message: null,
            loadingMessage: false,
            REC_START: /\n/g,
            REC_STOP: /\n/g,
        }
    },
    fetch () {
        // this.autopopulateStatus()
        return this.loadMessages()
    },
    computed: {
        ...mapGetters(['survey', 'client']),
        history () {
            return this.messages.map(message => [message.question, message.body])
        },
        storageKey () {
            return `aichat-${this.client?.id}`
        },
    },
    methods: {
        async sendMessage () {
            const message = this.message
            this.messages.push({ body: message, author: 'you' })
            this.matchScroll()
            this.message = ''
            this.loadingMessage = true

            const results = await this.$try(this.system === 'rose' ? 'roseAiCreateCompletion' : 'aiCreateCompletion', { text: message, history: this.history }, { spinner: false })
            this.loadingMessage = false
            if (!results.error) {
                results.system = this.system === 'rose' ? 'Rose AI' : 'General AI'
                results.body = _.get(results, 'text') || _.get(results, 'choices.0.message.content') || _.get(results, 'choices.0.text')
                results.hash = hashObject(results)
                results.question = message

                // if (this.REC_START.test(message)) {
                //     results.body = 'I recommend ' + results.body
                // }

                this.messages.push(results)
                this.saveMessages()
                this.matchScroll()
            }
        },
        matchScroll () {
            this.$nextTick(() => {
                const messageDisplay = this.$refs?.chatArea
                if (!messageDisplay) return
                messageDisplay.scrollTop = messageDisplay.scrollHeight
            })
        },
        async loadMessages () {
            const response = await this.$try('getItem', this.storageKey)
            this.messages = response?.value?.messages || []
            this.matchScroll()
        },
        saveMessages () {
            return this.$try('setItem', this.storageKey, { messages: this.messages }, { type: 'aichat', client: this.survey.id }, { spinner: false })
        },
        clearMessages () {
            this.messages = []
            return this.$try('deleteItem', this.storageKey)
        },
        async autopopulateStatus () {
            const result = await this.$try('getNlRecStatus', this.survey.id)
            if (!result.error) {
                this.message = `Can you recommend a medicare plan for me? ${result.nl_rec_status}\n`
            }
        }
    },
}
