
import { calcCoverage, fromDob } from '~/util/date'

export default {
    props: {
        merge: {
            type: Object,
            default: () => ({})
        },
        showInnerForm: {
            type: Boolean,
            default: false,
        }
    },
    data () {
        return {
            form: {
                hsa: null,
                dob: null,
                collecting_ssi: null,
                employer_coverage: null,
                employer_coverage_p65: null,
                pre_medicare_end_date: null,
                disabled: null,
                disabled_date: null,
                has_part_a: null,
                part_a_date: null,
                has_part_b: null,
                part_b_date: null,
            }
        }
    },
    fetch () {
        this.updateForm()
    },
    computed: {
        coverage () {
            return calcCoverage(this.form)
        },
        dobx () {
            return fromDob(this.form.dob) || {}
        },
        part_a_date_in_the_past () {
            return !this.form.part_a_date || this.$moment(this.form.part_a_date).isBefore(this.$moment())
        },
        part_b_date_in_the_past () {
            return !this.form.part_b_date || this.$moment(this.form.part_b_date).isBefore(this.$moment())
        },
    },
    watch: {
        merge: {
            deep: true,
            handler () {
                this.updateForm()
            }
        },
        'form.pre_medicare_coverage' (value) {
            this.form.employer_coverage = value === 'EMPLOYER'
        },
        'form.has_part_a' (value) {
            if (!value) {
                this.form.part_a_date = null
            }
        },
        'form.has_part_b' (value) {
            if (!value) {
                this.form.part_b_date = null
            }
        },
        'form.part_a_date' (date) {
            if (date) {
                this.form.has_part_a = true
            }
        },
        'form.part_b_date' (date) {
            if (date) {
                this.form.has_part_b = true
            }
        }
    },
    methods: {
        updateForm () {
            if (this.merge) {
                for (const key in this.merge) {
                    const value = this.merge[key]
                    this.$set(this.form, key, value)
                }
            }
        },
    },
}
