
import _ from 'lodash'
import moment from 'moment'
import timezones from 'timezones-list'
import { mapGetters, mapState } from 'vuex'

const timezonesOptions = timezones.map(tz => ({ ...tz, text: tz.label, value: tz.tzCode }))

export default {
    props: {
        attendees: {
            type: Array,
            default: () => [],
        },
        method: {
            type: String,
            default: 'phone',
        },
        aep: {
            type: Boolean,
            default: null,
        },
        notes: {
            type: String,
            default: '',
        },
        agent: {
            type: Object,
            default: null,
        },
        inWizard: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        const today = moment()
        return {
            today: today.toDate(),
            tomorrow: today.clone().add(1, 'days').toDate(),
            agents: [],
            datesLoading: false,
            dates: [],
            minimumDate: null,
            timesLoading: null,
            schedulingLoading: null,
            times: null,
            minutes: null,
            timezones: timezonesOptions,
            timezonesMap: _.keyBy(timezonesOptions, 'value'),
            form: {
                keep_agent: null,
                force_agent: null,
                appointment: {
                    selected_date: null,
                    selected_time: null,
                    selected_agent: null,
                    aep: this.inWizard ? this.aep : this.$store.getters.isPreAEP || !!parseInt(this.$route.query.aep),
                    skip_soa: false,
                    skip_survey: false,
                    notes: '',
                    month: moment().format('YYYY-MM'),
                    time: null,
                    timezone: this.$store.getters.canAgent ? 'America/New_York' : Intl.DateTimeFormat().resolvedOptions().timeZone,
                    datetime: null,
                },
            }
        }
    },
    computed: {
        ...mapGetters(['support', 'isAEPScheduling']),
        ...mapState({
            scheduleHelpText: state => state.scheduleHelpText
        }),
        client0 () {
            return this.clients[0]
        },
        returning () {
            return this.client0?.returning
        },
        token () {
            return this.client0?.token
        },
        fips () {
            return this.client0?.location?.fips || this.client0?.fips
        },
        clients () {
            return this.attendees.filter(client => !client.caretaker)
        },
        caretakers () {
            return this.attendees.filter(client => client.caretaker)
        },
        eligibleAgents () {
            return this.agents
                .filter((agent) => {
                    if (this.useVideo) {
                        return agent.eligible && agent.agent_video_consult
                    }
                    if (this.useEmailOrSurvey) {
                        return agent.eligible && agent.agent_email_consult
                    }
                    return agent.eligible
                })
                .map((agent) => {
                    agent.text = `${agent.first_name} ${agent.last_name}`
                    return agent
                })
        },
        eligibleAgentsMap () {
            return _.keyBy(this.eligibleAgents, agent => agent?.id)
        },
        filteredAgents () {
            if (this.canAgent && this.form.force_agent && this.eligibleAgentsMap[this.form.force_agent]) {
                return [this.eligibleAgentsMap[this.form.force_agent]]
            }
            if (this.agent && this.form.keep_agent === 1) {
                return [this.agent]
            }
            if (this.agent && this.form.keep_agent === 0) {
                return this.eligibleAgents.filter(agent => agent?.id !== this.agent?.id)
            }
            return this.eligibleAgents.filter(a => a)
            // return (this.returning ? this.eligibleAgents : [this.eligibleAgents[0]]).filter(a => a)
        },
        filteredAgentsMap () {
            return _.keyBy(this.filteredAgents, 'id')
        },
        selectedAgent () {
            return this.filteredAgentsMap[this.form.appointment.selected_agent]
        },
        canSelectDate () {
            if (this.canAgent || this.inWizard) {
                return true
            }
            if (!this.client0) {
                return false
            }
            if (this.returning) {
                return this.eligibleAgents.length && (this.client0?.token || this.client0?.id)
            }
            const ccc = this.eligibleAgents.length &&
                this.client0 &&
                (
                    this.client0?.token
                        ? (
                            this.client0?.email &&
                        this.client0?.dob
                        )
                        : (
                            this.client0?.first_name &&
                        this.client0?.last_name &&
                        this.client0?.email &&
                        this.client0?.phone &&
                        this.hasMinimumFields
                        )
                )
            return ccc
        },
        hasMinimumFields () {
            return this.client0?.token ? true : this.fips && this.client0?.gender && this.client0?.dob
        },
        datesMap () {
            return _.keyBy(this.dates, d => d)
        },
        timesMap () {
            return _.keyBy(this.times || [], t => t)
        },
        useVideo () {
            return this.method === 'video'
        },
        usePhone () {
            return this.method === 'phone'
        },
        useEmail () {
            return this.method === 'email'
        },
        useSurvey () {
            return this.method === 'survey'
        },
        useEmailOrSurvey () {
            return this.useEmail || this.useSurvey
        },
        zip () {
            return this.client0?.location?.zip_code || this.form?.location?.zip_code || this.client0?.zip_code
        },
    },
    watch: {
        filteredAgentsMap: {
            deep: true,
            handler (a, b) {
                if (!_.isEqual(a, b)) {
                    return this.loadDates()
                }
            }
        },
        returning () {
            return this.resetDates()
        },
        zip (newZip, oldZip) {
            if (newZip && newZip !== oldZip) {
                return this.loadTimezone(newZip)
            }
        },
        agent (agent) {
            if (agent?.id && this.canAgent) {
                this.$set(this.form, 'force_agent', agent.id)
            }
        },
        'method' () {
            return this.loadDates()
        },
        'notes' (notes) {
            this.$set(this.form.appointment, 'notes', notes)
        },
        'aep' (aep) {
            this.$set(this.form.appointment, 'aep', aep)
        },
        'form.keep_agent' () {
            return this.loadDates()
        },
        'form.force_agent' () {
            return this.loadDates()
        },
        'form.appointment.aep' () {
            return this.loadDates()
        },
        'form.appointment.selected_date' (newDate) {
            if (newDate) {
                return this.loadTimes()
            } else {
                this.resetTimes()
            }
        },
        'form.appointment.selected_time' () {
            this.setAppointmentDatetime()
        },
        'form.appointment.timezone' () {
            return this.loadDates()
        },
    },
    mounted () {
        this.setGlobal('$book', this)
        // this.$set(this.form.appointment, 'notes', this.notes)
        return Promise.all([this.loadTimezone(this.zip), this.loadAgents()])
    },
    methods: {
        dateDisabled (ymd) {
            return !this.datesMap[ymd]
        },
        dateDisabledClass (ymd) {
            return this.dateDisabled(ymd) ? 'date--disabled disabled' : 'date--enabled'
        },
        onCalendarContext: _.debounce(async function (context) {
            const month = moment(context.activeDate).format('YYYY-MM')
            if (month !== this.form.appointment.month) {
                this.$set(this.form.appointment, 'month', month)
                await this.loadDates()
            }
        }, 750),
        setAppointmentDatetime () {
            const selectedTime = this.form.appointment.selected_time
            const timezone = this.form.appointment.timezone

            if (selectedTime && timezone) {
                const datetime = moment.tz(selectedTime, timezone)
                this.$set(this.form.appointment, 'time', selectedTime)
                this.$set(this.form.appointment, 'datetime', datetime.format('MM/DD/YYYY hh:mma z'))
            }
        },
        async loadAgents (yell) {
            if (this.hasMinimumFields || !this.canAgent) {
                const { agents } = await this.$api.getAgentsForFips({ fips: this.fips })
                this.agents = agents
            } else if (this.client0 && this.canAgent && yell === true) {
                // await this.validate()
                if (this.header && this.header.presentQuickEditModal) {
                    this.header.presentQuickEditModal()
                }
            }
        },
        loadDates: _.debounce(async function () {
            this.resetDates()
            if (!this.filteredAgents?.length) {
                return this.loadAgents()
            }
            if (this.useEmailOrSurvey) {
                return
            }
            // if (!(await this.validate())) {
            //     return
            // }
            this.timesLoading = null
            try {
                this.datesLoading = true
                /* eslint-disable camelcase */
                const { dates, minimum_date } = await this.$api.getSchedulingAvailableDates({
                    method: this.method,
                    timezone: this.form.appointment.timezone,
                    aep: this.form.appointment.aep,
                    agents: this.filteredAgents.map(agent => agent.id),
                    month: this.form.appointment.month,
                })
                this.dates = dates
                this.minimumDate = minimum_date
            } catch (e) {
                this.$alert(e)
            } finally {
                this.datesLoading = false
            }
        }, 100),
        loadTimes: _.debounce(async function () {
            // if (!(await this.validate())) {
            //     return
            // }
            this.timesLoading = true
            this.resetTimes()
            if (!this.form.appointment.selected_date || this.useEmailOrSurvey) {
                return
            }
            try {
                const { times, minutes } = await this.$api.getSchedulingAvailableTimes({
                    method: this.method,
                    timezone: this.form.appointment.timezone,
                    aep: this.form.appointment.aep,
                    agents: this.filteredAgents.map(agent => agent.id),
                    date: this.form.appointment.selected_date
                })
                this.times = [...times].sort((a, b) => a.time.localeCompare(b.time))
                this.minutes = minutes
            } catch (e) {
                this.$alert(e)
            } finally {
                this.timesLoading = false
            }
        }, 100),
        async bookAppointment () {
            this.schedulingLoading = true
            /* eslint-disable camelcase */
            // if (!(await this.validate())) {
            //     return
            // }
            const bookBody = {
                timezone: this.form.appointment.timezone,
                aep: this.form.appointment.aep,
                method: this.method,
                clients: this.clients,
                caretakers: this.caretakers,
                ...(this.useEmailOrSurvey
                    ? {
                        agent: this.agent?.id || this.eligibleAgents[_.random(0, this.eligibleAgents.length - 1, false)]?.id,
                    }
                    : {
                        agent: this.form.appointment.selected_agent,
                        time: this.form.appointment.time,
                        notes: this.form.appointment.notes,
                        skip_soa: this.form.appointment.skip_soa,
                        skip_survey: this.form.appointment.skip_survey,
                    }
                )
            }

            const message = `bookingAppointment(fn=${this.client0.first_name},ln=${this.client0.last_name})`
            this.sentryCaptureMessage(message,
                {
                    contexts: {
                        bookBody,
                    },
                    tags: {
                        survey: message
                    },
                    screenshot: true
                }
            )

            const { error, client_ids, redirect_survey, state } = await this.$try('bookSchedulingTime', bookBody)

            if (!error) {
                this.$gtag.event('booked_appointment', {
                    event_category: 'scheduler',
                    event_label: this.toName(this.clients[0]),
                    value: client_ids.join('+')
                })
                if (this.useEmail) {
                    this.$alert({ message: 'Request sent! Someone will reach out to you shortly', variant: 'success' })
                } else if (this.useSurvey) {
                    this.$alert({ message: 'Request sent! You should a receive an email from us shortly', variant: 'success' })
                } else {
                    this.$alert({ message: 'Appointment Booked', variant: 'success' })
                }
                if (state && redirect_survey) {
                    const { user, group, host_url, effective_host_url } = state
                    this.$store.commit('user', user)
                    this.$store.commit('group', group)
                    this.$store.commit('hostUrl', host_url)
                    this.$store.commit('effectiveHostUrl', effective_host_url || host_url)
                }
                const id = client_ids && client_ids[0]
                this.$router.push(id ? this.canAgent ? `/survey/${id}` : redirect_survey ? '/survey' : '/thank-you' : '/thank-you')
            }
            this.schedulingLoading = false
        },
        resetDates () {
            this.$set(this.form.appointment, 'selected_date', null)
            this.dates = []
            this.resetTimes()
        },
        resetTimes () {
            this.$set(this.form.appointment, 'selected_time', null)
            this.$set(this.form.appointment, 'selected_agent', null)
            this.$set(this.form.appointment, 'time', null)
            this.$set(this.form.appointment, 'datetime', null)
            this.minutes = null
            this.times = []
        },
        async loadTimezone (zip) {
            if (!zip) {
                return
            }
            const response = await this.$try('getTimezoneForZip', zip, { spinner: false })
            if (response && response.timezone_code && this.timezonesMap[response.timezone_code]) {
                this.$set(this.form.appointment, 'timezone', response.timezone_code)
            }
        },
    }

}
