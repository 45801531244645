
import { mapState } from 'vuex'

export default {
    props: {
        value: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            form: {
                email: this.value
            }
        }
    },
    computed: {
        ...mapState({
            scheduleHelpText: state => state.scheduleHelpText
        })
    },
    watch: {
        value (val) {
            this.form.email = val
        }
    },
    mounted () {
        this.form.email = this.value
    },
    methods: {
        onConfirm () {
            this.$emit('input', this.form.email)
        }
    }
}
