import Vue from 'vue'
import commonMixin from '~/mixins/common'
import rootMixin from '~/mixins/root'

Vue.mixin(commonMixin)

export default (ctx, inject) => {
    // root only
    ctx.app.mixins = [commonMixin, rootMixin]
}
