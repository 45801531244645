
export default {
    props: {
        plans: {
            type: Array,
            default: () => [],
        },
        compact: {
            type: Boolean,
            default: false,
        },
        showPrint: {
            type: Boolean,
            default: false,
        },
        showTour: {
            type: Boolean,
            default: true,
        },
        showIndex: {
            type: Boolean,
            default: false,
        },
        showCompare: {
            type: Boolean,
            default: true,
        },
        showSelect: {
            type: Boolean,
            default: true,
        },
        showBack: {
            type: Boolean,
            default: true,
        },
        inModal: {
            type: Boolean,
            default: false,
        },
        collapseCategories: {
            type: Boolean,
            default: false,
        },
        accordionCategories: {
            type: Boolean,
            default: false,
        },
        collapsibleVisible: {
            type: Boolean,
            default: true,
        },
    },
    data () {
        return {
            childWindow: null,
            showEqual: true,
            showNotEqual: true,
            highlightNotEqual: false,
        }
    },

    methods: {
        openPrintWindow () {
            window.removeEventListener('message', this.onMessage)
            window.addEventListener('message', this.onMessage)
            this.childWindow = window.open('/print-window')
            if (this.childWindow.focus) {
                this.childWindow.focus()
            }
        },
        onMessage (event) {
            if (event?.origin === window?.origin && event.data === 'mounted') {
                this.childWindow.postMessage(this.$stringify({
                    survey: this.$store.state.survey,
                    summary: this.$store.state.summary,
                    plans: this.plans
                }))
            }
        }
    },
}
