
import _ from 'lodash'
import { mapGetters } from 'vuex'
import surveyForm from '~/mixins/survey-form'

export default {
    mixins: [surveyForm],
    data () {
        return {
            sortBy: this.$route.query.sortBy || 'highest__score',
            filtersVisible: this.$store.getters.isSize('gte-lg'),
            filters: {
                query: '',
                my_plans: this.$route.query.my_plans === 'true',
                supported: this.$route.query.supported !== 'false',
                type: parseInt(this.$route.query.type) || 0,
                benefits: [].concat(this.$route.query.benefits || []),
                carriers: [].concat(this.$route.query.carriers || []),
                mapdTypes: [].concat(this.$route.query.mapdTypes || []),
                suppTypes: [].concat(this.$route.query.suppTypes || []),
            },
            types: [
                { value: 0, text: 'Supplemental (SUPP)', shortcode: 'supp' },
                { value: 1, text: 'Drug (PDP)', shortcode: 'pdp' },
                { value: 2, text: 'Advantage (MA(PD))', shortcode: 'mapd' },
                { value: 3, text: 'Employer/Group (GP)', shortcode: 'gp' },
            ],
            sortByOptions: [
                { value: 'highest__score', text: 'Highest Score' },
                { value: 'lowest__estimated_monthy_cost', text: 'Lowest Estimated Cost Per Month' },
                { value: 'lowest__estimated_yearly_cost', text: 'Lowest Estimated Cost Per Year' },
                { value: 'lowest__effective_date_estimated_yearly_cost', text: 'Lowest Estimated Cost This Year' },
                { value: 'lowest__monthly_premium', text: 'Lowest Premium' },
                { value: 'highest__stars_rating.OVERALL', text: 'Highest Rating' },
                // { value: 'lowest__copays_and_monthly', text: 'Lowest Copays+Premium Cost' },
                // { value: 'highest__copays_and_monthly', text: 'Highest Copays+Premium Cost' },
                { value: 'lowest__score', text: 'Lowest Score' },
                { value: 'highest__estimated_monthy_cost', text: 'Highest Estimated Cost Per Month' },
                { value: 'highest__estimated_yearly_cost', text: 'Highest Estimated Cost Per Year' },
                { value: 'highest__effective_date_estimated_yearly_cost', text: 'Lowest Estimated Cost This Year' },
                { value: 'highest__monthly_premium', text: 'Highest Premium' },
                { value: 'lowest__stars_rating.OVERALL', text: 'Lowest Rating' },
            ],
            benefits: [
                { value: 'has_any_vision', text: 'Has vision' },
                { value: 'has_any_hearing', text: 'Has hearing' },
                { value: 'has_any_fitness', text: 'Has fitness' },
                { value: 'has_any_basic_dental', text: 'Has basic dental' },
                // { value: 'has_any_out_of_network_coverage', text: 'Has out of network coverage' },
                { value: 'has_any_comprehensive_dental', text: 'Has comprehensive dental' },
                { value: 'referral_not_required', text: 'No referrals required' },
            ],
        }
    },
    async fetch () {
        await this.saveChanges()
        await this.$store.dispatch('getSurvey', { id: this.uid })
        const [qres] = await Promise.all([
            this.$store.dispatch('getQuotes', {
                id: this.uid,
                detailed: this.$route.query.detailed === 'true',
                version: parseInt(this.$route.query.version) || 2
            }),
            this.$store.dispatch('getSummary', { id: this.yearSummaryId }),
            this.$store.dispatch('getOpportunities', this.uid),
            this.$store.getters.canAgent && this.$store.getters.inGroup ? this.$store.dispatch('loadAllClientUserGroupPlans') : true
        ])
        if (qres?.error?.response?.status === 422 && this.header && this.header.presentQuickEditModal) {
            this.header.presentQuickEditModal()
        }
    },
    computed: {
        ...mapGetters(['year', 'groupId', 'plans', 'userGroupPlans', 'sources', 'client', 'survey', 'isBusy', 'yearSummaryId', 'isPlanInSummary']),
        supps () {
            return this.plans.filter(plan => plan.plan_type === 'supp')
        },
        suppsFiltered () {
            return this.supps.filter(this.plansFilter)
        },
        suppsFilteredSorted () {
            const plans = this.suppsFiltered
            plans.sort(this.plansSort)
            return plans
        },
        // todo: needs to split ma and mapd
        mapds () {
            return this.plans.filter(plan => plan.plan_type === 'mapd' || plan.plan_type === 'ma')
        },
        mapdsFiltered () {
            return this.mapds.filter(this.plansFilter)
        },
        mapdsFilteredSorted  () {
            const plans = this.mapdsFiltered
            plans.sort(this.plansSort)
            return plans
        },
        pdps () {
            return this.plans.filter(plan => plan.plan_type === 'pdp')
        },
        pdpsFiltered () {
            return this.pdps.filter(this.plansFilter)
        },
        pdpsFilteredSorted () {
            const plans = this.pdpsFiltered
            plans.sort(this.plansSort)
            return plans
        },
        gps () {
            return this.userGroupPlans.filter(plan => plan.plan_type === 'gp')
        },
        gpsFiltered () {
            return this.gps.filter(this.plansFilter)
        },
        gpsFilteredSorted () {
            const plans = this.gpsFiltered
            plans.sort(this.plansSort)
            return plans
        },
        plansFilteredSorted () {
            const plans = this[`${this.types[this.filters.type]?.shortcode}sFiltered`] || []
            plans.sort(this.plansSort)
            return plans
        },
        sourcesFailed () {
            return Object.keys(this.sources).filter(source => this.sources[source] === false || this.sources[source].success === false)
        },
        sourcesErrors () {
            return Object.keys(this.sources).filter(source => this.sources[source] === false || this.sources[source].success === false).map(source => ({ source, error: this.sources[source].error_message }))
        },
        carriers () {
            const carriers = _.uniqBy(this.plans.map(plan => ({ value: plan.carrier, text: plan.carrier })), c => c.value)
            carriers.sort((c1, c2) => (c1.text > c2.text ? 1 : -1))
            return carriers
        },
        mapdTypes () {
            const mapdTypes = this.mapds.filter(p => p.mapd_type).map(plan => ({ value: plan.mapd_type, text: plan.mapd_type }))
            return _.uniqBy(mapdTypes, pot => pot.value)
        },
        suppTypes () {
            const suppTypes = this.supps.map(plan => ({ value: plan.supp_type, text: plan.supp_type }))
            return _.uniqBy(suppTypes, pot => pot.value)
        },
        plansFilter () {
            const query = this.filters.query
                ? (plan) => {
                    const terms = this.filters.query.split(' ')
                    for (const term of terms) {
                        const termRe = new RegExp(term, 'i')
                        const termMatched = termRe.test(plan.name) || termRe.test(plan.carrier) || (plan.organizations && plan.organizations.some(org => termRe.test(org)))
                        if (!termMatched) return false
                    }
                    return true
                }
                : () => true

            const myPlans = this.filters.my_plans === true ? plan => this.isPlanInSummary(plan) : () => true
            const supported = this.filters.supported === true ? plan => plan.plan_type === 'gp' || plan.supported : () => true
            const benefits = this.filters.benefits && this.filters.benefits.length ? plan => this.filters.benefits.every(b => plan[b]) : () => true
            const carriers = this.filters.carriers && this.filters.carriers.length
                ? plan => this.filters.carriers.some((c) => {
                    const re = new RegExp(c, 'i')
                    return c === plan.carrier || re.test(plan.carrier) || re.test(plan.parent_org) || (plan.organizations && plan.organizations.some(org => re.test(org)))
                })
                : () => true
            const mapdTypes = this.filters.mapdTypes && this.filters.mapdTypes.length ? plan => this.filters.mapdTypes.some(pot => (plan.type === 'mapd' || plan.type === 'ma') ? plan.mapd_type === pot : true) : () => true
            const suppTypes = this.filters.suppTypes && this.filters.suppTypes.length ? plan => this.filters.suppTypes.some(c => plan.type === 'supp' ? (_.toLower(plan.supp_type) === _.toLower(c)) : true) : () => true
            return plan => query(plan) && myPlans(plan) && supported(plan) && benefits(plan) && carriers(plan) && mapdTypes(plan) && suppTypes(plan)
        },
        plansSort () {
            const parts = this.sortBy.split('__')
            const orderA = parts[0] === 'highest' ? -1 : 1
            const orderB = orderA === 1 ? -1 : 1
            const property = parts[1]
            return (pA, pB) => (_.get(pA, property) >= _.get(pB, property) ? orderA : orderB)
        },
        quotesVersion: {
            get () {
                return this.$store.getters.quotesVersion
            },
            set (v) {
                this.$store.commit('quotesVersion', v)
            }
        },
    },
    watch: {
        year () {
            return this.$fetch()
        },
        sortBy (sortBy) {
            this.$routerx.replaceQuery({ sortBy })
        },
        filters: {
            deep: true,
            handler (filters) {
                this.$nextTick(() => {
                    this.$routerx.replaceQuery(filters)
                })
            },
        },
        quotesVersion: {
            handler (newVersion, oldVersion) {
                if (newVersion === oldVersion) {
                    return
                }
                setTimeout(() => {
                    this.$routerx.replaceQuery({ version: newVersion })
                }, 100)
                return this.getQuotesByVersion(newVersion)
            }
        }
    },
    methods: {
        getFreshQuotes () {
            this.$store.dispatch('getQuotes', {
                id: this.uid,
                fresh: true,
                spinner: true,
                detailed: this.$route.query.detailed === 'true',
                version: this.quotesVersion || 2
            })
        },
        getQuotesByVersion (version) {
            this.$store.dispatch('getQuotes', {
                id: this.uid,
                spinner: true,
                detailed: this.$route.query.detailed === 'true',
                version: version || 2
            })
            // reset filters since suppTypes and carriers are different between v1 and v2
            this.filters.suppTypes = []
            this.filters.mapdTypes = []
            this.filters.benefits = []
            this.filters.carriers = []
            this.filters.query = ''
            this.filters.supported = true
            this.filters.my_plans = false
            this.filters.type = 0
        },
    }
}
