import _ from 'lodash'
import CommonMixin from '~/mixins/common'

export default {
    data () {
        return {
            deferredPrompt: null,
        }
    },
    created () {
        if (typeof window === 'undefined') {
            global.$root = this
        } else {
            window.$root = this
        }
    },
    mounted () {
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault()
            this.deferredPrompt = e
            console.log('root beforeinstallprompt', e)
        })
        window.addEventListener('appinstalled', () => {
            console.log('root appinstalled')
            this.deferredPrompt = null
        })
        this.$root.$on('bv::modal::hide', () => {
            this.$root.$emit('bv::hide::tooltip')
        })
        this.$root.$on('bv::modal::show', () => {
            this.$root.$emit('bv::hide::tooltip')
        })
        const message = this.$route.query.message
        const variant = this.$route.query.type
        if (message) {
            this.$alert({ message, variant })
            this.$routerx.deleteQuery({ message, type: variant })
        }
    },
    methods: {
        back () {
            return this.$router.back()
        },
        dismissInstallApp () {
            this.deferredPrompt = null
        },
        promptInstallApp () {
            return this.deferredPrompt.prompt()
        },
        vueTemplateToHtml (str, data = {}) {
            const template = `<div>${str}</div>`
            data = {
                ...this.$store.getters.variablesData,
                ...data,
            }
            const computed = {
                ...this.$store.getters.variablesComputed,
                $store: () => this.$store,
                $_: _,
            }
            const methods = {
                ...CommonMixin.methods,
            }
            if (str) {
                const compiled = this.$vueFull.compile(template)
                const vm = new this.$vueFull({
                    data,
                    computed,
                    methods,
                    render: compiled.render,
                    staticRenderFns: compiled.staticRenderFns,
                })
                vm.$mount()
                const outerHTML = vm.$el.outerHTML
                if (!outerHTML) {
                    return '<code>Something went wrong while displaying this template</code>'
                }
                return outerHTML
            }
        },
        async vueTemplateToHtmlAsync (str, data = {}) {
            const template = `<div>${str}</div>`
            data = {
                ...this.$store.getters.variablesData,
                ...data,
            }
            const computed = {
                ...this.$store.getters.variablesComputed,
                $store: () => this.$store
            }
            const methods = {
                ...CommonMixin.methods,
            }
            if (str) {
                const vm = new this.$vue({
                    template,
                    data,
                    computed,
                    methods
                })
                try {
                    const context = {}
                    return await this.$vueRender.renderToString(vm, context)
                } catch (e) {
                    console.error(e)
                    return '<code>Something went wrong while displaying this template</code>'
                }
            }
        }
    }
}
