
import { mapGetters } from 'vuex'

export default {
    props: {
        endpath: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            loadingFrame: true,
            html: null,
            tabs: null,
        }
    },
    computed: {
        ...mapGetters(['activeRoutePath', 'activeScriptTab']),
        activeEndpath () {
            return this.endpath || (this.activeRoutePath || '').split('/').pop()
        }
    },
    watch: {
        '$route.path' () {
            this.fetchScript()
        },
        html () {
            if (!this.html) {
                this.fetchScript()
            }
        }
    },
    mounted () {
        this.setGlobal('$consultationScripts', this)
        this.fetchScript()
    },
    methods: {
        async fetchScript () {
            const res = await this.$try('getItem', `consultation-scripts-${this.activeEndpath.length ? this.activeEndpath : 'profile'}`)
            this.html = res?.value?.html
            if (!this.html) this.html = `<p>Page: ${this.activeEndpath} does not have a corresponding script, please contact our tech team if you believe this is a mistake.</p>`
            this.loadingFrame = false

            if (this.activeEndpath === 'demographics') {
                this.tabs = [
                    {
                        title: 'General',
                        value: res.value?.html,
                    },
                    {
                        title: 'VA',
                        value: res.value?.va,
                    },
                    {
                        title: 'FEP Blue',
                        value: res.value?.fep_blue,
                    },
                    {
                        title: 'TRICARE',
                        value: res.value?.tricarechampva,
                    }
                ]
            } else {
                this.tabs = null
            }
        },
    }
}
