
/* eslint-disable camelcase */
import { mapGetters } from 'vuex'
import _ from 'lodash'
import { address } from '~/util/string'

export default {
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        syncQuery: {
            type: Boolean,
            default: false,
        },
        zipCode: {
            type: String,
            default: null,
        },
        isSurvey: {
            type: Boolean,
            default: false
        },
        showSelectedOnly: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            adding: false,
            results: [],
            searching: null,
            searchForm: {
                query: this.$route?.query?.query,
                zip_code: this.zipCode || this.$route?.query?.zip_code,
                distance: parseInt(this.$route?.query?.distance || 25),
                page: parseInt(this.$route?.query?.page) || 0,
                page_size: parseInt(this.$route?.query?.page_size) || 10,
            },
        }
    },
    computed: {
        ...mapGetters(['distances']),
        markers () {
            return this.results.map((result) => {
                result.position = { lat: result.location.latitude, lng: result.location.longitude }
                result.key = `provider-${result.npi}-${result.lapro_id}`
                result.icon = 'user-md'
                result.title = `${result.name}`
                result.subtitle = `${address(result.location)}`
                result.selected = this.isProviderInList(result)
                result.description = result.selected ? 'Click to remove from list' : 'Click to add to list'
                result.variant = result.selected ? 'primary' : 'secondary'
                return result
            })
        },
        valid () {
            return this.searchForm.query?.length >= 3 && this.searchForm.zip_code?.length === 5 && this.searchForm.distance > 0
        },
        providerImgPath () {
            return require('~/assets/images/external-icons/Doctor.png')
        }
    },
    watch: {
        zipCode (newZipCode) {
            this.$set(this.searchForm, 'zip_code', newZipCode)
        },
        searchForm: {
            deep: true,
            handler: _.debounce(function (searchForm) {
                this.$nextTick(() => {
                    this.syncQuery && this.$routerx.replaceQuery(searchForm)
                })
            }, 300)
        },
    },
    mounted () {
        if (this.valid) {
            return this.search()
        }
    },
    methods: {
        setZipCodeIfUnset (zipcode) {
            if (!this.searchForm.zip_code) {
                this.$set(this.searchForm, 'zip_code', zipcode)
                if (this.valid) this.search()
            }
        },
        async search ({ page = 0, page_size = 10 } = {}) {
            if (this.searchForm.query && this.searchForm.zip_code && this.searchForm.distance) {
                this.$set(this.searchForm, 'page', page)
                this.$set(this.searchForm, 'page_size', page_size)
                this.searching = true
                let { results, error } = await this.$try('searchProviders', this.searchForm, { spinner: false })
                if (!error) {
                    results = results.map((provider) => {
                        provider.locations = (provider.locations || []).map((location) => {
                            return {
                                text: address(location),
                                value: location
                            }
                        })
                        provider.location = _.get(provider, 'locations.0.value')
                        return provider
                    })
                    this.results = page === 0 ? results : [].concat(this.results).concat(results || [])
                }
                this.searching = false
            } else {
                this.$warning('Please enter at least 3 characters of a provider name, a zip code and select a radius')
            }
        },
        addProvider (provider = {}) {
            if (!provider.name) {
                return this.$alert('Provider\'s name is required')
            }
            const providers = this.value.slice(0)
            providers.push(provider)
            this.$emit('input', providers)
        },
        updateProvider (provider = {}) {
            const index = this.findProviderIndex(provider)
            if (index > -1) {
                const providers = this.value.slice(0)
                providers[index] = provider
                this.$emit('input', providers)
            }
        },
        findProviderIndex (provider) {
            return _.findIndex(this.value, (p) => {
                if (provider.npi) {
                    return p.npi === provider.npi
                }
                return p.name === provider.name
            })
        },
        isProviderInList (provider) {
            return this.findProviderIndex(provider) > -1
        },
        removeProvider (provider) {
            const index = this.findProviderIndex(provider)
            if (index > -1) {
                const providers = this.value.slice(0)
                providers.splice(index, 1)
                this.$emit('input', providers)
            }
        },
        toggleProvider (provider) {
            if (this.isProviderInList(provider)) {
                return this.removeProvider(provider)
            } else {
                return this.addProvider(provider)
            }
        }
    },
}
