
import moment from 'moment'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { stringToColor } from '~/util/string'

const months = moment.months()

export default {
    props: {
        pharmacy: {
            type: Object,
            default: null
        },
        barsClass: {
            type: String,
            default: null
        },
        collapsibleVisible: {
            type: Boolean,
            default: false
        },
        compact: {
            type: Boolean,
            default: false
        },
        yMax: {
            type: Number,
            default: 500
        }
    },
    computed: {
        ...mapGetters(['findDrugAlternatives', 'isDonutHoleYear']),
        drugTotals () {
            return this.pharmacy.drug_totals
        },
        phase () {
            return this.pharmacy.phase_information
        },
    },
    methods: {
        drugsMonthlyBarData (arr) {
            const costs = _.keyBy(arr, c => parseInt(c.date.split('-')[1]) - 1)

            return {
                datasets: [
                    {
                        label: 'Deductible',
                        backgroundColor: stringToColor('AAAA'),
                        data: months.map((a, i) => costs[i]?.deductible),
                    },
                    {
                        label: 'Initial',
                        backgroundColor: stringToColor('BBBB'),
                        data: months.map((a, i) => costs[i]?.initial),
                    },
                    this.isDonutHoleYear
                        ? {
                            label: 'Gap',
                            backgroundColor: stringToColor('CCCC'),
                            data: months.map((a, i) => costs[i]?.gap),
                        }
                        : null,
                    {
                        label: 'Catastrophic',
                        backgroundColor: stringToColor('DDDD'),
                        data: months.map((a, i) => costs[i]?.catastrophic),
                    },
                ].filter(v => v),
                labels: months,
            }
        },
        drugsMonthlyBarOptions () {
            return {
                responsive: true,
                scales: {
                    xAxes: [{
                        stacked: true,
                    }],
                    yAxes: [{
                        stacked: true,
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            max: this.yMax,
                            callback: (value) => {
                                return this.money(value, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
                            },
                        },
                    }],
                },

                title: {
                    display: true,
                    text: 'Estimated Monthly Cost',
                },
                tooltips: {
                    display: true,
                    callbacks: {
                        label: (tooltipItem, data) => {
                            return `${data.datasets[tooltipItem.datasetIndex].label}: ${this.money(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index])}`
                        },
                    },
                },
                legend: {
                    display: true,
                },
            }
        },
        drugsMonthlyAverage (arr) {
            return _.sumBy(arr, 'initial_cost') / arr.length
        },
    },
}
