
import _ from 'lodash'

export default {
    props: {
        value: {
            type: [String, Number],
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: '',
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        searchable: {
            type: Boolean,
            default: true,
        },
        internalSearch: {
            type: Boolean,
            default: true,
        },
        showNoOptions: {
            type: Boolean,
            default: false,
        },
        allowEmpty: {
            type: Boolean,
            default: true,
        },
        helpText: {
            type: String,
            default: null,
        },
        url: {
            type: String,
            default: null
        },
        trackBy: {
            type: String,
            default: 'value'
        },
        label: {
            type: String,
            default: 'text'
        },
        choices: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            selectableOptions: this.choices || [],
            rawValue: this.choices.filter(item => item[this.trackBy] === this.value)?.[0]
        }
    },
    async fetch () {
        if (!this.choices?.length) {
            let items = await this.$try('$get', this.url, { spinner: false, alert: false })
            if (!_.isObject(items?.[0])) {
                items = items.map(i => ({ [this.label]: i, [this.trackBy]: i }))
            }
            this.selectableOptions = items || []
        }
    },
    watch: {
        value () {
            if (!_.isEqual(parseInt(this.value), this.rawValue?.[this.trackBy])) {
                this.updateValue()
            }
        },
        rawValue () {
            this.emitInput()
        },
        selectableOptions () {
            this.rawValue = this.selectableOptions.filter(item => item[this.trackBy] === this.value)?.[0]
        }
    },
    methods: {
        updateValue () {
            this.rawValue = this.selectableOptions.filter(item => item[this.trackBy] === this.value)?.[0]
        },
        emitInput () {
            this.$emit('input', this.rawValue?.[this.trackBy] || null)
        },
        search (query) {
            this.query = query
            return this.$fetch()
        },
    },
}
