

import { NodeViewContent, nodeViewProps, NodeViewWrapper } from '@tiptap/vue-2'
import CardMixin from '~/mixins/card'
import CommonMixin from '~/mixins/common'

export default {
    components: {
        NodeViewWrapper,
        NodeViewContent,
        Editor: () => import('~/components/Editor.vue')
    },
    mixins: [CardMixin, CommonMixin],
    props: nodeViewProps,
    data () {
        return {
            subEditor: null,
            val: null
        }
    },
    computed: {
        tag () {
            return this.node.attrs.tag
        },
        statement () {
            if (this.tag === '1') return 'IF'
            if (this.tag === '2') return 'ELSE IF'
            return 'ELSE'
        },
        isFocused () {
            return this?.subEditor?.isFocused
        },
        isEditable () {
            return !this.val?.length ? true : this?.subEditor?.isEditable
        }
    },
    watch: {
        isFocused () {
            this.editor.setEditable(!this.isFocused)
        },
        isEditable () {
            return this?.editor?.setEditable(this.isEditable)
        }
    },
    mounted () {
        this.$nextTick(() => {
            if (!this.val?.length) {
                const val = this.$refs?.content?.$el?.innerHTML
                const regex = /<p>\s*(<br>+|<br\s*class="ProseMirror-trailingBreak">)+\s*<\/p>/g

                if (val?.length) {
                    this.val = val.replace(regex, '')
                    this.updateAttributes({ content: this.val })
                }
            }
        })
    },
    methods: {
        handleChange (e) {
            if (this.$refs.content) {
                const regex = /<p>\s*(<strong>(IFELSE-IFELSE+|.*(IF|ELSEIF|ELSE)+.*)<\/strong>)+\s*<\/p>/
                this.val = e.replace(regex, '')
                this.updateAttributes({ content: e })
            }
        },
        lineAfterBlock () {
            this.editor.commands.command(({ tr, state }) => {
                const { selection } = state
                const { empty, anchor } = selection

                if (!empty) {
                    return false
                }

                state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
                    if (node.type.name === 'ConditionalBlock') {
                        tr.insertText(' ', pos + node.nodeSize)
                    }
                })
            })
        }
    }
}
