
import _ from 'lodash'
import { mapGetters } from 'vuex'

import card from '~/mixins/card'
export default {
    mixins: [card],
    props: {
        magicUrl: {
            type: String,
            default: '/'
        },
    },
    computed: {
        ...mapGetters([
            'uid',
            'surveyRoutesMap',
            'isPlanInOpportunities',
        ]),
        // todo: needs to split ma and mapd
        mapdPlan () {
            return _.find(this.summaryCombosPlans, p => p.mapd_or_ma)
        },
        suppPlan () {
            return _.find(this.summaryCombosPlans, p => p.supp)
        },
        pdpPlan () {
            return _.find(this.summaryCombosPlans, p => p.pdp)
        },
    },
}
