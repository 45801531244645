
import _ from 'lodash'

export default {
    props: {
        value: {
            type: [Object, String],
            default: null
        },
        id: {
            type: [String, Number],
            default: null
        },
        label: {
            type: [String, Boolean],
            default: false
        },
        readonly: {
            type: [String, Boolean],
            default: null
        },
        disabled: {
            type: [String, Boolean],
            default: null
        },
        placeholder: {
            type: [String],
            default: null
        },
        inputClass: {
            type: [String],
            default: null
        },
        groupClass: {
            type: [String],
            default: null
        },
        autocomplete: {
            type: [String, Boolean],
            default: null
        },
        tabindex: {
            type: [String, Number],
            default: null
        },
        showOneCounty: {
            type: Boolean,
            default: false
        },
        state: {
            type: Boolean,
            default: null,
        },
        required: {
            type: Boolean,
            default: true,
        },
    },
    data () {
        return {
            location: {},
            loading: false,
            counties: [],
        }
    },
    computed: {
        zipEntered () {
            const z = _.get(this.location, 'zip_code')
            return z && z.length === 5
        },
    },
    watch: {
        value (v1, v2) {
            if (!_.isEqual(v1, v2)) {
                this.updateValue()
            }
        },
    },
    mounted () {
        this.updateValue()
        this.$nextTick(async () => {
            await this.loadCounties()
            this.$emit('mounted')
            // delayed watching the zip to allow first automatic selected set by the template
            this.$watch('location.zip_code', () => this.loadCounties())
            this.$watch('location.fips', () => this.emitInput())
        })
    },

    methods: {
        updateValue () {
            this.location = _.cloneDeep(this.value || {})
        },
        async loadCounties () {
            if (!this.zipEntered) {
                return null
            }
            this.$unalert()
            this.loading = true
            try {
                const resp = await this.$api.getCountiesForZip(_.get(this.location, 'zip_code'))
                if (resp) {
                    this.counties = resp.counties
                }
            } catch (e) {
                this.counties = []
                this.$alert(e.message)
            } finally {
                this.loading = false
            }
            // only auto-set the location.fips if it doesn't have one, or whatever it has is not in the last of available fips
            if (this.counties?.length && (!this.location?.fips || !_.find(this.counties, c => c.fips === this.location?.fips))) {
                this.$set(this.location, 'fips', _.get(this.counties, '0.fips'))
                this.$set(this.location, 'state', _.get(this.counties, '0.state'))
                this.$set(this.location, 'name', _.get(this.counties, '0.name'))
            }
            this.emitInput()
        },
        emitInput () {
            if (this.location) {
                const county = _.find(this.counties, c => c.fips === this.location.fips)
                if (county) {
                    this.$set(this.location, 'fips', county.fips)
                    this.$set(this.location, 'state', county.state)
                    this.$set(this.location, 'name', county.name)
                }
            }
            this.$emit('input', _.cloneDeep(this.location))
        },
    },
}
