
import _ from 'lodash'
import { mapGetters } from 'vuex'

const DEFAULT_FORM = {
    name: null,
    dosage: null,
    custom_dosage: null,
    package: null,
    custom_package: null,
    quantity: null,
    frequency: null,
    is_generic: null,
}
export default {
    props: {
        value: {
            type: Object,
            default: null
        },
        cancelLabel: {
            type: [String, Boolean],
            default: 'Cancel'
        },
        okLabel: {
            type: [String, Boolean],
            default: 'Done'
        },
        warning: {
            type: String,
            default: null
        },
    },
    data () {
        return {
            loading: false,
            watchers: [],
            dosages: [],
            form: {
                ...DEFAULT_FORM
            }
        }
    },
    fetch () {
        return this.onValue()
    },
    computed: {
        ...mapGetters(['year', 'frequencies', 'parseDosage', 'parsePackage']),
        packages () {
            return this.dosage?.packages || []
        },
        dosage () {
            return _.find(this.dosages, dsg => dsg.rxcui === this.form?.dosage?.rxcui)
        },
    },
    mounted () {},
    methods: {
        async ok () {
            const valid = await this.$validator.validate()
            if (valid) {
                this.$emit('input', this.form)
            }
        },
        cancel () {
            this.$emit('cancel', this.value)
        },
        async onValue () {
            this.watchers.forEach(unwatch => unwatch && unwatch())
            if (this.value) {
                this.form = _.cloneDeep(this.value)

                if (!this.form?.dosages?.length) {
                    if (!this.form.rxcui) {
                        this.dosages = []
                    } else {
                        this.loading = true
                        console.log('getDrugOptions for', { year: this.year, ...this.form })
                        const { error, dosages } = await this.$try('getDrugOptions', { year: this.year, rxcui: this.form.rxcui }, { spinner: false })
                        this.loading = false
                        if (!error) {
                            this.dosages = dosages.map(dsg => this.parseDosage(dsg))
                        }
                    }
                } else {
                    this.dosages = this.form.dosages
                }
                this.setDefaults()
                this.watchers.push(this.$watch('value', () => {
                    if (!_.isEqual(this.value, this.form)) {
                        this.onValue()
                    }
                }))
                this.watchers.push(this.$watch('form.dosage', () => {
                    this.setDefaults({ forcePackage0: true })
                }))
            } else {
                this.form = { ...DEFAULT_FORM }
            }
        },
        setDefaults (options = {}) {
            /* eslint-disable */
            if (!this.form.dosage) {
                this.$set(this.form, 'dosage', this.dosages[0])
            } else if (this.dosage) {
                const dsg = this.parseDosage(this.dosage, this.packages)
                this.$set(this.form, 'dosage', dsg)
            }
            if (!this.form.package || options.forcePackage0) {
                this.$set(this.form, 'package', this.packages[0])
            } else if (this.form.package) {
                const pkg = this.parsePackage(this.form.package, this.dosage)
                this.$set(this.form, 'package', pkg)
            }
            if (!this.form.quantity) {
                this.$set(this.form, 'quantity', this.canAgent && 0 ? (_.get(this.form, 'package.default_quantity_30') || 1) : null)
            }
            if (!this.form.frequency) {
                this.$set(this.form, 'frequency', 'FREQUENCY_30_DAYS')
            }
        },
    },
}
