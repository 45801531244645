
import { mapGetters } from 'vuex'

export default {
    data () {
        return {
            loading: true,
            e422: null,
            clients: null,
            phone: null,
            tableFields: [
                { key: 'shortcuts', sortable: false },
                { key: 'name', sortable: false },
                { key: 'cell_phone', sortable: false },
                { key: 'home_phone', sortable: false },
            ],
        }
    },
    async fetch () {
        const { users, phone, error } = await this.$try('getActiveCall', { spinner: false, alert: false })
        if (error?.response?.status === 422) {
            this.e422 = error
            return
        }
        if (error) {
            return this.$alert(error)
        }
        const clients = users
        if (clients) {
            this.clients = clients.map(this.parseClient)
        }
        this.phone = phone
        if (this.clients?.length === 1) {
            this.$emit('close')
            this.$router.push({ path: `/survey/${this.clients[0].id}` })
            this.$success(`You're on the phone with ${this.clients[0].name}, here's their profile`)
        }
    },
    computed: {
        ...mapGetters([
            'crmBase',
        ])
    },
    methods: {
        parseClient (client) {
            client.name = client.name || this.toName(client)
            return client
        }
    }
}
