/* eslint-disable */
import _ from 'lodash'
import * as SurveyCore from 'survey-core'
import { Survey } from 'survey-vue-ui'
// import * as widgets from 'surveyjs-widgets'
import 'survey-core/defaultV2.min.css'
import { bootstrapThemeName, bootstrapThemeColors } from 'survey-core/plugins/bootstrap-integration'
import { titlelize } from '~/util/string'

import vComponentInit from '~/widgets/vcomponent.survey'

const mainColor = '#008CCD'
SurveyCore.StylesManager.applyTheme(bootstrapThemeName)
bootstrapThemeColors['$primary-color'] = mainColor
bootstrapThemeColors['$secondary-color'] = mainColor
bootstrapThemeColors['$primary-hover-color'] = '#2798ce'
bootstrapThemeColors['$primary-text-color'] = mainColor
bootstrapThemeColors['$selection-border-color'] = mainColor

console.log(Survey)

const vifv = function ([name] = []) {
    const q = _.get(this, `survey.questionHashes.names.${name}.0`)
    if (!q) {
        return true
    }
    return q.isVisible
}
const vifnv = function ([name] = []) {
    const q = _.get(this, `survey.questionHashes.names.${name}.0`)
    if (!q) {
        return false
    }
    return !q.isVisible
}
const x = function (arr = []) {
    const expression = arr[0]
    try {
        return function (expression) {
            return eval(expression);
        }.call(this, expression);
    } catch (e) {
        console.warn('eval', expression, e)
    }
}
SurveyCore.FunctionFactory.Instance.register('_', _)
SurveyCore.FunctionFactory.Instance.register('x', x)
SurveyCore.FunctionFactory.Instance.register('titlelize', titlelize)
SurveyCore.FunctionFactory.Instance.register('vifv', vifv)
SurveyCore.FunctionFactory.Instance.register('vifnv', vifnv)
SurveyCore.FunctionFactory.Instance.register('getter', function ([name, keypath, ...args]) {
    if (typeof $root === 'undefined') {
        return null
    }
    let getter = $root.$store.getters[name]
    getter = keypath ? _.get(getter, keypath) : getter
    if (typeof getter === 'function') {
        return getter(...args)
    }
    return getter
})
SurveyCore.FunctionFactory.Instance.register('preferredFirstName', function () {
    return this.survey.valuesHash.preferred_first_name || this.survey.valuesHash.preferred_name || this.survey.valuesHash.first_name
})
SurveyCore.FunctionFactory.Instance.register('hasOtherNewAttendees', function () {
    return (this.survey.valuesHash.attendees || []).filter(function (a) { return !a.caretaker && !a.returning }).length > 0
})
SurveyCore.FunctionFactory.Instance.register('hasAnyReturning', function () {
    return this.survey.returning || (this.survey.valuesHash.attendees || []).filter(function (a) { return !a.caretaker && !a.returning }).length > 0
})
SurveyCore.FunctionFactory.Instance.register('value', function ([name]) {
    return this.survey.valuesHash[name]
})
SurveyCore.FunctionFactory.Instance.register('updateIncomeChoices', function (taxStatus) {
    if (typeof $root === 'undefined') {
        return null
    }
    const survey = this.survey
    const choices = $root.$store.getters.incomeOptionsFiledAs(taxStatus).map(choice => new SurveyCore.ItemValue(choice.value, choice.text))
    const questions = ['current_income', 'projected_income'].map(name => survey.getQuestionByName(name)).filter(q => q)
    questions.forEach((question) => {
        question.choices = choices
    })
})
SurveyCore.JsonObject.metaData.addProperty('page', 'category')
SurveyCore.JsonObject.metaData.addProperty('page', 'subcategory')
SurveyCore.JsonObject.metaData.addProperty('page', 'comment')
SurveyCore.JsonObject.metaData.addProperty('page', 'navname')

SurveyCore.JsonObject.metaData.addProperty('questionbase', { name: 'comment', default: null })
SurveyCore.JsonObject.metaData.addProperty('questionbase', { name: 'component', default: null })
SurveyCore.JsonObject.metaData.addProperty('questionbase', { name: 'propsData', default: {} })
SurveyCore.JsonObject.metaData.addProperty('questionbase', { name: 'addClasses', default: null })
SurveyCore.JsonObject.metaData.addProperty('questionbase', { name: 'skipClasses', default: null })
SurveyCore.JsonObject.metaData.addProperty('questionbase', { name: 'pick', default: '' })
SurveyCore.JsonObject.metaData.addProperty('questionbase', { name: 'tooltip', default: null })
SurveyCore.Serializer.addProperties('page', ['displayName', 'hint'])

vComponentInit(SurveyCore)
// widgets.icheck(SurveyCore)
// widgets.select2(SurveyCore)
// widgets.inputmask(SurveyCore)
// widgets.jquerybarrating(SurveyCore)
// widgets.jqueryuidatepicker(SurveyCore)
// widgets.nouislider(SurveyCore)
// widgets.select2tagbox(SurveyCore)
// widgets.sortablejs(SurveyCore)
// widgets.ckeditor(SurveyCore)
// widgets.autocomplete(SurveyCore)
// widgets.bootstrapslider(SurveyCore)
