
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
    props: {
        client: {
            type: Object,
            default: null
        },
    },
    data () {
        return {
            sendMeACopy: false,
            form: {
                to: this.client?.email || '',
                subject: 'Get ready for your consultation',
                soa_doc_id: null,
                soa_force: false
            },
        }
    },
    async fetch () {
        const soaDocuments = await this.$try('getLatestSoaDocuments', this.$store.getters.uid)
        if (!soaDocuments?.error) {
            this.$store.commit('soaDocuments', soaDocuments)
            if (soaDocuments.length) {
                this.form.soa_doc_id = soaDocuments[0].id
            }
        }
    },
    computed: {
        ...mapGetters([
            'soaDocuments',
            'soaDocumentsMap',
        ]),
        emails () {
            return (this.form.to || '').split(',').map(to => to.trim()).filter(to => !!to)
        },
    },
    watch: {
        sendMeACopy (v) {
            let to = (this.form?.to || '').split(',')
            if (v === true) {
                to.push(this.user.email)
                to = _.uniq(to)
            } else {
                _.remove(to, e => _.toLower(e) === _.toLower(this.user.email))
            }
            this.$set(this.form, 'to', to.join(','))
        },
    },
    methods: {
        async send () {
            const { error } = await this.$try('sendPreConsultSurvey', {
                id: this.client.id,
                data: {
                    ...this.form,
                    to: this.emails
                }
            })
            if (!error) {
                this.$success('Pre-Consult survey sent!')
                this.$emit('success')
            }
        },
        async generateSoaDocumentLink () {
            await this.$try('generateSoaDocumentLink', this.client.id)
            await this.$fetch()
        }
    },
}
