
import _ from 'lodash'

export default {
    props: {
        groupPlan: {
            type: Object,
            default: null
        }
    },
    data () {
        return {
            files: [],
            form: {
                group_id: null,
                active: true,
                name: null,
                carrier: null,
                parent_org: null,
                source_id: null,
                summary_pdf: null,
                // monthly_premium: null,
                // monthly_premium_note: null,
                deductible: null,
                deductible_note: null,
                drug_deductible: null,
                drug_deductible_note: null,
                maximum_oopc_cost_share: null,
                maximum_oopc_cost_share_note: null,
                pcp_copay_cost_share: null,
                pcp_copay_cost_share_note: null,
                pcp_copay_cost_share_percent: false,
                specialist_copay_cost_share: null,
                specialist_copay_cost_share_note: null,
                specialist_copay_cost_share_percent: false,
                urgent_care_copay_cost_share: null,
                urgent_care_copay_cost_share_note: null,
                urgent_care_copay_cost_share_percent: false,
                emergency_copay_cost_share: null,
                emergency_copay_cost_share_note: null,
                emergency_copay_cost_share_percent: false,
                hospitalization_copay_cost_share: null,
                hospitalization_copay_cost_share_note: null,
                hospitalization_copay_cost_share_percent: false,
                outpatient_copay_cost_share: null,
                outpatient_copay_cost_share_note: null,
                outpatient_copay_cost_share_percent: false,
                radiology_copay_cost_share: null,
                radiology_copay_cost_share_note: null,
                radiology_copay_cost_share_percent: false,
                bloodwork_copay_cost_share: null,
                bloodwork_copay_cost_share_note: null,
                bloodwork_copay_cost_share_percent: false,
                xrays_copay_cost_share: null,
                xrays_copay_cost_share_note: null,
                xrays_copay_cost_share_percent: false,
                physical_therapy_copay_cost_share: null,
                physical_therapy_copay_cost_share_note: null,
                physical_therapy_copay_cost_share_percent: false,
                has_any_vision: false,
                has_any_hearing: false,
                has_any_basic_dental: false,
                has_any_comprehensive_dental: false,
                has_any_fitness: false
            }
        }
    },
    computed: {
        editing () {
            return !!this.groupPlan?.id
        },
        copays () {
            return Object.keys(this.form)
                .filter(k => /_copay_cost_share$/.test(k))
                .map((key) => {
                    return { label: this.titlelize(key.replace(/_cost_share*/, '')), prop_cost_share: key }
                })
        },
    },
    async mounted () {
        if (this.editing) {
            await this.loadFiles()
        }
        this.updateForm()
    },
    methods: {
        async loadFiles () {
            this.files = await this.$try('getAllGroupPlanFiles', { group_plan_id: this.groupPlan.id })
        },
        onFileUploaded () {
            return this.loadFiles()
        },
        async deleteFile (file) {
            const id = file?.file_id || file?.id
            if (!id) {
                return
            }
            const { error } = await this.$try('deleteFile', id)
            if (!error) {
                return this.loadFiles()
            }
        },
        updateForm () {
            if (this.groupPlan) {
                this.form = _.cloneDeep(this.groupPlan)
            }
        },
        validate () {
            return this.$validator.validate()
        },
        async save () {
            const valid = await this.validate()
            if (!valid) {
                return this.$store.$alert(this.$validator.errors.all().join('\n'))
            }

            const { error } = await this.$try(this.editing ? 'updateGroupPlan' : 'createGroupPlan',
                {
                    ...this.form,
                    id: this.groupPlan?.id
                },
            )
            if (error) {
                return this.$emit('error', error)
            }
            this.$emit('refresh')
            this.$emit('close')
        }
    }
}
