import { render, staticRenderFns } from "./SummaryCard.vue?vue&type=template&id=5615cae8&lang=pug&"
import script from "./SummaryCard.vue?vue&type=script&lang=js&"
export * from "./SummaryCard.vue?vue&type=script&lang=js&"
import style0 from "./SummaryCard.vue?vue&type=style&index=0&id=5615cae8&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/codebuild/output/src60861037/src/frontend/components/Icon.vue').default})
