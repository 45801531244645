
import { mapGetters, mapState } from 'vuex'
import _ from 'lodash'
import { fromDob } from '~/util/date'

export const getNewClientForm = (test = false) => {
    let form = {
        returning: null,
        salutation: null,
        first_name: null,
        middle_name: null,
        suffix: null,
        last_name: null,
        phone: null,
        sms_opt_in: true,
        email: null,
        dob: null,
        gender: null,
        location: null,
        token: null,
        manual_age: null,
        use_age: false,
        effective_date: null,
        caretaker: null,
        relationship: null,
        source_type: null,
        source_name: null,
        source_opt_out: false,
        source_client_id: null,
        collecting_ssi: false,
        has_account: false,
        hsa: false,
        va: false,
        tricare: false,
        champva: false,
        fep_blue: false,
        notes: '',
        moving: false,
        moving_state: null,
        multiple_states: false,
        group: null,
        group_relationship: null,
    }
    if (test) {
        const now = +new Date()
        form = {
            ...form,
            returning: false,
            first_name: 'Test' + now,
            last_name: 'Doe',
            phone: '1234567890 ext 123',
            email: `test+${now}@doctorschoiceusa.com`,
            dob: '1950-01-01',
            gender: 'F',
            effective_date: '2023-01-01',
            source_name: 'some source',
            source_type: 'OTHER',
            zip_code: '01844',
            fips: '25009'
        }
    }
    return form
}

export default {
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        main: {
            type: Boolean,
            default: true
        },
        assumeNew: {
            type: Boolean,
            default: false
        },
        showEmailOptIn: {
            type: Boolean,
            default: true
        },
        readonly: {
            type: Boolean,
            default: false
        },
        test: {
            type: Boolean,
            default: false
        },
    },
    data () {
        const form = getNewClientForm(this.test)
        return {
            checking: false,
            checkingValidFeedback: null,
            checkingInvalidFeedback: null,
            dirty: false,
            form,
        }
    },
    computed: {
        ...mapGetters(['clientRelationships', 'sourceTypes', 'groupRelationships', 'genders', 'yesnos', 'usStates']),
        ...mapState({
            scheduleHelpText: state => state.scheduleHelpText
        }),
        clientCheckedFound () {
            return this.checkingInvalidFeedback ? false : this.checkingValidFeedback ? true : null
        },
        sourceLabel () {
            if (/EMPLOYER|FRIEND|FAMILY_MEMBER|FINANCIAL_ADVISOR|DOCTOR|OTHER/.test(this.form.source_type)) {
                return 'Please specify'
            }
            if (/FAMILY_FIRST/.test(this.form.source_type)) {
                return 'Please provide the name of advocate that referred you'
            }
            return null
        },
        dobx () {
            return fromDob(this.form.dob) || {}
        },
        age () {
            return this.dobx.age
        },
        eq64 () {
            return this.dobx.eq64
        },
        gte65 () {
            return this.dobx.gte65
        },
        lt65 () {
            return this.dobx.lt65
        },
        t65 () {
            return this.dobx.t65 || this.dobx.turning_65
        },
    },
    watch: {
        value: {
            deep: true,
            handler (v1, v2) {
                if (!_.isEqual(v1, v2)) {
                    this.updateForm()
                }
            }
        },
        form: {
            deep: true,
            handler (m1, m2) {
                this.dirty = true
                this.emitInput()
            }
        },
        'form.dob' () {
            return this.checkClient()
        },
        'form.location' () {
            this.$set(this.form, 'zip_code', this.form?.location?.zip_code || null)
            this.$set(this.form, 'fips', this.form?.location?.fips || null)
        },
    },
    created () {
        this.updateForm()
    },
    mounted () {
        this.setGlobal('$clientForm', this)
        this.checkClient()
    },
    methods: {
        updateForm () {
            _.each(this.value, (v, k) => {
                this.$set(this.form, k, _.cloneDeep(v))
            })
        },
        emitInput () {
            this.$emit('input', this.form)
        },
        async checkClient () {
            if (this.form.returning && this.form.id) {
                const data = await this.$api.getExistingClientToken(this.form)
                if (data) {
                    this.$emit('token', data.token)
                    this.$emit('phone', data.cell_phone)
                    this.$emit('agent', data.agent)
                    this.$emit('agents', data.agents)
                    this.$emit('group', data.group)
                    this.$emit('group_relationship', data.group_relationship)
                    this.$emit('source_type', data.source_type)
                    this.$emit('source_name', data.source_name)
                    this.form.token = data.token
                    this.form.phone = data.cell_phone
                    this.form.group = data.group
                    this.form.group_relationship = data.group_relationship
                    this.form.source_type = data.source_type
                    this.form.source_name = data.source_name
                    this.checkingValidFeedback = `Welcome back ${this.$_.startCase(this.form.first_name)}!`
                }
                return
            }
            if (this.form.returning &&
                this.form.first_name &&
                this.form.last_name &&
                this.form.email &&
                this.form.dob &&
                this.dirty) {
                try {
                    this.checkingInvalidFeedback = null
                    this.checkingValidFeedback = null

                    this.form.token = null
                    this.checking = true
                    const data = await this.$api.getExistingClientToken(this.form)
                    if (data) {
                        this.$emit('token', data.token)
                        this.$emit('phone', data.cell_phone)
                        this.$emit('agent', data.agent)
                        this.$emit('agents', data.agents)
                        this.$emit('group', data.group)
                        this.$emit('group_relationship', data.group_relationship)
                        this.$emit('source_type', data.source_type)
                        this.$emit('source_name', data.source_name)
                        this.form.token = data.token
                        this.form.phone = data.cell_phone
                        this.form.group = data.group
                        this.form.group_relationship = data.group_relationship
                        this.form.source_type = data.source_type
                        this.form.source_name = data.source_name
                        this.checkingValidFeedback = `Welcome back ${this.$_.startCase(this.form.first_name)}!`
                    }
                } catch (e) {
                    this.checkingInvalidFeedback = e?.message || 'Something went wrong while checking your info'
                } finally {
                    this.$nextTick(() => {
                        this.dirty = false
                    })
                    this.checking = false
                }
            }
        },
        async validate () {
            const a = await this.$validator.validate()
            let b = true
            if (this.$refs?.locationField?.$validator.validate) {
                b = await this.$refs.locationField.$validator.validate()
            }
            return a && b
        }
    }
}
