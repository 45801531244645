
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
    props: {
        plan: {
            type: Object,
            default: null
        },
        date: {
            type: String,
            default: null
        },
    },
    data () {
        return {
            form: {
                effective_date: null,
                enrollment_period: this.$route.query.ep || null,
                payment_method: null,
                writing_agent_id: null,
            },
            writingAgents: [],
            e422: null,
            opportunity: null
        }
    },
    computed: {
        ...mapGetters([
            'client',
            'survey',
            'isAEP',
            'isSEPLEC',
            'isSEPMoving',
            'enrollmentPeriods',
            'paymentMethodsPerProduct',
            'year',
        ])
    },
    async mounted () {
        if (this.survey?.effective_date) {
            this.$set(this.form, 'effective_date', this.survey?.effective_date)
        }

        if (!this.form.enrollment_period) {
            if (this.isAEP) {
                this.$set(this.form, 'enrollment_period', 'AEP')
                if (!this.survey?.effective_date) {
                    this.$set(this.form, 'effective_date', this.year + '-01-01')
                }
            } else if (this.isIEP) {
                this.$set(this.form, 'enrollment_period', 'IEP')
            } else if (this.isOEP && (this.plan.type === 'mapd' || this.plan.type === 'ma')) {
                this.$set(this.form, 'enrollment_period', 'OEP')
            } else if (this.isSEPLEC) {
                this.$set(this.form, 'enrollment_period', 'SEP_LEC')
            } else if (this.isSEPMoving) {
                this.$set(this.form, 'enrollment_period', 'SEP_MOVING')
            }
        } else if (this.form.enrollment_period === 'AEP') {
            this.$set(this.form, 'effective_date', this.year + '-01-01')
        }

        this.$set(this.form, 'payment_method', 'MONTHLY_EFT')

        if (this.canAgent) {
            const { agents } = await this.$try('getAgentsForFips', { fips: this.survey.fips, active_ok: true })
            this.writingAgents = agents.map(agent => ({ text: this.toName(agent), value: agent.id }))
            if (_.find(this.writingAgents, option => option.value === this.survey.agent_id)) {
                this.$set(this.form, 'writing_agent_id', this.survey.agent_id)
            }
        }
    },
    methods: {
        async startEnrollment (options = {}) {
            if (!this.form.effective_date) {
                return this.$alert('Effective date is required!')
            }
            this.e422 = null
            this.opportunity = null
            const replace = options.replace === true
            if (this.canAgent) {
                const body = {
                    user: this.client?.id,
                    plan_id: this.plan?.id,
                    ...this.form,
                    replace
                }
                const { error, opportunity } = await this.$try('agentStartEnrollment', body, { alert: false })
                if (error?.response?.status === 422 && !replace) {
                    this.e422 = error
                    return
                }
                if (error) {
                    return this.$alert(error)
                }
                if (opportunity) {
                    this.opportunity = opportunity
                }
            } else {
                const { error } = await this.$try('startEnrollment', {
                    plan_id: this.plan?.id,
                    ...this.form,
                })
                if (error) {
                    return this.$alert(error)
                } else {
                    this.$router.push('/enroll-thank-you')
                    this.$emit('close')
                }
            }
        }
    },
}
