

export default {
    props: {
        model: {
            type: Object,
            default: null
        },
    },
    computed: {
        name () {
            const str = this.toName(this.model)
            return str
        },
        inactive () {
            return this.model.active === false
        },
    },
}
