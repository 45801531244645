
import { mapGetters } from 'vuex'

export default {
    props: {
        merge: {
            type: Object,
            default: () => ({})
        },
        showOnlyReleventRows: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            form: {
                lives_with_spouse: null,
                projected_lives_with_spouse: null,
                tax_filing_status: null,
                projected_tax_filing_status: null,
                current_income: null,
                year: null
            }
        }
    },
    fetch () {
        this.updateForm()
    },
    computed: {
        ...mapGetters([
            'yearMinus2',
            'calcIrmaaB',
            'calcIrmaaD',
            'incomeOptionsFiledAsMap',
            'projectedIncomeDrop',
        ]),
        marriedFilingLivingSeparately () {
            return (this.form.tax_filing_status === 'MARRIED_SEPARATELY' && this.form.lives_with_spouse === false) || (this.form.projected_tax_filing_status === 'MARRIED_SEPARATELY' && this.form.projected_lives_with_spouse === false)
        },
    },
    watch: {
        merge: {
            deep: true,
            handler () {
                this.updateForm()
            }
        },
    },
    methods: {
        updateForm () {
            if (this.merge) {
                for (const key in this.merge) {
                    const value = this.merge[key]
                    this.$set(this.form, key, value)
                }
            }
        },
        projected_tax_filing_status_sentence () {
            if (this.form.projected_tax_filing_status === 'MARRIED_SEPARATELY') {
                return 'Married, filing separately'
            } else if (this.form.projected_tax_filing_status === 'MARRIED_JOINT') {
                return 'Married, filing jointly'
            }
            return 'Single'
        }
    },
}
