
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
    props: {
        plan: {
            type: Object,
            default: null
        },
        collapsibleVisible: {
            type: Boolean,
            default: false
        },
        compact: {
            type: Boolean,
            default: false
        },
        bordered: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            showAllValues: false,
            showMedicareEstimations: false,
            hideTable: false,
            selectedNpi: null,
        }
    },
    computed: {
        ...mapGetters(['excludedDrugs', 'getDrugUniquishKey', 'isDonutHoleYear', 'client', 'year']),
        pharmacy0 () {
            if (this.selectedNpi) {
                return this.pharmaciesMap[this.selectedNpi] || this.plan?.cheapest_pharmacy
            }
            return this.plan?.cheapest_pharmacy
        },
        monthlyDates () {
            return this.pharmacy0?.monthly_dates || []
        },
        hasCataphoric () {
            return this.monthlyDates.some(m => m.date === this.pharmacy0?.phase_information.catastrophic_start)
        },
        hasDonut () {
            return this.isDonutHoleYear && this.monthlyDates.some(m => m.date === this.pharmacy0?.phase_information.gap_start)
        },
        hasDeductible () {
            return this.plan?.drug_deductible_cost_share && this.monthlyDates.some(m => m.date === this.pharmacy0?.phase_information?.initial_coverage_start)
        },
        pharmacies () {
            return this.plan?.drug_cost_by_pharmacy_list.map(p => ({ value: p.pharmacy.npi, text: p.pharmacy.name }))
        },
        pharmaciesMap () {
            return _.keyBy(this.plan?.drug_cost_by_pharmacy_list, p => p.pharmacy.npi)
        }
    }
}
