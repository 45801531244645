
import _ from 'lodash'
import card from '~/mixins/card'

// national averages
const averages = {
    pcp_copay_cost_share: 171,
    specialist_copay_cost_share: 171,
    radiology_copay_cost_share: 650,
    bloodwork_copay_cost_share: 432,
    emergency_copay_cost_share: 1200,
    xrays_copay_cost_share: 290,
    outpatient_copay_cost_share: 5000, // 4000-170000
    hospitalization_copay_cost_share: 2883, // per day
}

const events = {
    low: {
        pcp_copay_cost_share: 2,
        specialist_copay_cost_share: 2,
        radiology_copay_cost_share: 1,
        bloodwork_copay_cost_share: 1,
        emergency_copay_cost_share: 0,
        xrays_copay_cost_share: 1,
        outpatient_copay_cost_share: 0,
        hospitalization_copay_cost_share: 0
    },
    medium: {
        pcp_copay_cost_share: 4,
        specialist_copay_cost_share: 4,
        radiology_copay_cost_share: 2,
        bloodwork_copay_cost_share: 2,
        emergency_copay_cost_share: 0,
        xrays_copay_cost_share: 2,
        outpatient_copay_cost_share: 0,
        hospitalization_copay_cost_share: 0
    },
    high: {
        pcp_copay_cost_share: 6,
        specialist_copay_cost_share: 6,
        radiology_copay_cost_share: 4,
        bloodwork_copay_cost_share: 4,
        emergency_copay_cost_share: 1,
        xrays_copay_cost_share: 4,
        outpatient_copay_cost_share: 1,
        hospitalization_copay_cost_share: 1
    },
    catastrophic: {
        pcp_copay_cost_share: 0,
        specialist_copay_cost_share: 0,
        radiology_copay_cost_share: 0,
        bloodwork_copay_cost_share: 0,
        emergency_copay_cost_share: 0,
        xrays_copay_cost_share: 0,
        outpatient_copay_cost_share: 0,
        hospitalization_copay_cost_share: 0,
        maximum_oopc_cost_share: 1
    }
}

export default {
    mixins: [card],
    props: {
        displayPlans: {
            type: Array,
            default: () => []
        },
        showPrint: {
            type: Boolean,
            default: false
        },
    },
    data () {
        return {
            userGroupPlanModal: false,
            editingUserGroupPlan: null,
            averages
        }
    },
    computed: {
        comparingPlans () {
            return this.displayPlans
        },
    },
    methods: {
        canShowPropRow (prop) {
            return this.comparingPlans.every((plan) => {
                if (plan?.plan_type === 'gp') {
                    if (plan.config && (!plan.config.ignore || !plan.config.ignore[prop])) {
                        return true
                    }
                    return false
                }
                return true
            })
        },
        comboName (c) {
            return c.name || c.map(p => p.name).join(' + ')
        },
        comboId (c) {
            return c.id || c.map(p => p.id).join('_')
        },
        comboYearlyDeductible (c) {
            const plans = _.isArray(c) ? c : [c]
            const sum1 = _.sum(plans.map(p => parseInt(_.isNil(p.yearly_deductible) ? p.deductible : p.yearly_deductible, 10) || 0))
            const sum2 = _.sum(plans.map(p => parseInt(p.drug_deductible, 10) || 0))
            return sum1 + sum2
        },
        comboPremiumsMonthlyTotal (c) {
            const plans = _.isArray(c) ? c : [c]
            const vals = [this.part_a_premium]
            let skipPartB = false
            let skipPartD = false
            if (plans[0].plan_type === 'gp') {
                skipPartB = true
                skipPartD = true
            }
            if (!skipPartB) {
                vals.push(this.part_b_premium)
                vals.push(this.part_b_irmaa)
            }
            if (!skipPartD) {
            // if (!this.tricare_or_champva && !skipPartD) {
                vals.push(this.part_d_irmaa)
            }
            let sum = _.sum(vals.filter(v => v))
            // if (!this.tricare_or_champva) {
            sum += _.sum(plans.map(plan => plan.monthly_premium))
            // }

            return sum
        },
        comboDrugsYearlyTotal (c) {
            const plans = _.isArray(c) ? c : [c]
            let sum = 0
            sum += _.sum(plans.map(plan => (_.get(plan, 'drug_cost_by_pharmacy_list.0.estimated_yearly_total') || 0) / 12)) || 0
            return sum
        },
        comboUtilizationCopay (c, utilization = 'low') {
            const plans = _.isArray(c) ? c : [c]
            let sum = 0
            const multipliers = events[utilization]
            _.each(multipliers, (multiplier, copay) => {
                sum += _.sum(plans.map((plan) => {
                    return (plan[`${copay}_percent`] ? averages[copay] * plan[copay] / 100 : (plan[copay] || 0)) * multiplier
                })) || 0
            })
            return sum
        },
        openPrintWindow () {
            window.removeEventListener('message', this.onMessage)
            window.addEventListener('message', this.onMessage)
            this.childWindow = window.open('/print-window')
            if (this.childWindow.focus) {
                this.childWindow.focus()
            }
        },
        onMessage (event) {
            if (event?.origin === window?.origin && event.data === 'mounted') {
                this.childWindow.postMessage(this.$stringify({
                    survey: this.$store.state.survey,
                    summary: this.$store.state.summary,
                    plans: this.comparingPlans
                }))
            }
        }
    }
}
