
export default {
    props: {
        plan: {
            type: Object,
            default: null
        },
        collapsibleVisible: {
            type: Boolean,
            default: false
        },
        compact: {
            type: Boolean,
            default: false
        },
        bordered: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        pharmacy0 () {
            // the one with the most "dates"
            return this.$_.maxBy(this.plan.drug_cost_by_pharmacy_list, pharmacy => Object.keys(pharmacy.estimated_monthly_costs_map).length)
        },
    }
}
