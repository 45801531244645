import Vue from 'vue'
import moment from 'moment'
import 'moment-timezone'
import _ from 'lodash'
import { marked } from 'marked'
import stringify from 'fast-safe-stringify'
import sanitizeHTML from 'sanitize-html'
import Multiselect from 'vue-multiselect'
import { Waypoint } from 'vue-waypoint'
import draggable from 'vuedraggable'
import VeeValidate from 'vee-validate'
import Clipboard from 'v-clipboard'
import VueFull from 'vue/dist/vue.js'

sanitizeHTML.defaults.allowedTags = sanitizeHTML.defaults.allowedTags.concat(['img', 'mark'])

Vue.component('Multiselect', Multiselect)
Vue.component('Waypoint', Waypoint)
Vue.component('Draggable', draggable)

Vue.use(VeeValidate, {
    fieldsBagName: 'veeFields'
})

Vue.use(Clipboard)

const sanitizeScript = function (html) {
    const allowedAttributes = {
        a: ['href', 'name', 'target'],
        img: ['src', 'srcset', 'alt', 'title', 'width', 'height', 'loading'],
        '*': ['style']
    }
    return sanitizeHTML(html, { allowedAttributes })
}

const sanitize = function (html) {
    if (!html || !_.isString(html)) return html
    return sanitizeHTML(html)
}

const mark = function (content) {
    const { query } = this.$store.state.query
    if (!query || !content || !content.replace) {
        return content
    }
    return content.replace(new RegExp(query, 'gi'), match => `<mark>${match}</mark>`)
}

const markAndSanitize = function (content) {
    return sanitize(mark(content))
}

const parseMarkdown = function (content) {
    return marked.parse(content)
}

const parseMarkdownAndSanitize = function (content) {
    return sanitize(parseMarkdown(content))
}

export const pluralize = (num, singular, plural, showNum = true) => {
    if (!plural) plural = `${singular}s`
    return `${showNum ? `${num} ` : ''}${num === 1 ? singular : plural}`
}

export default ({ store }, inject) => {
    inject('_', _)
    inject('moment', moment)
    inject('sanitize', sanitize)
    inject('sanitizeScript', sanitizeScript)
    inject('mark', mark)
    inject('parseMarkdown', parseMarkdown)
    inject('markAndSanitize', markAndSanitize)
    inject('parseMarkdownAndSanitize', parseMarkdownAndSanitize)
    inject('pluralize', pluralize)
    inject('stringify', stringify)
    inject('vue', Vue)
    inject('vueFull', VueFull)
    if (process.server) {
        inject('vueRender', require('vue-server-renderer').createRenderer())
    }
}
