
import { mapGetters } from 'vuex'
import { getMarkAttributes } from '@tiptap/core'

export default {
    props: {
        editor: {
            type: Object,
            default: null
        },
        light: {
            type: Boolean,
            default: false,
        },
        advanced: {
            type: Boolean,
            default: false,
        }
    },
    data () {
        return {
            disabled: false,
        }
    },
    computed: {
        ...mapGetters(['highlightColor', 'fontColor']),
    },
    watch: {
        editor () {
            this.disabled = !(this.editor.options.editable)
        }
    },
    methods: {
        setLink () {
            // todo: make a better prompt
            /* eslint-disable-next-line */
            const href = window.prompt('URL')
            this.editor.chain().focus().setLink({ href }).run()
        },

        hexToRgb (hex = '#000000') {
            hex = hex.replace(/^#/, '')

            const bigint = parseInt(hex, 16)
            const r = (bigint >> 16) & 255
            const g = (bigint >> 8) & 255
            const b = bigint & 255

            return `rgb(${r}, ${g}, ${b})`
        },

        toggleColor () {
            if (!this.disabled) {
                const attrs = getMarkAttributes(this.editor.state, 'textStyle')
                const rgb = this.hexToRgb(this.fontColor)
                if (attrs?.color === this.fontColor || attrs?.color === rgb) {
                    this.editor.chain().focus().unsetColor().run()
                } else {
                    this.editor.chain().focus().setColor(this.fontColor).run()
                }
            }
        },

        updateColor (event) {
            if (event.target.name === 'highlighter') {
                this.$store.commit('setColor', { value: event.target.value, isFontColor: false })
                this.editor.chain().focus().toggleHighlight({ color: this.highlightColor }).run()
            } else {
                this.$store.commit('setColor', { value: event.target.value, isFontColor: true })
                this.toggleColor()
            }
        },

        highlightPicker () {
            if (!this.disabled) { this.$refs.highlighter.click() }
        },

        fontColorPicker () {
            if (!this.disabled) { this.$refs.fontColor.click() }
        }
    },
}
