import _ from 'lodash'

const createRouterx = function ({ app }) {
    const router = app.router
    const routerx = {
        pushQuery (query = {}) {
            return routerx.setQuery(query, { replace: false })
        },
        replaceQuery (query = {}) {
            return routerx.setQuery(query, { replace: true })
        },
        async setQuery (query = {}, options = {}) {
            try {
                await router[options.replace ? 'replace' : 'push']({
                    query: {
                        ...router.currentRoute?.query || {},
                        ...query,
                    },
                })
            // eslint-disable-next-line
            } catch (e) {}
        },
        async deleteQuery (query) {
            query = _.isArray(query) ? [].concat(query) : Object.keys(query)
            const q = Object.assign({}, router.currentRoute?.query || {})
            query.forEach((query) => {
                delete q[query]
            })
            try {
                await router.replace({ query: q })
            // eslint-disable-next-line
            } catch (e) {}
        },
        pushParams (params) {
            return routerx.setParams(params, { replace: false })
        },
        replaceParams (params) {
            return routerx.setParams(params, { replace: true })
        },
        async setParams (params, options = {}) {
            const query = Object.assign({}, router.currentRoute?.query || {})
            try {
                await router[options.replace ? 'replace' : 'push']({
                    query,
                    params,
                })
            // eslint-disable-next-line
            } catch (e) {}
        }
    }
    return routerx
}

export default (context, inject) => {
    const $routerx = createRouterx(context)
    inject('routerx', $routerx)
}
