
/* eslint-disable vue/component-definition-name-casing */
import { mapGetters } from 'vuex'
import layout from '~/mixins/layout'

export default {
    name: 'error',
    mixins: [layout],
    props: {
        error: {
            type: [Object, String],
            default: null
        }
    },
    computed: {
        ...mapGetters(['location'])
    }
}
