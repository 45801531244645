import Vue from 'vue'
import VueGtag from 'vue-gtag'

// https://matteo-gabriele.gitbook.io/vue-gtag/v/master/api
Vue.use(VueGtag, {
    config: {
        id: 'G-4104YDHVSZ',
        params: {
            send_page_view: true
        }
    }
})

export default async (context) => {
    await context.store.dispatch('nuxtClientInit', context)
}
