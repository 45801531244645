
/* eslint-disable camelcase */

import { mapGetters } from 'vuex'
import _ from 'lodash'
import { address } from '~/util/string'

export default {
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        syncQuery: {
            type: Boolean,
            default: false,
        },
        zipCode: {
            type: String,
            default: null,
        },
        isSurvey: {
            type: Boolean,
            default: false
        },
        showSelectedOnly: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            results: [],
            count: 0,
            searching: false,
            searchForm: {
                query: this.$route?.query?.query,
                zip_code: this.zipCode || this.$route?.query?.zip_code,
                distance: parseInt(this.$route?.query?.distance || 10),
                page: parseInt(this.$route?.query?.page) || 0,
                page_size: parseInt(this.$route?.query?.page_size) || 10,
            },
        }
    },
    computed: {
        ...mapGetters(['distances']),
        markers () {
            return this.results.map((result) => {
                result.position = { lat: result.latitude, lng: result.longitude }
                result.key = `pharmacy-${result.npi}`
                result.icon = 'clinic-medical'
                result.title = `${result.name}`
                result.subtitle = `${address(result)}`
                result.selected = this.isPharmacyInList(result)
                result.description = result.selected ? 'Click to remove from list' : 'Click to add to list'
                result.variant = result.selected ? 'primary' : 'secondary'
                return result
            })
        },
        valid () {
            return this.searchForm.zip_code?.length === 5 && this.searchForm.distance > 0
        },
    },
    watch: {
        zipCode (newZipCode) {
            this.$set(this.searchForm, 'zip_code', newZipCode)
        },
        searchForm: {
            deep: true,
            handler: _.debounce(function (searchForm) {
                this.$nextTick(() => {
                    this.syncQuery && this.$routerx.replaceQuery(searchForm)
                })
            }, 300)
        },
    },
    mounted () {
        if (this.valid && this.searchForm.query) {
            return this.search()
        }
    },
    methods: {
        setZipCodeIfUnset (zipcode) {
            if (!this.searchForm.zip_code) {
                this.$set(this.searchForm, 'zip_code', zipcode)
                if (this.valid) this.search()
            }
        },
        async search ({ page = 0, page_size = 10 } = {}) {
            this.$set(this.searchForm, 'page', page)
            this.$set(this.searchForm, 'page_size', page_size)
            this.searching = true
            const { results, error, count } = await this.$try('searchPharmacies', this.searchForm, { spinner: false })
            if (!error) {
                this.results = page === 0 ? results : [].concat(this.results).concat(results || [])
                this.count = count
            }
            this.searching = false
        },
        findPharmacyIndex (pharmacy) {
            return _.findIndex(this.value, p => p.npi === pharmacy.npi)
        },
        isPharmacyInList (pharmacy) {
            return this.findPharmacyIndex(pharmacy) > -1
        },
        addPharmacy (pharmacy) {
            const pharmacies = this.value.slice(0)
            pharmacies.push(pharmacy)
            this.$emit('input', pharmacies)
        },
        removePharmacy (pharmacy) {
            const index = this.findPharmacyIndex(pharmacy)
            if (index > -1) {
                const pharmacies = this.value.slice(0)
                pharmacies.splice(index, 1)
                this.$emit('input', pharmacies)
            }
        },
        togglePharmacy (pharmacy) {
            if (this.isPharmacyInList(pharmacy)) {
                return this.removePharmacy(pharmacy)
            } else {
                return this.addPharmacy(pharmacy)
            }
        }
    },
}
