
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
    props: {
        value: {
            type: Object,
            default () { return {} }
        },
        statesFilter: {
            type: Boolean,
            default: false
        },
    },
    data () {
        return {
            editingFile: {
                metadata: {
                    available_states: []
                }
            },
        }
    },
    computed: {
        ...mapGetters(['usStates', 'survey'])
    },
    watch: {
        value (newFile = {}) {
            this.editingFile = _.cloneDeep({ ...this.editingFile, ...newFile })
            this.$set(this.editingFile.metadata, 'available_states', this.editingFile?.metadata?.available_states || [])
        }
    },
    created () {
        this.editingFile = _.cloneDeep({ ...this.editingFile, ...this.value })
        this.$set(this.editingFile.metadata, 'available_states', this.editingFile?.metadata?.available_states || [])
    },
    mounted () {
        this.setGlobal('$editFileForm', this)
    },
    methods: {
        async saveChanges () {
            const result = await this.$try('updateFile', { ...this.editingFile })
            if (result.success) {
                this.$emit('input', result)
            }
        },
    }
}
