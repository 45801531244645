
import { mapGetters, mapMutations } from 'vuex'

export default {
    props: {
        autopadding: {
            type: Boolean,
            default: true,
        }
    },
    data () {
        return {
            iconsVariantsMap: {
                success: 'thumbs-up',
                info: 'info',
                warning: 'exclamation',
                danger: 'exclamation-circle',
            },
            titlesVariantsMap: {
                danger: 'Error',
            },
        }
    },
    computed: {
        ...mapGetters([
            'isBusy',
            'survey',
            'isSurveyRoute',
            'alerts'
        ])
    },
    methods: {
        ...mapMutations(['clearAlert'])
    }
}
