
import { mapGetters } from 'vuex'
import layout from '~/mixins/layout'
/* eslint-disable vue/component-definition-name-casing */
export default {
    name: 'wide',
    mixins: [layout],
    computed: {
        ...mapGetters(['client', 'activeSurveyTab']),
    }
}
