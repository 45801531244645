

export default {
    props: {
        pages: {
            type: Array,
            required: true
        },
        current: {
            type: Number,
            required: true
        }
    }
}
