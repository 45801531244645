
export default {
    props: {
        editable: {
            type: Boolean,
            default: true,
        },
        draggable: {
            type: Boolean,
            default: false,
        },
        removable: {
            type: Boolean,
            default: false,
        },
        editedBy: {
            type: String,
            default: null,
        },
        editedOn: {
            type: [String, Date],
            default: null,
        },
        topControls: {
            type: Boolean,
            default: true,
        },
        bottomControls: {
            type: Boolean,
            default: true,
        },
        confirmReset: {
            type: Boolean,
            default: true,
        },
        html: {
            type: String,
            default: null,
        },
    },
    data () {
        return {
            ssrHtml: null,
            hovering: false,
            editor: false,
            editorContent: null,
            editorOptions: {},
        }
    },
    async fetch () {
        if (this.$store.getters.isServer) {
            this.ssrHtml = await this.$root.vueTemplateToHtmlAsync(this.html)
        }
    },
    methods: {
        enableEditing () {
            this.editorContent = this.html || this.getOriginalHtml()
            this.editor = true
        },
        disableEditing () {
            this.editorContent = null
            this.editor = false
        },
        getOriginalHtml () {
            return this.$refs.original.innerHTML
        },
        saveEdits () {
            this.$emit('save', this.editorContent)
            this.disableEditing()
        },
        async resetEdits () {
            if (this.confirmReset) {
                // description: 'This will reset all manual changes done by you or anyone else',
                const value = await this.$bvModal.msgBoxConfirm('Are you sure you want to reset this card\'s to the original text?', {
                    title: 'Please Confirm',
                    okVariant: 'danger',
                    okTitle: 'Yes, I am sure',
                    cancelTitle: 'No',
                    footerClass: 'p-2',
                    centered: true
                })
                if (value) {
                    this.$emit('reset')
                    this.disableEditing()
                }
            } else {
                this.$emit('reset')
                this.disableEditing()
            }
        }

    },
}
