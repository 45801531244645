
import { mapGetters, mapState } from 'vuex'
import { slugify } from '~/util/string'

export default {
    props: {
        label: {
            type: String,
            default: null,
        },
        dosage: {
            type: String,
            default: null,
        },
        form: {
            type: String,
            default: null,
        },
        quantity: {
            type: [String, Number],
            default: null,
        },
    },
    computed: {
        goodRxUrl () {
            const urlParams = []
            if (this.dosage) urlParams.push(`dosage=${this.dosage}`)
            if (this.form) urlParams.push(`form=${this.form}`)
            if (this.quantity) urlParams.push(`quantity=${this.quantity}`)
            if (this.zip_code) urlParams.push(`location=${this.zip_code}`)
            return `https://www.goodrx.com/${slugify(this.label.trim())}?${urlParams.join('&')}`
        },
        ...mapGetters(['canAgent']),
        ...mapState({
            zip_code: state => state.survey?.zip_code,
        }),
    },
}
