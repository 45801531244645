import { render, staticRenderFns } from "./ConsultationAi.vue?vue&type=template&id=2dd86e18&lang=pug&"
import script from "./ConsultationAi.vue?vue&type=script&lang=js&"
export * from "./ConsultationAi.vue?vue&type=script&lang=js&"
import style0 from "./ConsultationAi.vue?vue&type=style&index=0&id=2dd86e18&prod&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/codebuild/output/src60861037/src/frontend/components/Icon.vue').default,Spinner: require('/codebuild/output/src60861037/src/frontend/components/Spinner.vue').default})
