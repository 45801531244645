
// import _ from 'lodash'
import { mapGetters } from 'vuex'
import { hashObject } from '@/util/string'

export default {
    props: {
        plan: {
            type: Object,
            default: null,
        },
        isTable: {
            type: Boolean,
            default: false
        },
    },
    data () {
        return {
            isDoubleCheckProvidersLoading: false
        }
    },
    computed: {
        ...mapGetters([
            'isProviderInNetwork',
            'isProviderOutNetwork',
            'isProviderUnsureNetwork',
            'providers',
            'survey',
            'plansMap',
            'year'
        ]),
    },
    methods: {
        hashObject,
        async doubleCheckProviders () {
            this.isDoubleCheckProvidersLoading = true
            try {
                const body = {
                    year: this.year,
                    providers: this.providers,
                    zip_code: this.survey.zip_code,
                    plan_type: this.plan.plan_type,
                    source_id: this.plan.source_id,
                    plan_subtype: this.plan.plan_subtype,
                    plan_name: this.plan.name,
                    carrier: this.plan.carrier,
                    plan_source_id: this.plan.source_id,
                    fresh: false,
                }
                const data = await this.$api.doubleCheckProviders(body, { spinner: false })

                const currentInNetworkProviders = this.plan.in_network_providers.slice(0)
                const inNetworkProviders = []
                const outNetworkProviders = []
                Object.entries(data).forEach(([key, value]) => {
                    if (value?.is_provider_in_network === true) {
                        inNetworkProviders.push(parseInt(key, 10))
                    } else if (value?.is_provider_in_network === false) {
                        outNetworkProviders.push(parseInt(key, 10))
                    }
                })
                // filter out the providers that are not in the network for sure
                const currentInNetworkProvidersFilteredOut = currentInNetworkProviders.filter(provider => inNetworkProviders.includes(provider)).filter(provider => !outNetworkProviders.includes(provider))
                // combine the rest, because if we're not sure from the doubleCheckProviders then we just rely on the current in_network_providers
                const updatedInNetworkProviders = [...new Set([...currentInNetworkProvidersFilteredOut, ...(inNetworkProviders || [])])]
                const updatedOutNetworkProviders = outNetworkProviders
                this.$store.commit('updateQuoteNetworkProviders', { id: this.plan.id, inNetworkProviders: updatedInNetworkProviders, outNetworkProviders: updatedOutNetworkProviders })
                this.$store.commit('pushAlert', { message: 'Providers network have been updated', variant: 'info' })
            } finally {
                this.isDoubleCheckProvidersLoading = false
            }
        }
    }
}
