
import _ from 'lodash'
import { mapGetters } from 'vuex'
import card from '~/mixins/card'

export default {
    mixins: [card],
    props: {
        showDental: {
            type: Boolean,
            default: false,
        },
        overridePlans: {
            type: Array,
            default: () => [],
        },
        skipPartA: {
            type: Boolean,
            default: false,
        },
        skipPartB: {
            type: Boolean,
            default: false,
        },
        skipPartD: {
            type: Boolean,
            default: false,
        },
        skipSurcharges: {
            type: Boolean,
            default: false,
        },
        mode: {
            type: String,
            default: 'full',
        },
    },
    data () {
        return {
            dental_adding: null,
        }
    },
    computed: {
        ...mapGetters([
            'canAgent',
            'paymentMethodsPerProduct',
        ]),
        full () {
            return this.mode === 'full'
        },
        lite () {
            return this.mode === 'lite'
        },
        premiumDisplayPlans () {
            return this.overridePlans?.length ? this.overridePlans : this.selected_plans
        },
        premiumsTotal () {
            const vals = [this.part_a_premium]
            if (!this.skipPartB) {
                vals.push(this.part_b_premium)
                vals.push(this.part_b_irmaa)
            }

            // if (!this.tricare_or_champva && !this.skipPartD) {
            if (!this.skipPartD) {
                vals.push(this.part_d_irmaa)
            }
            let sum = _.sum(vals.filter(v => parseFloat(v)))
            // if (!this.tricare_or_champva) {
            sum += _.sum(this.premiumDisplayPlans.map(plan => parseFloat(plan.monthly_premium)))
            // }

            sum += _.sum(this.premiumDisplayPlans.map(plan => (parseFloat(_.get(plan, 'drug_cost_by_pharmacy_list.0.estimated_yearly_total') || 0)) / 12)) || 0
            if (this.showDental && this.dental_cost) {
                sum += parseFloat(this.dental_cost)
            }
            return sum
        },
        yearlyPremiumsTotal () {
            return this.premiumsTotal * 12
        },
        anySurcharge () {
            return !!(this.part_b_irmaa || this.part_d_irmaa)
        },
    },
    methods: {
        // todo: @luke [no-question] dental_coverage_cost
        async onDentalRowSave () {
            await this.$store.dispatch('answer', { name: 'dental_coverage_cost', value: this.dental_adding })
            this.onDentalRow()
        },
        async onDentalRowDelete () {
            await this.$store.dispatch('answer', { name: 'dental_coverage_cost', value: null })
            this.onDentalRow()
        },
        onDentalRow () {
            this.$store.dispatch('saveUserAnswers', { answersSaving: 1 })
            this.dental_adding = null
        },
    },
}
