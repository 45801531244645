

export default {
    props: {
        value: {
            type: Array,
            default: () => []
        }
    },
}
