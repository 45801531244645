
import { mapGetters } from 'vuex'

export default {
    props: {
        drug: {
            type: Object,
            default: null
        },
        isDrugInList: {
            type: Function,
            default: () => false,
        },
        isDrugParentInList: {
            type: Function,
            default: () => false,
        },
        isDrugGenericInList: {
            type: Function,
            default: () => false,
        },
        removable: {
            type: Boolean,
            default: false
        },
        editable: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
    },
    data () {
        return {
            generic: null,
            adding: 0,
            editing: false
        }
    },
    computed: {
        ...mapGetters(['getDrugUniquishKey']),
        options () {
            if (!this.drug?.generic) {
                return []
            }
            return [
                { value: true, text: `Use generic version: ${this.titlelize(this.drug.generic.name)}` },
                { value: false, text: `Keep the brand name: ${this.titlelize(this.drug.name)}` }
            ]
        },
        val () {
            return this.generic ? { ...this.drug.generic, is_generic: true } : this.drug
        }
    },
    mounted () {},
    methods: {
        emitValue (val) {
            if (this.adding) {
                this.$emit('add', val)
                this.adding = 0
                this.$refs.modal.hide()
            } else {
                this.$emit('edit', val)
                this.editing = false
                this.$refs.modal.hide()
            }
        },
        startAdd () {
            if (this.drug.generic) {
                this.adding = 1
            } else {
                this.adding = 2
            }
            this.$refs.modal.show()
        },
        midAdd () {
            this.adding = 2
        },
    }
}
