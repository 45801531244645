
import { mapGetters } from 'vuex'

export default {
    props: {
        plan: {
            type: Object,
            default: null,
        },
    },
    computed: {
        ...mapGetters([
            'isProviderInNetwork',
            'providers',
        ]),
        isSupp () {
            return this.plan?.plan_type === 'supp'
        },
        someProvidersInNetwork () {
            return this.providers.some(p => this.isProviderInNetwork(p, this.plan))
        },
        noneProviderInNework () {
            return this.providers.every(p => !this.isProviderInNetwork(p, this.plan))
        },
        providersOutNework () {
            return this.providers.filter(p => !this.isProviderInNetwork(p, this.plan))
        }
    },
}
