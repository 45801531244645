
import card from '~/mixins/card'
export default {
    mixins: [card],
    props: {
        magicUrl: {
            type: String,
            default: '/'
        },
        mode: {
            type: [String, Object],
            default: 'default'
        },
        aep: {
            type: Boolean,
            default: null
        },
        index: {
            type: Number,
            default: null
        },
        comboIndex: {
            type: Number,
            default: null
        },
    },
}
