import { render, staticRenderFns } from "./Pharmacy.vue?vue&type=template&id=e1089bc8&scoped=true&lang=pug&"
import script from "./Pharmacy.vue?vue&type=script&lang=js&"
export * from "./Pharmacy.vue?vue&type=script&lang=js&"
import style0 from "./Pharmacy.vue?vue&type=style&index=0&id=e1089bc8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e1089bc8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/codebuild/output/src60861037/src/frontend/components/Icon.vue').default,DrugNameDesc: require('/codebuild/output/src60861037/src/frontend/components/DrugNameDesc.vue').default,DrugAlternatives: require('/codebuild/output/src60861037/src/frontend/components/DrugAlternatives.vue').default,ChartBars: require('/codebuild/output/src60861037/src/frontend/components/chart/Bars.vue').default})
