
import { mapState } from 'vuex'
export default {
    props: {
        value: {
            type: String,
            default: 'phone'
        }
    },
    computed: {
        ...mapState({
            scheduleHelpText: state => state.scheduleHelpText
        })
    }
}
