import { render, staticRenderFns } from "./PlanCard.vue?vue&type=template&id=04544a55&lang=pug&"
import script from "./PlanCard.vue?vue&type=script&lang=js&"
export * from "./PlanCard.vue?vue&type=script&lang=js&"
import style0 from "./PlanCard.vue?vue&type=style&index=0&id=04544a55&prod&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PlanCarrierLinks: require('/codebuild/output/src60861037/src/frontend/components/PlanCarrierLinks.vue').default,Stars: require('/codebuild/output/src60861037/src/frontend/components/Stars.vue').default,Icon: require('/codebuild/output/src60861037/src/frontend/components/Icon.vue').default,PlanProvidersList: require('/codebuild/output/src60861037/src/frontend/components/PlanProvidersList.vue').default,PlanComparing: require('/codebuild/output/src60861037/src/frontend/components/PlanComparing.vue').default,PlanCompare: require('/codebuild/output/src60861037/src/frontend/components/PlanCompare.vue').default,GroupPlanCompare: require('/codebuild/output/src60861037/src/frontend/components/GroupPlanCompare.vue').default,EnrollForm: require('/codebuild/output/src60861037/src/frontend/components/EnrollForm.vue').default,UserGroupPlanForm: require('/codebuild/output/src60861037/src/frontend/components/UserGroupPlanForm.vue').default,UploadFilesForm: require('/codebuild/output/src60861037/src/frontend/components/UploadFilesForm.vue').default,EditFileForm: require('/codebuild/output/src60861037/src/frontend/components/EditFileForm.vue').default})
