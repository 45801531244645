
import _ from 'lodash'
import { resolveType } from '~/util/string'

export default {
    props: {
        mode: {
            type: String,
            default: 'default',
        },
        title: {
            type: String,
            default: 'Upcoming Webinars',
        },
        readmore: {
            type: Boolean,
            default: true,
        },
    },
    data () {
        return {
            webinars: []
        }
    },
    async fetch () {
        const { $try, store, route } = this.$nuxt.context
        const results = await $try('getGroupWebinars', store.getters.group?.id, { fresh: !!resolveType(route.query.fresh) })
        if (!results.error) {
            this.webinars = this.widget ? results.slice(0, 6) : results
        }
    },
    computed: {
        webinarsByMonth () {
            return _.groupBy(this.webinars, 'month_str')
        },
        widget () {
            return this.mode === 'widget'
        },
    },
}
