
import { mapGetters } from 'vuex'

export default {
    props: {
        metadata: {
            type: Object,
            default () { return {} }
        },
        size: {
            type: String,
            default: 'lg'
        },
        statesFilter: {
            type: Boolean,
            default: false
        },
    },
    data () {
        return {
            uploadingFiles: null,
            uploadingMetadata: {
                available_states: []
            }
        }
    },
    computed: {
        ...mapGetters(['usStates', 'survey'])
    },
    watch: {
        metadata (newMetadata = {}) {
            this.uploadingMetadata = { ...this.uploadingMetadata, ...newMetadata }
        }
    },
    async created () {
        this.uploadingMetadata = { ...this.uploadingMetadata, ...this.metadata }
        if (this.statesFilter) {
            await this.$store.dispatch('loadCountyFromZipCode')
            const state = this.$store.state?.county?.state
            if (state) {
                this.uploadingMetadata.available_states.push(state)
            }
        }
    },
    mounted () {
        this.setGlobal('$uploadFilesForm', this)
    },
    methods: {
        removeUploadingFileByIndex (idx) {
            this.uploadingFiles.splice(idx, 1)
        },
        async uploadFiles (files) {
            if (!files || !files.length) {
                return
            }
            const form = new FormData()
            files.forEach((f, i) => {
                form.append('files', f)
            })
            const results = await this.$try('uploadFiles', form, { ...this.uploadingMetadata })
            if (!results.error) {
                results.forEach((file) => {
                    if (file.success) {
                        this.$emit('file-uploaded', file)
                    } else {
                        this.$emit('file-failed', file)
                        this.$alert({ message: `${file.filename} failed to upload${file.reason ? `: ${file.reason}` : ''}`, variant: 'warning' })
                    }
                })
                this.uploadingFiles = null
                this.$emit('files-uploaded', results)
            }
        },
    }
}
