
import { mapGetters } from 'vuex'

export default {
    props: {
        client: {
            type: Object,
            default: null
        },
    },
    data () {
        return {
            activeProducts: [],
        }
    },
    async fetch () {
        this.activeProducts = await this.$api.getActiveProducts(this.client?.id)
    },
    computed: {
        ...mapGetters(['crmBase', 'year', 'uid'])
    },
}
