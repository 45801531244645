
import _ from 'lodash'
import { mapState, mapGetters } from 'vuex'

export default {
    props: {
        plan: {
            type: [Object, Array],
            default: () => [],
        },
        plans: {
            type: [Object, Array],
            default: () => [],
        },
        compact: {
            type: Boolean,
            default: false,
        },
        showIcons: {
            type: Boolean,
            default: false,
        },
        showEqual: {
            type: Boolean,
            default: true,
        },
        showNotEqual: {
            type: Boolean,
            default: true,
        },
        showCompare: {
            type: Boolean,
            default: true,
        },
        showSelect: {
            type: Boolean,
            default: true,
        },
        showIndex: {
            type: Boolean,
            default: false,
        },
        showBack: {
            type: Boolean,
            default: true,
        },
        collapseCategories: {
            type: Boolean,
            default: false,
        },
        accordionCategories: {
            type: Boolean,
            default: false,
        },
        collapsibleVisible: {
            type: Boolean,
            default: true,
        },
        highlightNotEqual: {
            type: Boolean,
            default: false,
        },
        inModal: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            categoriesToggled: {},
        }
    },
    computed: {
        ...mapState({
            stepsMap: state => state.benefitsCategoriesStepsMap,
            categoryImgSrcsMap: state => state.benefitsCategoriesImgsSrcsMap,
        }),
        ...mapGetters(['providers']),
        planColumnClassFull () {
            return `col-${12 / this.plansList.length}`
        },
        planColumnClass () {
            if (this.plansList.length === 1) {
                return 'col-8'
            }
            if (this.plansList.length === 2) {
                return 'col-4'
            }
            if (this.plansList.length === 3) {
                return 'col-3'
            }
            return 'col'
        },
        planColumnOffset () {
            if (this.plansList.length === 3) {
                return '3'
            }
            return '4'
        },
        multiple () {
            return this.plansList.length > 1
        },
        plansList () {
            return _.uniqBy([].concat(this.plans).concat(this.plan).filter(p => p), 'key')
        },
        plansTypes () {
            return this.plansList.map(p => p.plan_type)
        },
        allPdps () {
            return _.every(this.plansTypes, t => t === 'pdp')
        },
        pkey () {
            return `${this.plansList.map(p => p.id).join('_')}`
        },
        plan0 () {
            return _.maxBy(this.plansList, p => p?.services_list?.length || 0) || this.plansList[0]
        },
        planRx () {
            return _.maxBy(this.plansList, p => p?.drug_cost_by_pharmacy_list?.length || 0) || this.plan0
        },
        categoriesList () {
            /* eslint-disable */
            let categoriesList = []
            this.plansList.forEach((plan) => {
                categoriesList = [].concat(categoriesList).concat(_.cloneDeep(plan.categories_list))
            })
            categoriesList = _.uniqBy(categoriesList, 'name').filter(c => c)

            this.plansList.forEach((plan) => {
                categoriesList.forEach((category) => {
                    const planCategory = _.find(plan.categories_list, pc => pc.name === category.name)
                    if (planCategory) {
                        category.services_list = _.uniqBy([].concat(category.services_list).concat(_.cloneDeep(planCategory.services_list)), 'key')
                    }
                })
            })
            return categoriesList
        },
        categoriesList0 () {
            return this.categoriesList.filter(category => category.name !== 'GENERAL')
        },
        categoryGeneral () {
            return this.categoriesList.filter(category => category.name === 'GENERAL')[0]
        },
        pharmacyCosts0 () {
            return this.planRx ? this.planRx.drug_cost_by_pharmacy_list : []
        },
        aPlanHasDrugs () {
            return !!this.pharmacyCosts0?.length
        },
        tiers0 () {
            return (_.find(this.plansList, p => p.drug_tiers && p.drug_tiers.tiers && Object.keys(p.drug_tiers.tiers).length) || {}).drug_tiers?.tiers
        },
        drugInfo0 () {
            return (_.find(this.plansList, p => p.drug_info && Object.keys(p.drug_info).length) || {}).drug_info
        },
        pharmacyEstimatedMonthlyCostMaxValue () {
            // i know, i know, f*ck that
            let max = 0
            _.each(this.plansList, (plan) => {
                _.each(plan.drug_cost_by_pharmacy, (ph) => {
                    const costs = ph.estimated_monthly_costs
                    _.each(costs, (obj) => {
                        // todo: @luke should I sum all of these up? or basically gap + initial + catastrophic?
                        const sum = _.sumBy(_.values(obj), v => (_.isNumber(v) ? v : 0))
                        max = Math.max(max, sum)
                    })
                })
            })
            return max
        },
        scrollContainer () {
            return this.inModal ? '.vuedals' : 'body'
        },
        categoriesNames() {
            return _.uniq([].concat(Object.keys(this.categoryImgSrcsMap)).concat(this.categoriesList0.map(c => c.name)))
        },
        accordionCategoriesName() {
            return this.plansList.map(p => p.key).join('_')
        },
    },
    watch: {
        collapseCategories () {
            this.resetCategoriesToggle ()
        },
        collapsibleVisible () {
            this.resetCategoriesToggle()
        },
    },
    created () {
        this.resetCategoriesToggle()
    },
    mounted () {
        this.setGlobal('$plansTable', this)
    },
    updated () {
        this.$emit('updated')
    },
    methods: {
        onWaypoint ({ going }) {
            const els = Array.from(this.$el.querySelectorAll('.sticky-element'))
            if (!els?.length) {
                return
            }
            const common = ['sticky-top']

            if (going === 'in') {
                els.forEach(el => {
                    const classes = [].concat(common).concat((el.getAttribute('data-sticky-classes') || '').split(',') || []).filter(c => c)
                    classes.forEach(c => el.classList.remove(c))
                })
            } else {
                els.forEach(el => {
                    const classes = [].concat(common).concat((el.getAttribute('data-sticky-classes') || '').split(',') || []).filter(c => c)
                    classes.forEach(c => el.classList.add(c))
                })
            }
        },

        isEqual (keypath) {
            const properties = this.plansList.map((plan) => {
                const v = _.get(plan, keypath) || _.get(plan.benefits, keypath)
                return _.isObject(v) ? _.pick(v, [
                    'cost_share', 'flags',
                    'mail_30', 'mail_90', 'network_30', 'network_90',
                ]) : v
            })
            return properties.every(property => _.isEqual(property, properties[0]))
        },
        propertyEqualityClass (keypath) {
            return `property--${keypath} ${this.isEqual(keypath) ? 'property--eq' : 'property--neq'}`
        },
        categoryImgSrc (categoryName) {
            /* eslint-disable */
            return require(`~/assets/images/external-icons/${this.categoryImgSrcsMap[categoryName] || this.categoryImgSrcsMap.FALLBACK}`)
        },
        scrollToCategory (categoryName) {
            const el = this.$el.querySelector(`#category__${categoryName}__${this.pkey}`)
            el.scrollIntoView({ behavior: 'smooth' })
            this.$nextTick(() => {
                if (this.collapseCategories) {
                    this.$set(this.categoriesToggled, categoryName, !this.categoriesToggled[categoryName])
                }
            })
        },
        resetCategoriesToggle () {
            this.categoriesNames.forEach(name => {
                this.$set(this.categoriesToggled, name, !this.collapseCategories || this.collapsibleVisible)
            })
        },
        goBack( ) {
            this.$emit('back')
            if (!this.inModal) {
                this.$router.back()
            }
        },
    },
}
