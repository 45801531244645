
import { address } from '~/util/string'

export default {
    props: {
        model: {
            type: Object,
            default: null
        }
    },
    computed: {
        address () {
            return address(this.model)
        },
    },
}
