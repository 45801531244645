import { render, staticRenderFns } from "./Summary.vue?vue&type=template&id=9d9bfdda&lang=pug&"
import script from "./Summary.vue?vue&type=script&lang=js&"
export * from "./Summary.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Summary: require('/codebuild/output/src60861037/src/frontend/components/Summary.vue').default})
