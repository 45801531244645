
const seconds = 5
export default {
    props: {
        redirect: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return { timeout: null, interval: null, seconds }
    },
    mounted () {
        if (this.redirect && !/editor/.test(this.$route.path)) {
            this.timeout = setTimeout(() => {
                this.$router.push('/schedule')
            }, seconds * 1000)
            this.interval = setInterval(() => {
                this.seconds -= 1
            }, 1000)
        }
    },
    beforeUnmout () {
        clearTimeout(this.timeout)
        clearInterval(this.interval)
    }
}
