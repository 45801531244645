import { mapGetters, mapState } from 'vuex'
import _ from 'lodash'
import GuidanceMixin from '~/mixins/guidance'

export default {
    mixins: [GuidanceMixin],
    props: {
        name: {
            type: String,
            default: ''
        },
        html: {
            type: String,
            default: null,
        },
        type: {
            type: String,
            default: 'default'
        },
        title: {
            type: String,
            default: null
        },
        subtitle: {
            type: String,
            default: null
        },
        description: {
            type: String,
            default: null
        },
        magicUrl: {
            type: String,
            default: '/'
        },
        mode: {
            type: [String, Object],
            default: 'default'
        },
        aep: {
            type: Boolean,
            default: null
        },
        index: {
            type: Number,
            default: null
        },
        comboPlans: {
            type: Array,
            default () { return [] }
        },
        comboIndex: {
            type: Number,
            default: null
        },
    },
    computed: {
        ...mapState({
            summary: state => state.summary
        }),
        ...mapGetters([
            'survey',
            'client',
            'agent',
            'summary',
            'year',
            'yearMinus2',
            'irmaaB',
            'irmaaD',
            'getProjectedIrmaaB',
            'getProjectedIrmaaD',
            'irmaaD',
            'isAEP',
            'income',
            'projectedIncome',
            'summaryPlansMap',
            'summaryPlansList',
            'summaryCombosPlans',
            'summaryUserGroupPlansMap',
            'summaryUserGroupPlansList',
            'getSummaryComboPlanIdsByCard',
            'getSummaryComboPlansByCard',
            'partAPremium',
            'partADeductible',
            'partAPremiumLt30',
            'partAPremiumGte30Lte39',
            'partAPremiumGt40',
            'partBPremium',
            'partBDeductible',
            'providersMap',
            'pharmaciesMap',
            'packagesMap',
            'isProviderInNetwork',
            'isProviderOutNetwork',
        ]),
        card_config () {
            return _.get(this.summary, `data.cards.${this.name}.config`) || {}
        },
        card_config_value () {
            return this.card_config?.value
        },
        card_config_switch () {
            return this.card_config_value?.plan && this.card_config_value?.plan === this.card_config_value?.plan2
        },
        plan () {
            return this.plans_map[this.card_config_value?.plan]
        },
        plan1 () {
            return this.plans_map[this.card_config_value?.plan1]
        },
        plan2 () {
            return this.plans_map[this.card_config_value?.plan2]
        },
        first_mapd () {
            return _.find(this.plans_list, p => p.type === 'mapd' || p.type === 'ma')
        },
        first_supp () {
            return _.find(this.plans_list, p => p.type === 'supp')
        },
        first_pdp () {
            return _.find(this.plans_list, p => p.type === 'pdp')
        },
        mapd () {
            return this.plans_map[this.card_config_value?.mapd] || this.first_mapd
        },
        supp () {
            return this.plans_map[this.card_config_value?.supp] || this.first_supp
        },
        pdp () {
            return this.plans_map[this.card_config_value?.pdp] || this.first_pdp
        },
        selected_plans () {
            return [this.supp, this.pdp, this.mapd].filter(plan => !!plan)
        },
        plans () {
            return this.plans_list
        },
        plans_map () {
            return this.summaryPlansMap
        },
        plans_list () {
            return this.summaryPlansList
        },
        pharmacy0 () {
            const p0 = _.find(this.plans_list, p => p.cheapest_pharmacy)
            return p0?.cheapest_pharmacy
        },
        single () {
            return this.dplans.length === 1
        },
        multiple () {
            return this.dplans.length > 1
        },
        dplans () {
            return [].concat(this.comboPlans).filter(p => p)
        },
        planName () {
            return this.single ? this.dplan0?.name : this.dplans.map(p => p.name).join(' + ')
        },
        planDeductible () {
            return this.single ? this.dplan0?.deductible : this.dplans.filter(p => !_.isNil(p?.deductible)).map(p => p.deductible).join(' + ')
        },
        planMonthlyPremium () {
            return _.sum(this.dplans.map(p => p.monthly_premium))
        },
        planPharmacy0 () {
            return this.single ? this.dplan0.cheapest_pharmacy : this.pdpPlan?.cheapest_pharmacy
        },
        planDrugDeductible () {
            return this.single ? this.dplan0?.drug_deductible || this.dplan0?.cheapest_pharmacy?.phase_information?.deductible_amount : this.pdpPlan?.drug_deductible || this.pdpPlan?.cheapest_pharmacy?.phase_information?.deductible_amount
        },
        planDrugDeductibleNb () {
            return _.isNumber(this.planDrugDeductible) ? this.planDrugDeductible : this.planDrugDeductible ? parseInt(this.planDrugDeductible, 10) : 0
        },
        deductibleDrugs () {
            return this.planPharmacy0?.drug_costs?.length ? this.planPharmacy0?.drug_costs.filter(dc => dc.does_count_towards_deductible) : []
        },
        deductibleStart () {
            return this.single ? this.dplan0?.cheapest_pharmacy?.phase_information?.initial_coverage_start : this.pdpPlan?.cheapest_pharmacy?.phase_information?.initial_coverage_start
        },
        gapStart () {
            return this.single ? this.dplan0?.cheapest_pharmacy?.phase_information?.gap_start : this.pdpPlan?.cheapest_pharmacy?.phase_information?.gap_start
        },
        catastrophicStart () {
            return this.single ? this.dplan0?.cheapest_pharmacy?.phase_information?.catastrophic_start : this.pdpPlan?.cheapest_pharmacy?.phase_information?.catastrophic_start
        },
        maximumOopc () {
            return this.dplan0?.maximum_oopc_in_network || this.dplan0?.maximum_oopc_cost_share
        },
        planOrCombo () {
            return this.single ? 'plan' : 'combination'
        },
        planType () {
            return this.isPlan0Mapd ? 'mapd' : this.multiple ? 'supp_pdp' : this.dplan0?.plan_type
        },
        planTypePlan () {
            return this.isPlan0Mapd ? 'Medicare advantage plan' : 'Medicare supplement plan + Prescription drug plan'
        },
        isPlan0Mapd () {
            return this.dplan0?.plan_type === 'mapd' || this.dplan0?.plan_type === 'ma'
        },
        dplan0 () {
            return this.dplans[0]
        },
        dplan1 () {
            return this.dplans[1]
        },
        suppPlan () {
            return _.find(this.dplans, p => p.plan_type === 'supp')
        },
        mapdPlan () {
            return _.find(this.dplans, p => p.plan_type === 'mapd' || p.plan_type === 'ma')
        },
        pdpPlan () {
            return _.find(this.dplans, p => p.plan_type === 'pdp')
        },
        suppOrMapd () {
            return this.suppPlan || this.mapdPlan
        },
        pdpOrMapd () {
            return this.pdpPlan || this.mapdPlan
        },
        planTypeLabel () {
            return this.dplans.map(p => p.plan_type_label).join(' + ')
        },
        premiumsTotal () {
            const vals = [this.part_a_premium, this.part_b_premium, this.part_b_irmaa]
            // if (!this.tricare_or_champva) {
            vals.push(this.part_d_irmaa)
            // }
            let sum = _.sum(vals)
            // if (!this.tricare_or_champva) {
            sum += this.planMonthlyPremium
            // }
            // sum += this.estimatedDrugsTotal
            return sum
        },
        projectedPremiumsTotal () {
            const vals = [this.part_a_premium, this.part_b_premium, this.projected_part_b_irmaa]
            // if (!this.tricare_or_champva) {
            vals.push(this.projected_part_d_irmaa)
            // }
            let sum = _.sum(vals)
            // if (!this.tricare_or_champva) {
            sum += this.planMonthlyPremium
            // }
            // sum += this.estimatedDrugsTotal
            return sum
        },
        estimatedDrugsTotal () {
            return _.sum(this.dplans.map(plan => (_.get(plan, 'drug_cost_by_pharmacy_list.0.estimated_yearly_total') || 0) / 12)) || 0
        },
        yearlyPremiumsTotal () {
            return this.premiumsTotal * 12
        },
        anySurcharge () {
            return !!(this.part_b_irmaa || this.part_d_irmaa)
        },
        clientCompiledHtml () {
            return null // this.html && !this.$store.getters.isServer ? this.$root.vueTemplateToHtml(this.html) : null
        }
    },
    watch: {
        html () {
            return this.compileHtml()
        }
    },
    data () {
        return { compiledHtml: null }
    },
    async fetch () {
        await this.compileHtml()
    },
    methods: {
        async compileHtml () {
            if (this.html) {
                if (this.$store.getters.isServer) {
                    this.compiledHtml = await this.$root.vueTemplateToHtmlAsync(this.html)
                } else {
                    this.compiledHtml = this.$root.vueTemplateToHtml(this.html)
                }
                return this.compiledHtml
            }
        },
        presentQuestion () {}
    }
}
