
import _ from 'lodash'

export default {
    props: {
        value: {
            type: [String, Number],
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: 'Select Employer Plan',
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        searchable: {
            type: Boolean,
            default: true,
        },
        internalSearch: {
            type: Boolean,
            default: false,
        },
        showNoOptions: {
            type: Boolean,
            default: false,
        },
        allowEmpty: {
            type: Boolean,
            default: true,
        },
        helpText: {
            type: String,
            default: null,
        },
        searchParams: {
            type: Object,
            default: () => ({}),
        }
    },
    data () {
        return {
            selectableOptions: [],
            userGroupPlanValue: null,
        }
    },
    async fetch () {
        const query = this.query || ''
        const params = { ...this.searchParams, query, user: this.$store.getters.client?.id, group: this.$store.getters.client?.group_id }
        const { items } = await this.$api.searchUserGroupPlans(params)
        this.selectableOptions = items || []
    },
    watch: {
        value () {
            if (!_.isEqual(parseInt(this.value), this.userGroupPlanValue?.id)) {
                this.updateValue()
            }
        },
        searchParams: {
            deep: true,
            handler () {
                this.$fetch()
            }
        },
        userGroupPlanValue () {
            this.emitInput()
        },
    },
    mounted () {
        this.updateValue()
    },
    methods: {
        async updateValue () {
            if (this.value) {
                const userGroupPlan = await this.$api.getUserGroupPlan(this.value)
                this.userGroupPlanValue = userGroupPlan
            } else {
                this.userGroupPlanValue = null
            }
        },
        emitInput () {
            this.$emit('input', this.userGroupPlanValue?.id || null)
            this.$emit('input-raw', this.userGroupPlanValue || null)
        },
        search (query) {
            this.query = query
            return this.$fetch()
        },
    },
}
