
export default {
    computed: {
        edgeEditable: {
            get () {
                return this.$store.getters.edge
            },
            async set (v) {
                await this.$try(v ? 'onEdge' : 'offEdge')
                this.$store.commit('edge', v)
            },
        }
    }
}
