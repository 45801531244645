
import { mapActions, mapGetters } from 'vuex'

export default {
    props: {
        nonav: {
            type: Boolean,
            default: false
        },
        groupLanding: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            justSentEmail: false,
            activeCallModal: false,
            quickEditModal: false,
            medicareResourcesItems: false,
        }
    },
    computed: {
        ...mapGetters([
            'crmBase',
            'clients',
            'client',
            'crmClient',
            'survey',
            'surveyRoutes',
            'surveyRoutesNavItems',
            'isSurveyRoute',
            'activeRoute',
            'activeRoutePath',
            'years',
            'year',
            'isDonutHoleYear',
            'location'
        ]),
        lightHeader () {
            return this.lightTheme || this.groupLanding
        },
        onAEPPage () {
            return this.$route.path === '/survey/aep'
        },
        onSchedulePage () {
            return this.$route.path === '/schedule'
        },
    },
    methods: {
        ...mapActions(['logout', 'sendVerifyEmail']),
        presentQuickEditModal () {
            this.quickEditModal = true
        },
        async deactivate () {
            const value = await this.$bvModal.msgBoxConfirm('Salesforce will only hold the deactivated clients for 15 days, after that, you cannot re-activate them.', {
                title: 'Are you sure you want to deactivate this client?',
                okVariant: 'danger',
                okTitle: 'Yes, I am sure',
                cancelTitle: 'No',
                footerClass: 'p-2',
                centered: true
            })
            if (value) {
                const { error } = await this.$try('deactivateClient', this.survey.id, { force: !this.crmClient })
                if (!error) {
                    location.reload()
                }
            }
        },
        async reactivate () {
            const value = await this.$bvModal.msgBoxConfirm('This is not guaranteed to succeed, depending on if salesforce still allows us to re-activate', {
                title: 'Are you sure you want to reactivate this client?',
                okVariant: 'danger',
                okTitle: 'Yes, I am sure',
                cancelTitle: 'No',
                footerClass: 'p-2',
                centered: true
            })
            if (value) {
                const { error } = await this.$try('reactivateClient', this.survey.id)
                if (!error) {
                    location.reload()
                }
            }
        },
        setYear (year) {
            this.$routerx.replaceQuery({ year })
            this.$store.commit('year', year)
        }
    }
}
