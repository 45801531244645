
import { resolveType } from '~/util/string'

export default {
    props: {
        value: {
            type: [Boolean]
        }
    },
    data () {
        return { checked: !!this.value }
    },
    watch: {
        value (newVal) {
            this.checked = !!newVal
        },
        checked (newVal) {
            this.$emit('input', resolveType(newVal))
        }
    }
}
