
import { mapGetters } from 'vuex'

export default {
    props: {
        plan: {
            type: Object,
            default: null,
        },
    },
    computed: {
        ...mapGetters([
            'partDGapEntrance',
            'partDGapBrandDrugPercent',
            'partDGapGenericDrugPercent',
            'partDCatastrophicEntrance',
            'partDCatastrophicCoveredDrugPercent',
            'partDCatastrophicGenericDrugCopay',
            'partDCatastrophicBrandDrugCopay',
            'isDonutHoleYear'
        ]),
        tiersDescriptions () {
            return [
                'Preferred Generic',
                'Generic',
                'Preferred Brand',
                'Non-Preferred Drug',
                'Specialty Tier',
            ]
        },
    },
}
