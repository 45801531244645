
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        syncQuery: {
            type: Boolean,
            default: false,
        },
        zipCode: {
            type: String,
            default: null,
        },
        isSurvey: {
            type: Boolean,
            default: false
        },
        showSelectedOnly: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            adding: null,
            results: [],
            originalResults: [],
            searching: null,
            searchForm: {
                query: this.$route?.query?.query,
                zip_code: this.zipCode || this.$route?.query?.zip_code,
            },
            sortByOptions: [
                { value: 'combos-last', text: 'Combinations last' },
                { value: 'alphabetical', text: 'Alphabetical' },
                { value: 'no', text: 'Original order' },
            ],
            filterForm: {
                sortBy: this.$route?.query?.sortBy || 'combos-last',
            },
        }
    },
    computed: {
        ...mapGetters(['frequencies', 'year', 'parseDrug', 'getDrugUniquishKey']),
        valid () {
            return this.searchForm.query?.length >= 3
        }
    },
    watch: {
        zipCode (newZipCode) {
            this.$set(this.searchForm, 'zip_code', newZipCode)
        },
        searchForm: {
            deep: true,
            handler: _.debounce(function (searchForm) {
                this.$nextTick(() => {
                    this.syncQuery && this.$routerx.replaceQuery(searchForm)
                    if (this.valid) {
                        return this.search()
                    }
                })
            }, 300)
        },
        filterForm: {
            deep: true,
            handler (filterForm) {
                this.syncQuery && this.$routerx.replaceQuery(filterForm)
                this.sort()
            }
        },
    },
    mounted () {
        if (this.valid) {
            return this.search()
        }
    },
    methods: {
        setZipCodeIfUnset (zipcode) {
            if (!this.searchForm.zip_code) {
                this.$set(this.searchForm, 'zip_code', zipcode)
                if (this.valid) this.search()
            }
        },
        async search () {
            if (this.searchForm.query) {
                this.searching = true
                const { results, error } = await this.$try('searchDrugs', { query: this.searchForm?.query, year: this.year }, { spinner: false })
                if (!error) {
                    this.originalResults = results
                    this.sort()
                }
                this.searching = false
            } else {
                this.$warning('Please type a drug partial or full name')
            }
        },
        sort () {
            let results = this.originalResults.slice(0)
            if (this.filterForm.sortBy === 'combos-last') {
                let count = 0
                results = _.sortBy(results.map(drg => this.parseDrug(drg)), (drg) => {
                    count++
                    if (drg.max_hyphens) {
                        return Number.MAX_SAFE_INTEGER - 1000 - results.length + drg.max_hyphens
                    }
                    return count
                })
            } else if (this.filterForm.sortBy === 'alphabetical') {
                results = _.sortBy(results.map(drg => this.parseDrug(drg)), (drg) => {
                    return drg.text
                })
            }
            this.results = results
        },
        addDrug (drug) {
            const drugs = this.value.slice(0)
            drugs.push(_.cloneDeep(drug))
            this.$emit('input', drugs)
        },
        findDrugIndex (drug) {
            return _.findIndex(this.value, d => _.isEqual(this.drugPick(d), this.drugPick(drug)))
        },
        isDrugInList (drug) {
            return this.findDrugIndex(drug) > -1
        },
        isDrugParentInList (drug, exact = true) {
            if (exact) {
                return _.findIndex(this.value, d => this.getDrugUniquishKey(d) === this.getDrugUniquishKey(drug)) > -1
            }
            return _.findIndex(this.value, d => d.rxcui === drug?.rxcui) > -1
        },
        isDrugGenericInList (drug, exact = true) {
            if (exact) {
                return _.findIndex(this.value, d => this.getDrugUniquishKey(d) === this.getDrugUniquishKey(drug?.generic)) > -1
            }
            return _.findIndex(this.value, d => d.rxcui === drug?.generic?.rxcui) > -1
        },
        removeDrug (index, drug) {
            // let index = this.findDrugIndex(drug)
            console.log({ index })
            if (index > -1) {
                const drugs = this.value.slice(0)
                drugs.splice(index, 1)
                this.$emit('input', drugs)
            }
        },
        excludeDrug (index, drug) {
            drug.excluded = true
            this.updateDrug(index, drug)
        },
        includeDrug (index, drug) {
            drug.excluded = false
            this.updateDrug(index, drug)
        },
        updateDrug (index, newValue) {
            const drugs = this.value.slice(0)
            drugs[index] = newValue
            this.$emit('input', drugs)
        },
        toggleDrug (drug) {
            if (this.isDrugInList(drug)) {
                return this.removeDrug(drug)
            } else {
                return this.addDrug(drug)
            }
        },
        drugPick (drug) {
            const drg = {}
            const props = ['rxcui', 'package.ndc', 'dosage.rxcui', 'quantity', 'frequency']
            props.forEach((prop) => {
                _.set(drg, prop, _.get(drug, prop))
            })
            return drg
        },
    },
}
