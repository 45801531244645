
import { mapGetters } from 'vuex'

import card from '~/mixins/card'
export default {
    mixins: [card],
    props: {
        magicUrl: {
            type: String,
            default: '/'
        },
    },
    data () {
        return {}
    },
    computed: {
        ...mapGetters([
            'uid',
            'year',
            'surveyRoutesMap',
            'isPlanInOpportunities',
        ]),
    },
    methods: {
        async keepPlans () {
            const value = await this.$bvModal.msgBoxConfirm(' ', {
                title: `Confirm you would like to keep your current coverage for ${this.year}?`,
                okVariant: 'primary',
                okTitle: 'Yes, confirm',
                cancelTitle: 'No, go back',
                footerClass: 'p-2',
                centered: true
            })
            if (value) {
                const { error } = await this.$try(this.canAgent ? 'agentKeepPlans' : 'keepPlans', this.canAgent ? { user: this.uid } : {})
                if (!error) {
                    this.$alert({ message: 'Thank you for letting us know!', variant: 'success' })
                }
            }
        },
    }
}
