
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { hashCode } from '@/util/string'

export default {
    props: {
        script: {
            type: Object,
            default: null
        }
    },
    data () {
        return {
            search: '',
            filteredVariables: [],
            editor: null,
            disabledSelect: false,
            showVariables: false,
            type: 'summary-scripts',
            showPreview: false,
            clientId: null,
            isLoading: false,
            form: {
                subtype: null,
                title: null,
                html: '',
            }
        }
    },
    computed: {
        ...mapGetters(['surveyRoutes', 'survey', 'variablesOptions']),
        isPreviewDisabled () {
            return !this?.survey && !this.clientId
        },
        renderedHtml () {
            return this.$root.vueTemplateToHtml(this.form.html)
        },
        renderedHtmlHash () {
            return hashCode(this.renderedHtml)
        },
    },
    watch: {
        async clientId () {
            if (this.clientId) {
                this.isLoading = true
                await this.$store.dispatch('getSurvey', { id: this.clientId })
                this.isLoading = false
            }
        },
        search () {
            if (this.search.length) {
                const pattern = new RegExp(this.search, 'i')
                this.filteredVariables = this.variablesOptions.filter(item => pattern.test(item.label))
            }
        }
    },
    mounted () {
        this.updateForm()
        if (this?.survey?.id) {
            this.clientId = this.survey.id
        }
    },
    methods: {
        updateForm () {
            if (this.script) {
                this.form = _.cloneDeep(this.script)
                this.disabledSelect = true
            }
        },
        validate () {
            return this.$validator.validate()
        },
        async save () {
            const valid = await this.validate()
            if (!valid) {
                return this.$store.$alert(this.$validator.errors.all().join('\n'))
            }

            const { error } = await this.$try(
                'setItem',
                this.form.subtype,
                this.form,
                { type: this.type, subtype: this.form.subtype },
            )
            if (error) {
                return this.$emit('error', error)
            }
            this.$emit('refresh')
            this.$emit('close')
        }
    }
}
