
import card from '~/mixins/card'
export default {
    mixins: [card],
    computed: {
        formattedDate () {
            const date = new Date()
            const options = {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            }
            return date.toLocaleDateString('en-US', options)
        }
    }
}
