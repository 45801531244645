
import { mapGetters } from 'vuex'
export default {
    async fetch () {
        if (this.uid) {
            const { clients } = await this.$try('getMatchingClients', this.uid, { spinner: false })
            this.$store.commit('matchingClients', clients)
        }
    },
    computed: {
        ...mapGetters(['client', 'matchingClients', 'uid']),
        firstN () {
            return this.matchingClients.slice(0, 5)
        },
        rest () {
            return this.matchingClients.slice(5)
        },
        inactive () {
            return this.matchingClients.filter(client => !client.active)
        }
    },
}
