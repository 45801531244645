
import _ from 'lodash'
import moment from 'moment'
import { mapGetters } from 'vuex'
import { lengthInUtf8Bytes, humanFileSize } from '~/util/string'

export default {
    middleware: ['canAgent'],
    props: {
        params: {
            type: Object,
            default: () => ({})
        },
    },
    data () {
        return {
            loading: false,
            tableFields: [
                { key: 'id', sortable: false },
                { key: 'activity', sortable: false },
                { key: 'status', sortable: false },
                { key: 'user_id', label: 'User', sortable: false },
                { key: 'client_id', label: 'Client', sortable: false },
                { key: 'context', sortable: false },
                { key: 'date', sortable: false },
                { key: 'ip_address', sortable: false },
            ],
            items: [],
            total: 0,
            form: {
                query: this.$route.query.query || '',
                strict: this.$route.query.strict === 'true',
                activity: this.$route.query.activity || null,
                summary_id: parseInt(this.$route.query.summary_id) || null,
                start_date: this.$route.query.start_date || moment().subtract(90, 'days').format('YYYY-MM-DD'),
                end_date: this.$route.query.end_date || moment().format('YYYY-MM-DD'),
                page: parseInt(this.$route.query.page) || 1,
                size: parseInt(this.$route.query.size) || 10
            },
            itemModal: false,
            presentingItem: null,
        }
    },
    fetch () {
        return this.search()
    },
    computed: {
        ...mapGetters([
            'uid',
            'activityChoices',
            'summaries',
        ]),
    },
    watch: {
        form: {
            deep: true,
            handler: _.debounce(function (form) {
                this.search({ page: form.page || 1 })
            }, 300)
        },
    },
    created () {
        _.forEach(this.params || {}, (v, k) => {
            if (!this.$route.query[k] && !_.isNil(v)) {
                this.$set(this.form, k, v)
            }
        })
    },
    mounted () {
        this.setGlobal('$activityReport', this)
    },
    methods: {
        humanFileSize,
        lengthInUtf8Bytes,
        async search (params = {}) {
            this.loading = true
            params = { ...this.form, ...params }
            if (!/_summary$/.test(params.activity)) {
                delete params.summary_id
            }
            const { items, total, page, size } = await this.$try('getActivity', params, { spinner: false })
            let qsParams = { ...params, page, size }
            if (this.clientId) {
                qsParams = _.omit(qsParams, ['client_id'])
            }
            this.$routerx.replaceQuery(qsParams)
            this.loading = false
            this.items = items || []
            this.total = total || 0
            this.form.page = page
            this.form.size = size
            this.$emit('total', this.total)
        },
    }
}
