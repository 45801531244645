
import { mapGetters } from 'vuex'
export default {
    async fetch () {
        if (this.uid) {
            const resp = await this.$try('getRelatedClients', this.uid, { spinner: false })
            if (resp?.clients) {
                this.$store.commit('clients', resp?.clients)
            }
        }
    },
    computed: {
        ...mapGetters(['client', 'clients', 'uid'])
    },
}
