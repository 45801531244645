
export default {
    props: {
        editor: {
            type: Object,
            default: null,
        },
        items: {
            type: Array,
            default: () => [],
        },
        command: {
            type: Function,
            default: () => {},
        },
        query: {
            type: String,
            default: ''
        },
        range: {
            type: Object,
            default: () => {},
        }
    },
    data () {
        return {
            selectedIndex: 0,
        }
    },
    watch: {
        items () {
            this.selectedIndex = 0
        }
    },
    methods: {
        onKeyDown ({ event }) {
            if (event.key === 'ArrowUp') {
                this.upHandler()
                return true
            }

            if (event.key === 'ArrowDown') {
                this.downHandler()
                return true
            }

            if (event.key === 'Enter') {
                this.enterHandler()
                return true
            }

            return false
        },

        upHandler () {
            this.selectedIndex = ((this.selectedIndex + this.items.length) - 1) % this.items.length
        },

        downHandler () {
            this.selectedIndex = (this.selectedIndex + 1) % this.items.length
        },

        enterHandler () {
            this.selectItem(this.selectedIndex)
        },

        selectItem (index) {
            const item = this.items[index]
            if (item) {
                this.command({ id: item.value, label: item.label })
            }
        },

    },
}
