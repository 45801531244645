
import _ from 'lodash'
import surveyForm from '~/mixins/survey-form'

export default {
    mixins: [surveyForm],
    autosave: true,
    data () {
        return {
            form: {
                checklist: [],
            },
            options: [],
        }
    },
    computed: {
        parents () {
            const parents = this.options.filter(option => !option.parent).map(option => ({ ...option, children: [] }))
            parents.forEach((parent) => {
                this.options.forEach((child) => {
                    if (child.parent === parent.value) {
                        parent.children.push(child)
                    }
                })
            })
            return parents.filter(parent => parent.children.length)
        },
        parentsMap () {
            return _.keyBy(this.parents, 'value')
        },
        children () {
            return this.parents.map(parent => parent.children).reduce((all, subset) => all.concat(subset), [])
        },
        childrenMap () {
            return _.keyBy(this.children, 'value')
        },
    },
    async created () {
        const list = await this.$content('consultation-checklist').fetch()
        this.options = list?.body || []
    },
    methods: {
        areChildrenChecked (option) {
            const parent = this.parentsMap[option.value]
            return parent && parent.children.every(child => _.findIndex(this.form.checklist, v => v === child.value) > -1)
        },
        isOptionChecked (option) {
            return _.findIndex(this.form.checklist, v => v === option.value) > -1
        },
        onOption (option, $event) {
            const checked = $event
            const checklist = _.cloneDeep(this.form.checklist)
            const index = _.findIndex(checklist, v => v === option.value)
            if (checked && index === -1) {
                checklist.push(option.value)
            } else if (index > -1) {
                checklist.splice(index, 1)
            }
            this.$set(this.form, 'checklist', checklist)
        },
        onParent (option, $event) {
            const parent = this.parentsMap[option.value]
            const checked = $event
            const checklist = _.cloneDeep(this.form.checklist)
            if (checked) {
                parent.children.forEach((option) => {
                    const index = _.findIndex(checklist, v => v === option.value)
                    if (index === -1) {
                        checklist.push(option.value)
                    }
                })
            } else {
                parent.children.forEach((option) => {
                    const index = _.findIndex(checklist, v => v === option.value)
                    if (index > -1) {
                        checklist.splice(index, 1)
                    }
                })
            }
            this.$set(this.form, 'checklist', checklist)
        }
    }
}
