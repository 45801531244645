
import { mapGetters } from 'vuex'

export default {
    props: {
        client: {
            type: Object,
            default: null
        },
    },
    data () {
        return {
            opportunities: [],
            invalidOpportunities: []
        }
    },
    async fetch () {
        const resp = await this.$api.getOpportunities(this.client?.id)
        if (resp) {
            this.opportunities = resp?.opportunities || []
            this.invalidOpportunities = resp?.invalid_opportunities || []
        }
    },
    computed: {
        ...mapGetters(['crmBase'])
    },
}
