import moment from 'moment'

export const do65FromDob = dob => moment.utc(dob).clone().add(65, 'years').startOf('day')

export const ageAt = (dob, date, options = { fraction: true, startOf: 'month', toFixed: null }) => {
    const m0 = moment.utc(dob)
    const m1 = moment.utc(date)
    if (options.startOf) {
        m0.startOf(options.startOf)
    }
    const age = m1.diff(m0, 'years', options.fraction)
    return options.toFixed ? parseFloat(age.toFixed(options.toFixed)) : age
}

export const ageFromDob = (dob, options) => ageAt(dob, undefined, options)

export const dobFromAge = age => moment.utc().subtract(age, 'years').startOf('day')

export const dobFromDo65 = do65 => moment.utc(do65).clone().subtract(65, 'years').startOf('day')

export const ageFromDo65 = (do65, fraction = false) => moment.utc().diff(dobFromDo65(do65), 'years', fraction)

export const fromDob = (value) => {
    if (value === null) {
        return ['age', 'do65', 'mo65', 'eq64', 't65', 'turning_65', 'eq65', 'gte65', 'lt62', 'lte62', 'lt65', 'too_early'].reduce((h, k) => {
            h[k] = null
            return h
        }, {})
    }
    const dob = moment(value)
    const today = moment()
    const birthday = dob.clone().set({ year: today.year() })
    const eoy = birthday.clone().endOf('year')
    const age = value ? ageFromDob(value, { startOf: 'month', fraction: true, toFixed: 2 }) : undefined
    const eq64 = Math.floor(age) === 64
    const do65 = do65FromDob(dob)
    const t65 = eq64 && birthday.isBetween(today, eoy)

    return {
        dob,
        age,
        do65,
        eq64,
        t65,
        turning_65: t65,
        mo65: do65.clone().startOf('month'),
        mo65_medicare: do65.date() === 1 ? do65.clone().subtract(1, 'month').startOf('month') : do65.clone().startOf('month'),
        eq65: Math.floor(age) === 65,
        gte65: age >= 65,
        lte62: age <= 62,
        lt62: age < 62,
        lt65: age < 65,
    }
}

export const calcCoverage = ({
    /* eslint-disable */
    /* eslint-disable camelcase */
    hsa,
    dob,
    has_part_a,
    part_a_date,
    has_part_b,
    part_b_date,
    collecting_ss,
    employer_coverage,
    employer_coverage_p65,
    pre_medicare_end_date,
    disabled,
    disabled_date,
}) => {
    if (!dob) {
        return
    }
    const age = ageFromDob(dob)
    const do65 = do65FromDob(dob)
    const first = do65.date() === 1
    const coverage65 = first ? do65.clone().subtract(1, 'month').startOf('month') : do65.clone().startOf('month')
    const coverage65m3 = coverage65.clone().subtract(3, 'month')
    const today = moment()
    const eoy = today.clone().endOf('year')
    const this_year = today.year()

    let coverage_a_date
    let coverage_b_date
    let info = null
    let warning = first ? 'Since you were born on the 1st of the month, your coverage will start the month before.' : null
    let danger = null

    let skip_disability = false

    if (age >= 65) {
        if (has_part_a && has_part_b) {
            coverage_a_date = moment(part_a_date)
            coverage_b_date = moment(part_b_date)
            info = 'No need to do anything for A & B'
            return {
                coverage_a_date: coverage_a_date,
                coverage_b_date: coverage_b_date,
                danger,
                warning,
                info
            }
        } else {
            if (employer_coverage) {
                skip_disability = true
            } else {
                coverage_a_date = today.clone()
                coverage_b_date = moment(`07/01/${this_year}`)
                if (today.isBetween(coverage_b_date, eoy)) {
                    coverage_b_date.add(1, 'year')
                    danger = `Problem! Client need to sign up for A now, and sign for B on 7/1 of NEXT YEAR! since 7/1 has already passed this year`
                } else {
                    danger = `Problem! Client need to sign up for A now, and sign for B on 7/1 this year!`
                }
                return {
                    coverage_a_date,
                    coverage_b_date,
                    danger,
                    info
                }
            }
        }
    }
    if (!skip_disability && disabled && disabled_date) {
        const coverage_disabled = moment(disabled_date).add(24, 'month').add(1, 'month').startOf('month')
        info = 'You start date is 25th months after you\'ve qualified for disability, ESRD or ALS'
        return {
            coverage_a_date: coverage_disabled,
            coverage_b_date: coverage_disabled,
            info
        }
    }
    if (employer_coverage) {
        if (employer_coverage_p65 && pre_medicare_end_date) {
            coverage_b_date = moment(pre_medicare_end_date).add(1, 'months').startOf('month')
            if (hsa) {
                coverage_a_date = moment(pre_medicare_end_date).subtract(7, 'months')
                if (do65.isBetween(coverage_a_date, coverage_b_date)) {
                    coverage_a_date = coverage65
                }
                if (collecting_ss) {
                    info = `You wil already have Part A. Sign up for Part B the month after you leave work`
                } else {
                    info = `Need to sign up for Part A one month prior to employer coverage ending`
                }
                return {
                    coverage_a_date,
                    coverage_b_date,
                    info,
                }
            } else {
                // no hsa
                if (collecting_ss) {
                    return {
                        coverage_a_date: coverage65,
                        coverage_b_date: coverage_b_date,
                        info,
                        warning
                    }
                } else {
                    info = `Need to sign up for Part A within 3 month of turning 65, the month of ${coverage65m3.format('MM/YYYY')}`
                    // no collecting_ss
                    return {
                        coverage_a_date: coverage65,
                        coverage_b_date: coverage_b_date,
                        info,
                    }
                }
            }
        } else {
            // no employer coverage past 65
            if (hsa) {
                if (collecting_ss) {
                    info = `Stop contributing to HSA the month you turn 65, ${coverage65.format('MM/DD/YYYY')}`
                    return {
                        coverage_a_date: coverage65,
                        coverage_b_date: coverage65,
                        info,
                        warning
                    }
                } else {
                    // no collecting_ss
                    info = `Need to sign up for Part A within 3 month of turning 65, the month of ${coverage65m3.format('MM/YYYY')}`
                    return {
                        coverage_a_date: coverage65,
                        coverage_b_date: coverage65,
                        info,
                        warning
                    }
                }
            } else {
                // no hsa
                if (collecting_ss) {
                    info = `You will automatically be signed up for Part A & B 3 months you turn 65, the month of ${coverage65m3.format('MM/YYYY')}`
                    return {
                        coverage_a_date: coverage65,
                        coverage_b_date: coverage65,
                        info,
                        warning
                    }
                } else {
                    // no collecting_ss
                    info = `Need to sign up for Part A within 3 month of turning 65, the month of ${coverage65m3.format('MM/YYYY')}`
                    return {
                        coverage_a_date: coverage65,
                        coverage_b_date: coverage65,
                        info,
                        warning
                    }
                }
            }
        }
    } else {
        // no employer coverage
        info = first ? `Part A & B will start on the first day of the month before you turn 65, since you were born on the first, so that would be ${coverage65.format('MM/YYYY')}` : `Part A & B will start on the first day of the month you turn 65, ${coverage65.format('MM/YYYY')}`
        return {
            coverage_a_date: coverage65,
            coverage_b_date: coverage65,
            info,
            warning
        }
    }
}

export const calcCoverageAroundDo65 = ({ do65, canHaveSEP = false }) => {
    do65 = moment.utc(do65).startOf('day')
    const dateEnroll = do65.clone().subtract(3, 'month').startOf('month')
    const coverageDates = []
    for (let i = 0; i < 12; i++) {
        const de = dateEnroll.clone().add(i, 'months')
        coverageDates.push({
            dateEnroll: de,
            coverageDate: calcCoverageDate({ do65, dateEnroll: de, canHaveSEP }),
            birthdayMonth: de.month() === do65.month()
        })
    }
    return coverageDates
}

export const calcCoverageDate = ({ do65, dateEnroll = moment.utc(), canHaveSEP = false, disabled = false, dateDisabled24m = null }) => {
    if (disabled) {
        return moment.utc(dateDisabled24m).add(1, 'month')
    }

    do65 = moment.utc(do65).startOf('day')

    dateEnroll = moment.utc(dateEnroll).startOf('day')

    // Initial Enrollment Period
    const IEP = {
        start: do65.clone().subtract(3, 'month').startOf('month'),
        center: do65.clone().startOf('month'),
        end: do65.clone().add(3, 'month').endOf('month')
    }

    // Special Enrollment Period
    const SEP = {
        start: IEP.end.clone(),
        end: IEP.end.clone().add(7, 'months')
    }

    // General Enrollment Period
    const GEP = {
        start: moment.utc('1-1', 'MM-DD').startOf('day'),
        end: moment.utc('3-31', 'MM-DD').startOf('day')
    }

    let coverageDate = {}
    const alerts = []

    // priority IEP -> SEP -> GEP

    if (dateEnroll.isBetween(IEP.start, IEP.center, null, '[)')) {
        coverageDate = IEP.center.clone()
    } else if (dateEnroll.isBetween(IEP.center, IEP.end, null, '[]')) {
        const monthsDiff = dateEnroll.diff(IEP.center, 'months')
        if (monthsDiff < 1) {
            coverageDate = dateEnroll.add(1, 'month').startOf('month')
        } else if (monthsDiff === 1) {
            coverageDate = dateEnroll.add(2, 'month').startOf('month')
        } else if (monthsDiff >= 2) {
            coverageDate = dateEnroll.add(3, 'month').startOf('month')
        }
    } else if (canHaveSEP && dateEnroll.isBetween(SEP.start, SEP.end, null, '[]')) {
        coverageDate = dateEnroll.add(1, 'month').startOf('month')
    } else if (GEP.start.month() <= dateEnroll.month() && dateEnroll.month() <= GEP.end.month()) {
        coverageDate = moment.utc(`7-1-${dateEnroll.year()}`, 'MM-DD-YYYY').startOf('day')
    } else {
        alerts.push({ message: 'Enroll date is not within any valid range', level: 'danger' })
    }

    coverageDate.alerts = alerts
    return coverageDate

    // let medicareDate = do65.clone();
    // if (do65.date() === 1) {
    //   medicareDate.subtract(1, 'month');
    // }
    // if (dateEnroll > do65) {
    //   medicareDate = dateEnroll.clone();
    //   if (dateEnroll.diff(do65, 'months', true) >= 2) {
    //     medicareDate.add(2, 'month');
    //   } else {
    //     medicareDate.add(1, 'month');
    //   }
    // }
    // return medicareDate;
}
