
import { mapGetters, mapActions } from 'vuex'

export default {
    props: {
        title: {
            type: String,
            default: null,
        },
        titleClassName: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: null,
        },
        nextLabel: {
            type: String,
            default () {
                return this.$store.getters.nextSurveyRoute ? 'Next' : 'Done'
            }
        }
    },
    computed: {
        ...mapGetters(['survey', 'uid', 'surveyRoutes', 'isSurveyRoute', 'activeRoutePath', 'activeRoute', 'previousSurveyRoute', 'nextSurveyRoute', 'autosave'])
    },
    methods: {
        ...mapActions(['onSurveyDone'])
    }
}
