
import { Pie } from 'vue-chartjs'

export default {
    extends: Pie,
    props: {
        chartData: {
            type: Object,
            default: null,
        },
        chartOptions: {
            type: Object,
            default: null,
        },
    },
    watch: {
        chartData () {
            this.renderChart(this.chartData, this.chartOptions)
        },
    },
    mounted () {
        this.renderChart(this.chartData, this.chartOptions)
    },
}
