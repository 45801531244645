
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
    props: {
        value: {
            type: Object,
            default () { return {} }
        },
    },
    data () {
        return {
            unwatch: null,
            form: {
                mapd: null,
                supp: null,
                pdp: null,
            }
        }
    },
    computed: {
        ...mapGetters(['summaryPlansList']),
        plansByType () {
            return this.types.reduce((m, type) => {
                m[type] = m[type] || []
                m[type] = this.summaryPlansList.filter(plan => !!plan[type])
                return m
            }, {})
        },
        types () {
            return ['supp', 'pdp', 'mapd']
        },
    },
    watch: {
        value () {
            if (!_.isEqual(this.value, this.form)) {
                this.updateForm()
            }
        },
    },
    mounted () {
        this.updateForm()
        this.unwatch = this.$watch('form', {
            deep: true,
            handler (form) {
                if (!_.isEqual(this.form, form)) {
                    this.$emit('input', _.cloneDeep(this.form))
                }
            }
        })
    },
    destroyed () {
        this.unwatch && this.unwatch()
    },
    methods: {
        updateForm () {
            this.form = _.cloneDeep(this.value || {})
            this.types.forEach((type) => {
                if (!this.form[type]?.id && this.plansByType[type].length === 1) {
                    this.$set(this.form, type, this.plansByType[type][0].id)
                    this.$emit('input', _.cloneDeep(this.form))
                }
            })
        },
    }
}
