
import _ from 'lodash'
import { mapMutations } from 'vuex'

export default {
    props: {
        max: {
            type: Number,
            default: 3,
        },
    },
    data () {
        return {
            modal: false
        }
    },
    computed: {
        comparingList () {
            return _.values(this.$store.state.comparing)
        },
        comparingLength () {
            return this.comparingList.length
        },
    },
    methods: {
        ...mapMutations(['clearComparing']),
    },
}
