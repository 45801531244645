export const ChoicesMixin = {
    props: {
        choices: {
            type: Array,
            default: () => [{ title: 'Yes', value: true }, { title: 'No', value: false }]
        },
        value: {
            type: [Boolean, String],
            default: null
        },
        help: {
            type: String,
            default: null
        }
    },
    data () {
        return {
            selected: this.value
        }
    },
    computed: {
        hasIcon () {
            if (this.choices.length) {
                return this.choices[0]?.icon
            }
            return null
        }
    },
    methods: {
        clickHandler (value) {
            this.selected = value
            this.$emit('input', value)
        }
    }
}
