

export default {
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        required: {
            type: Boolean,
            default: true
        },
        inputClass: {
            type: String,
            default: null
        },
        groupClass: {
            type: [String],
            default: null
        },
    }
}
