
export default {
    props: {
        src: {
            type: String,
            default: null,
        },
    },
    mounted () {
        if (this.src) {
            this.setBusy()
        }
    },
}
