
import { mapGetters } from 'vuex'
import { lengthInUtf8Bytes, humanFileSize } from '~/util/string'

export default {
    props: {
        activity: {
            type: Object,
            default: null
        },
    },
    data () {
        return { context: null }
    },
    computed: {
        ...mapGetters(['user']),
    },
    watch: {
        activity () {
            this.loadContext()
        }
    },
    mounted () {
        return this.loadContext()
    },
    methods: {
        humanFileSize,
        lengthInUtf8Bytes,
        async loadContext () {
            // if less than 1mb
            if (this.activity?.context?.file) {
                const res = await this.$try('$get', `/api/accounts/activity/${this.activity.id}/context.json`)
                if (res?.error) {
                    this.context = this.activity.context
                } else {
                    this.context = res
                }
            } else {
                this.context = this.activity.context
            }
        }
    }
}
