
import _ from 'lodash'

export default {
    props: {
        userGroupPlan: {
            type: Object,
            default: null
        },
    },
    data () {
        return {
            form: {
                monthly_premium: null,
                monthly_premium_note: null,
                config: {
                    ignore: {
                        deductible: null,
                        drug_deductible: null,
                        maximum_oopc_cost_share: null,
                        pcp_copay_cost_share: null,
                        specialist_copay_cost_share: null,
                        urgent_care_copay_cost_share: null,
                        emergency_copay_cost_share: null,
                        hospitalization_copay_cost_share: null,
                        outpatient_copay_cost_share: null,
                        radiology_copay_cost_share: null,
                        bloodwork_copay_cost_share: null,
                        xrays_copay_cost_share: null,
                        physical_therapy_copay_cost_share: null,
                        has_any_vision: false,
                        has_any_hearing: false,
                        has_any_basic_dental: false,
                        has_any_comprehensive_dental: false,
                        has_any_fitness: false,
                        has_any_otc: false,
                    }
                }
            }
        }
    },
    mounted () {
        this.updateForm()
        this.setGlobal('$userGroupPlanForm', this)
    },
    methods: {
        updateForm () {
            if (this.userGroupPlan) {
                this.form = _.cloneDeep(this.userGroupPlan)
            }
        },
        validate () {
            return this.$validator.validate()
        },
        async save () {
            const valid = await this.validate()
            if (!valid) {
                return this.$store.$alert(this.$validator.errors.all().join('\n'))
            }

            const { error } = await this.$try('updateUserGroupPlan',
                {
                    ...this.form,
                    id: this.userGroupPlan?.id
                },
            )
            if (error) {
                return this.$emit('error', error)
            }
            const userGroupPlan = _.cloneDeep({ ...this.userGroupPlan, ...this.form })
            this.$store.commit('updateUserGroupPlan', userGroupPlan)
            this.$emit('refresh')
            this.$emit('close')
        }
    }
}
