
import { mapGetters } from 'vuex'

export default {
    head () {
        return {
            title: 'MC',
            link: [
                { rel: 'icon', type: 'image/x-icon', href: require('~/assets/images/favicon.png') }
            ],
        }
    },
    mounted () {
        window.addEventListener('resize', () => {
            this.$store.dispatch('updateActiveBreakpoint')
        })
    },
    computed: {
        ...mapGetters(['support', 'isAgentMode', 'support'])
    },
}
