
export default {
    props: {
        steps: {
            type: Array,
            default: () => [],
        },
        active: {
            type: Object,
            default: null,
        },
        tooltip: {
            type: Function,
            default: () => null,
        },
        clickable: {
            type: Boolean,
            default: false,
        },
    },
}
