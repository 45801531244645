import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters([
            'uid',
            'survey',
            'client',
            'option',
            'year',
            'summary',
            'isAEPScheduling',
        ]),
        hasChanges () {
            return Object.keys(this.changes).length
        }
    },
    autosave: true,
    async fetch () {
        const survey = await this.getSurvey({ id: this.uid })
        let iterateOn = this.form
        if (!this.form || !Object.keys(this.form).length) {
            this.$set(this, 'form', {})
            iterateOn = _.cloneDeep(survey)
        }
        for (const field in iterateOn) {
            const initialValue = survey[field]
            this.$set(this.form, field, _.cloneDeep(initialValue))
            setTimeout(() => {
                const autosave = this.$options.autosave === true
                const handler = (newValue, oldValue) => {
                    if (this.errors.has(field) || _.isEqual(newValue, oldValue) || (_.isNil(newValue) && _.isNil(oldValue))) {
                        return
                    }
                    console.log(`change handler: ${field}`, 'from', typeof oldValue, oldValue, 'to', typeof newValue, newValue)
                    const value = _.cloneDeep(this.form[field])
                    this.$store.commit('field', { field, value })
                    this.$emit('input', value)
                    this.$set(this.changes, field, value)
                    if (autosave) {
                        return this.saveChanges()
                    }
                }
                this.watchers.push(this.$watch(`survey.${field}`, {
                    deep: true,
                    handler (newValue, oldValue) {
                        if (this.errors.has(field) || _.isEqual(newValue, oldValue) || (_.isNil(newValue) && _.isNil(oldValue))) {
                            return
                        }
                        this.$set(this.form, field, newValue)
                    }
                }))
                this.watchers.push(this.$watch(`form.${field}`, {
                    deep: true,
                    handler: autosave ? _.debounce(handler, 600) : handler
                }))
            }, 300)
        }
        await this.postSurveyFetch(survey)
        this.$nextTick(() => {
            this.lazyPostSurveyFetch(survey)
        })
    },
    data () {
        return {
            watchers: [],
            changes: {},
            form: {}
        }
    },
    destroyed () {
        if (this.watchers && this.watchers.length) {
            this.watchers.forEach(watcher => watcher())
        }
    },
    methods: {
        ...mapActions([
            'getSurvey',
            'completeSurvey'
        ]),
        postSurveyFetch () {},
        lazyPostSurveyFetch () {},
        async saveChanges () {
            if (this.hasChanges) {
                this.$store.commit('autosave', 'saving')
                console.log('saving fields', Object.keys(this.changes))
                // triggers for survey
                const triggers = [null, 'va', 'fep_blue', 'tricare', 'champva']
                const changedKey = Object.keys(this.changes)[0]
                const changedValue = Object.values(this.changes)[0]
                if (triggers.includes(changedKey) && !!changedValue) {
                    const index = triggers.indexOf(changedKey)
                    const scritpTabIndex = index === triggers.length - 1 ? triggers.length - 2 : index
                    this.$store.commit('setActiveSurveyTab', 2)
                    this.$store.commit('setActiveScriptTab', scritpTabIndex)
                    this.$store.commit('support', true)
                }

                const { error } = await this.$try('updateSurvey',
                    {
                        id: this.uid,
                        data: _.omit(_.cloneDeep(this.changes), 'updated', 'crm_id')
                    },
                    { spinner: false }
                )
                this.changes = {}
                if (error) {
                    this.$store.commit('autosave', 'errored')
                    return false
                    // throw error?
                } else {
                    this.$store.commit('autosave', 'saved')
                    return true
                }
            }
            return false
        },
        updateFormFromSurveyModel (surveyModel, questions) {
            questions.forEach((q) => {
                const name = q?.propertyHash?.name
                const question = surveyModel.getQuestionByName(name)
                let value = _.cloneDeep(question.value)

                // this is dump, surveyjs treats empty string as undefined, so if your API doesn't process them, you can't clear a value
                // https://github.com/surveyjs/survey-library/blob/b9657e9d2683e4aa600600c053b42dfcc9326c84/src/survey.ts#L5622-L5623
                if (value === undefined && question.getType() === 'text') {
                    value = ''
                }
                const pick = question?.propertyHash?.pick ? question?.propertyHash?.pick.split(',') : []
                if (pick?.length && value && _.isObject(value)) {
                    _.forEach(pick, (k) => {
                        if (this.form[k] !== value[k]) {
                            this.$set(this.form, k, value[k])
                        }
                    })
                } else if (!_.isEqual(this.form[name], value)) {
                    this.$set(this.form, name, value)
                }
            })
        }
    },
}
