
import _ from 'lodash'
import { mapGetters } from 'vuex'
export default {
    props: {
        title: {
            type: String,
            default: null,
        },
        titleClassName: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: null,
        },
        nextLabel: {
            type: String,
            default () {
                return this.$store.getters.isAgentMode ? this.$store.getters.nextSurveyRoute ? 'Next' : 'Done' : this.$store.getters.currentSurveyRoute?.text ? `Done with ${_.toLower(this.$store.getters.currentSurveyRoute?.text)}` : null
            }
        }
    },
    computed: {
        ...mapGetters(['nextSurveyRoute', 'client', 'crmBase']),
        support: {
            get () {
                return this.$store.state.support
            },
            set (v) {
                this.$store.commit('support', v)
            }
        }
    },
    methods: {
        getHeaderHeight () {
            if (this.header) {
                return this.header.offsetHeight
            }
            return 130
        }
    }
}
