
import _ from 'lodash'
import { mapGetters } from 'vuex'
import card from '~/mixins/card'

export default {
    mixins: [card],
    props: {
        value: {
            type: Object,
            default () { return {} }
        },
    },
    data () {
        return {
            form: {
                excluded: [],
            },
            unwatch: null,
        }
    },
    computed: {
        ...mapGetters([
            's3Base',
            'summaryAttachments'
        ]),
    },
    watch: {
        value () {
            if (!_.isEqual(this.value, this.form)) {
                this.updateForm()
            }
        },
    },
    mounted () {
        this.updateForm()
        this.unwatch = this.$watch('form', {
            deep: true,
            handler () {
                this.$emit('input', _.cloneDeep(this.form))
            }
        })
    },
    destroyed () {
        this.unwatch && this.unwatch()
    },
    methods: {
        updateForm () {
            this.form = { excluded: [], ..._.cloneDeep(this.value || {}) }
            this.$emit('input', _.cloneDeep(this.form))
        },
    }
}
