
import _ from 'lodash'

export default {
    props: {
        tier: {
            type: Object,
            default: null,
        },
        index: {
            type: Number,
            default: null
        }
    },
    data () {
        return {
            levels: ['preferred_retail', 'preferred_mail_order', 'standard_retail', 'standard_mail_order'],
        }
    },
    computed: {
        level0 () {
            let level0
            _.some(this.levels, (level) => {
                if (this.tier[level]) {
                    level0 = level
                    return true
                }
            })
            return level0
        },
    },

}
