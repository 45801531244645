
import { mapGetters } from 'vuex'

import card from '~/mixins/card'
export default {
    mixins: [card],
    props: {
        magicUrl: {
            type: String,
            default: '/'
        },
    },
    data () {
        return {
            enrollModal: false,
            enrollPlan: null,
        }
    },
    computed: {
        ...mapGetters([
            'uid',
            'surveyRoutesMap',
            'isPlanInOpportunities',
            'summarySwitchedOnCardsCombos',
        ]),
    },
    methods: {
        isPlanExcluded (plan) {
            return (this.card_config_value?.excluded || []).includes(plan.id)
        },
    }
}
