
export default {
    props: {
        keypath: {
            type: String,
            default: null,
        },
        name: {
            type: String,
            default: null,
        },
        helpList: {
            type: Array,
            default: () => [],
        },
        plansList: {
            type: Array,
            default: () => [],
        },
        planColumnOffset: {
            type: [String, Number],
            default: 2,
        },
        planColumnClass: {
            type: String,
            default: 'col',
        },
        collapsibleVisible: {
            type: Boolean,
            default: false,
        },
        propertyEqualityClass: {
            type: String,
            default: 'property--neq',
        },
        compact: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        hasHelp () {
            return this.helpList && this.helpList.filter(h => h).length
        },
    },
}
