
export default {
    props: {
        value: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            modalShow: false
        }
    }
}
