export default function downloadBase64Pdf (filename, base64) {
    const url = `data:application/pdf;base64,${base64}`
    const a = document.createElement('a')
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    URL.revokeObjectURL(url)
    a.remove()
}
