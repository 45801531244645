
export default {
    props: {
        name: {
            type: String,
            default: null
        },
        type: {
            type: String,
            default: 'fas'
        },
        spin: {
            type: Boolean,
            default: null
        },
        iconClass: {
            type: String,
            default: ''
        },
    }
}
