
import _ from 'lodash'

export default {
    props: {
        providers: {
            type: Array,
            default: () => ([])
        },
        value: {
            type: Array,
            default: () => ([])
        },
        removable: {
            type: Boolean,
            default: false
        },
        showSelectedOnly: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        findProviderIndex (provider) {
            return _.findIndex(this.value, (p) => {
                if (provider.npi) {
                    return p.npi === provider.npi
                }
                return p.name === provider.name
            })
        },
        isProviderInList (provider) {
            return this.findProviderIndex(provider) > -1
        },
    }
}
