
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
    props: {
        plan: {
            type: Object,
            default: null
        },
        readonly: {
            type: Boolean,
            default: false
        },
        magicUrl: {
            type: String,
            default: '/'
        },
        showEnroll: {
            type: Boolean,
            default: true
        },
    },
    data () {
        return {
            planModal: false,
            enrollModal: false,
            adding: false,
            removing: false,
        }
    },
    computed: {
        ...mapGetters(['isPlanInOpportunities', 'year', 'survey', 'isDonutHoleYear']),
        comparing: {
            get () {
                return !!this.$store.state.comparing[this.plan.id]
            },
            set (v) {
                return v ? this.$store.commit('addComparing', this.plan) : this.$store.commit('removeComparing', this.plan)
            }
        },
        added () {
            return this.$store.getters.isPlanInSummary(this.plan)
        },
        otherCosts () {
            return [
                this.plan.mapd || this.plan.ma ? { text: `${_.isNumber(this.plan.deductible_cost_share) ? this.money(this.plan.deductible_cost_share) + ' medical deductible' : this.plan.deductible_cost_share}`, value: this.plan.deductible_cost_share } : null,
                this.plan.mapd || this.plan.pdp ? { text: `${_.isNumber(this.plan.drug_deductible_cost_share) ? this.money(this.plan.drug_deductible_cost_share) : this.plan.drug_deductible_cost_share} drug deductible`, value: this.plan.drug_deductible } : null,
                { text: `${this.plan.maximum_oopc || this.plan.maximum_oopc_cost_share_note} Maximum out of pocket`, value: this.plan.maximum_oopc || this.plan.maximum_oopc_cost_share_note },
                { text: `${this.money(this.plan.emergency_copay_cost_share)} Emergency copay`, value: this.plan.emergency_copay_cost_share },
                { text: `${this.money(this.plan.pcp_copay_cost_share)} Primary care copay`, value: this.plan.pcp_copay_cost_share },
                { text: `${this.money(this.plan.specialist_copay_cost_share)} Specialist copay`, value: this.plan.specialist_copay_cost_share },
                // todo: add this to augmented plans in items instead of hardcoded value?
                /aarp/i.test(this.plan.carrier) && this.plan.plan_type === 'supp' ? { text: '$20/year AARP Membership is required', value: '$12' } : null
            ].filter(cost => cost && !_.isNil(cost.value) && cost.value !== '')
        },
    },
    methods: {
        async addPlanToSummary () {
            this.adding = true
            await this.$store.dispatch('addSummaryPlan', this.plan)
            this.adding = false
            this.$emit('addSummaryPlan', this.plan)
        },
        async removePlanFromSummary () {
            this.removing = true
            await this.$store.dispatch('removeSummaryPlan', this.plan)
            this.removing = false
            this.$emit('removeSummaryPlan', this.plan)
        }
    }
}
