// https://docs.google.com/spreadsheets/d/1egQs5ZS8NaDAdGPKQVI6MWTPETFC6KF8JK2JN1ga158/edit#gid=0

const aetna = {
    key: 'aetna',
    name: 'Aetna',
    logo: 'https://i.imgur.com/0LKBc3T.png',
    provider_network: 'https://www.aetnamedicare.com/en/find-doctors-hospitals/find-provider.html',
}

const united = {
    key: 'unitedhealth',
    name: 'UNITEDHEALTH GRP',
    logo: 'https://i.imgur.com/nw88mXU.jpg',
    provider_network: 'https://www.uhc.com/find-a-doctor',
    copay: 'https://www.uhccommunityplan.com/{state}'
}

const cigna = {
    key: 'cigna',
    name: 'CIGNA HLTH GRP',
    logo: 'https://i.imgur.com/ILBeHSw.png',
    provider_network: 'https://providersearch.hsconnectonline.com/Directory/',
}

const humana = {
    key: 'humana_grp',
    name: 'HUMANA GRP',
    logo: 'https://i.imgur.com/U9SPp0U.jpg',
    provider_network: 'https://www.humana.com/medicare/find-a-doctor',
}

const wellcare = {
    key: 'wellcare',
    name: 'Wellcare',
    logo: '',
    provider_network: 'https://www.wellcare.com/en/Find-a-Provider',
}

const moo = {
    key: 'mutual_of_omaha_grp',
    name: 'MUTUAL OF OMAHA GRP',
    logo: 'https://i.imgur.com/E5HSmq8.jpg',
    provider_network: '',
}

const bcbsri = {
    key: 'blue_cross_blue_shield_of_rhode_island',
    name: 'BLUE CROSS & BLUE SHIELD OF RHODE ISLAND',
    logo: 'https://i.imgur.com/60aWnQ4.jpg',
    provider_network: 'https://www.bcbsri.com/finddoctor',
}

const bcbsma = {
    key: 'blue_cross_blue_shield_of_massachusetts',
    name: 'BLUE CROSS & BLUE SHIELD OF MASSACHUSETTS',
    logo: 'https://i.imgur.com/x83HvZX.jpg',
    provider_network: '',
}

const fallon = {
    key: 'fallon_health',
    name: 'Fallon Health',
    logo: 'https://i.imgur.com/ZvGek41.png',
    provider_network: 'https://fallonhealth.org/en/find-insurance/medicare/find-a-doctor.aspx',
}

const silverscript = {
    key: 'silver_script',
    name: 'SilverScript',
    logo: 'https://i.imgur.com/PUtK5Ib.png',
    provider_network: '',
}

const envision = {
    key: 'envision_insurance',
    name: 'EnvisionInsurance',
    logo: 'https://i.imgur.com/c0R1WqW.png',
    provider_network: '',
}

const bluemedicarerx = {
    key: 'blue_medicare_rx',
    name: 'Blue MedicareRx',
    logo: 'https://i.imgur.com/RHkH9tQ.png',
    provider_network: '',
}

export const carriers = {
    aetna,
    aetna_grp: aetna,
    aetna_medicare: aetna,

    united,
    united_healthcare: united,
    unitedhealth_grp: united,

    cigna,
    cigna_hlth_grp: cigna,

    humana,
    humana_grp: humana,

    wellcare,
    well_care: wellcare,

    mutual_of_omaha: moo,
    mutual_of_omaha_rx: moo,
    mutual_of_omaha_grp: moo,

    bcbsri,
    blue_cross_blue_shield_of_rhode_island: bcbsri,
    blue_cross_blue_shield_of_massachusetts: bcbsma,

    bluemedicarerx,
    blue_medicare_rx: bluemedicarerx,

    fallon,
    fallon_health: fallon,

    silverscript,
    silver_script: silverscript,

    envision,
    envision_insurance: envision,
}
