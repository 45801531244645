
import { mapGetters } from 'vuex'
export default {
    props: {
        plan: {
            type: Object,
            default: null,
        },
    },
    computed: {
        ...mapGetters(['survey']),
    },
}
