
import { mapState, mapGetters } from 'vuex'
export default {
    props: {
        value: {
            type: String,
            default: 'phone'
        }
    },
    computed: {
        ...mapGetters(['isAEP']),
        ...mapState({
            scheduleHelpText: state => state.scheduleHelpText
        })
    },
    mounted () {
        this.setGlobal('$method', this)
    }
}
