
import { mapGetters } from 'vuex'

export default {
    props: {
        client: {
            type: Object,
            default: null
        },
    },
    data () {
        return {
            opportunities: [],
            form: {
                record_types: [],
                effective_dates: {
                    CMS_40B: this.client.effective_date || null,
                    CMS_564: this.client.effective_date || null,
                },
                enrollment_periods: {
                    CMS_40B: this.client.enrollment_period || null,
                },
            },
        }
    },
    async fetch () {
        /* eslint-disable camelcase */
        const { opportunities } = await this.$api.getCmsSsaOpportunities(this.client?.id)
        this.opportunities = opportunities || []
    },
    computed: {
        ...mapGetters(['crmBase', 'enrollmentPeriods']),
    },
    methods: {
        async submit () {
            const { error } = await this.$try('ensureCmsSsaOpportunities', this.client?.id, {
                ...this.form,
            })
            if (!error) {
                this.$success('Done!')
                this.$emit('success')
            }
        },
    },
}
