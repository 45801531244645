import _ from 'lodash'

// https://stackoverflow.com/a/58898994/493756
export const breakpoints = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
}
export const orderedKeys = ['xs', 'sm', 'md', 'lg', 'xl']

export const getActiveBreakpoint = () => {
    let breakpoint = ''
    let classList = []

    orderedKeys.some((k, i) => {
        const n = orderedKeys[i + 1]
        let query = ''
        query += `(min-width: ${breakpoints[k]}px)`
        if (n) {
            query += `and (max-width: ${breakpoints[n] - 1}px)`
        }
        if (typeof window !== 'undefined' && window.matchMedia && window.matchMedia(query).matches) {
            breakpoint = k
            classList = [
                ...orderedKeys.slice(0, i).map(b => `gt-${b}`),
                ...orderedKeys.slice(0, i).concat(k).map(b => `gte-${b}`),
                k,
                ...orderedKeys.slice(i + 1, orderedKeys.length).concat(k).map(b => `lte-${b}`),
                ...orderedKeys.slice(i + 1, orderedKeys.length).map(b => `lt-${b}`),
            ]
            return true
        }
        return false
    })
    return {
        breakpoint, classList, className: classList.join(' '), classMap: _.keyBy(classList, k => k),
    }
}
