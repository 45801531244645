
import moment from 'moment'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { resolveType, toAlpha } from '~/util/string'

const NA = 'N/A'

export default {
    props: {
        data: {
            type: Object,
            default: null
        },
        raw: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            parsedData: null,
        }
    },
    computed: {
        ...mapGetters([
            'user',
            'crmBase',
            'isServer'
        ]),
        report () {
            return this.parsedData
        },
        yearsFields () {
            return (this.report?.years || []).map(y => `${y}`)
        },
        periods () {
            return _.sortBy(this.data.periods.map((p) => {
                const sd = moment(p.s_date).startOf('month')
                const ed = moment(p.e_date).endOf('month')
                if (/ytd/i.test(p.postfix)) {
                    p.label = `YTD ${sd.year()}`
                } else {
                    p.label = `Q${sd.quarter()} ${sd.year()}`
                }
                p.date = ed.clone()
                p.datestr = p.date.format('MM/DD/YYYY')
                const months = []
                // eslint-disable-next-line no-unmodified-loop-condition
                while (ed > sd || sd.format('M') === ed.format('M')) {
                    months.push({
                        date: sd,
                        datestr: sd.format('MM/DD/YYYY'),
                        key: sd.format('MMM YYYY'),
                        label: sd.format('MMM YYYY'),
                        year: sd.year(),
                        month: sd.month()
                    })
                    sd.add(1, 'month')
                }
                p.months = months
                return p
            }), i => i.date)
        },
        months () {
            return _.sortBy(this.periods.reduce((a, p) => {
                return a.concat(p.months)
            }, []), i => i.date)
        },
        quarters () {
            return this.periods.reduce((a, p) => {
                return a.concat({
                    key: p.label,
                })
            }, [])
        },
    },
    watch: {
        data: {
            deep: true,
            handler () {
                this.parsedData = this.parseReport(this.data)
            }
        },
        'parsedData.utilization_map_by_month': {
            deep: true,
            handler () {
                this.saveChartsAsPng()
            }
        }
    },
    mounted () {
        this.setGlobal('$employerReport', this)
    },
    created () {
        this.parsedData = this.parseReport(this.data)
    },
    methods: {
        toAlpha,
        rowClass (item) {
            return item.variant ? `table-${item.variant}` : undefined
        },
        getUtilizationChartData () {
            const len = this.report?.utilization_table_by_month?.length
            const map = _.cloneDeep(this.report?.utilization_map_by_month)
            if (!len) {
                return null
            }
            const bars = [].concat(this.months).concat(this.quarters)
            this.periods.forEach((p) => {
                map[p.label] = {
                    consultations: _.sum(p.months.map(m => map[m.key]?.consultations || 0)),
                    communications: _.sum(p.months.map(m => map[m.key]?.communications || 0)),
                    webinars: _.sum(p.months.map(m => map[m.key]?.webinars || 0)),
                    roadmaps: _.sum(p.months.map(m => map[m.key]?.roadmaps || 0)),
                }
            })

            const datasets = [
                {
                    label: 'Consultations (1)',
                    backgroundColor: '#008CCD',
                    data: bars.map(m => map[m.key]?.consultations || 0),
                },
                {
                    label: 'Calls/Emails (2)',
                    backgroundColor: '#ff661f',
                    data: bars.map(m => map[m.key]?.communications || 0),
                },
                {
                    label: 'Webinars (3)',
                    backgroundColor: '#28a745',
                    data: bars.map(m => map[m.key]?.webinars || 0),
                },
                {
                    label: 'Roadmaps (4)',
                    backgroundColor: '#ffc000',
                    data: bars.map(m => map[m.key]?.roadmaps || 0),
                }
            ]
            return {
                labels: bars.map(b => b.key),
                datasets,
            }
        },
        getUtilizationChartOptions () {
            return {
                title: {
                    display: false,
                    text: 'Utilization Chart',
                },
                legend: {
                    display: true,
                },
                scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        stacked: true,
                    },
                },
                animation: false
            }
        },
        getPlansTypesChartData (period) {
            const dataMap = {
                mapds: { total: 0, label: 'MAPD' },
                supps: { total: 0, label: 'SUPP' },
                pdps: { total: 0, label: 'PDP' },
                unknowns: { total: 0, label: 'N/A' },
            }
            period.months.forEach((m) => {
                dataMap.mapds.total += _.get(this.report, `plans_by_type_by_month_map.${m.key}.mapds`) || 0
                dataMap.supps.total += _.get(this.report, `plans_by_type_by_month_map.${m.key}.supps`) || 0
                dataMap.pdps.total += _.get(this.report, `plans_by_type_by_month_map.${m.key}.pdps`) || 0
                dataMap.unknowns.total += _.get(this.report, `plans_by_type_by_month_map.${m.key}.unknowns`) || 0
            })
            const values = _.values(dataMap)
            const total = _.sumBy(values, 'total')
            if (!total) {
                return null
            }
            return {
                datasets: [{
                    data: values.map(v => v.total),
                    backgroundColor: ['#28a745', '#ff661f', '#008CCD', '#dc3545'],
                }],
                labels: values.map(v => `${v.label} (${v.total})`),
            }
        },
        getPlansTypesChartOptions (title) {
            return {
                title: {
                    display: true,
                    text: title || 'Closed Plans by Type',
                },
                responsive: true,
                tooltips: {
                    display: true,
                },
                legend: {
                    display: true,
                },
            }
        },
        parseReport (report) {
            if (report.parsed) {
                return report
            }
            report.parsed = true
            const formatter = value => value || 0

            report.utilization_by_month_fields = [
                { key: 'key', label: 'Month' },
                { key: 'consultations', label: 'Consultations', sub: '(1)', formatter },
                { key: 'communications', label: 'Calls/Emails', sub: '(2)', formatter },
                { key: 'webinars', label: 'Webinar Attendees', sub: '(3)', formatter },
                { key: 'roadmaps', label: 'Roadmaps Completed', sub: '(4)', formatter },
                { key: 'total', label: 'Total', formatter },
            ]
            report.utilization_map_by_month = {}
            const utilizationByMonthMap = (field, period) => (record) => {
                record.date = record.year && record.month ? moment(`${record.month}/01/${record.year}`, 'MM/DD/YYYY').startOf('month') : moment(record.month, 'YYYY-MM-DD').startOf('month')
                record.datestr = record.date.format('MM/DD/YYYY')
                record.year = record.year || record.date.year()
                record.month = record.month || record.date.month()
                record.key = `${record.date.format('MMM')} ${record.year}`
                record[field] = record.total || 0
                report.utilization_map_by_month[record.key] = { ...(report.utilization_map_by_month[record.key] || {}), [field]: record[field] }
            }
            this.periods.forEach((p) => {
                p.months.forEach((m) => {
                    report.utilization_map_by_month[m.key] = { key: m.key, date: m.date, datestr: m.datestr, month: m.month, year: m.year, total: 0 }
                })
                ;(report[`consultations_scheduled_by_month${p.postfix}`] || []).map(utilizationByMonthMap('consultations', p));
                (report[`communications_by_month${p.postfix}`]?.records || []).map(utilizationByMonthMap('communications', p));
                (report[`roadmaps_completed_by_month${p.postfix}`] || []).map(utilizationByMonthMap('roadmaps', p));
                (report[`webinars_attendees_by_month${p.postfix}`]?.records || []).map(utilizationByMonthMap('webinars', p))
            })
            report.utilization_table_by_month = _.values(report.utilization_map_by_month)

            report.utilization_by_state_fields = [
                { key: 'key', label: 'State' },
                { key: 'consultations', label: 'Consultations', formatter },
                { key: 'communications', label: 'Calls/Emails', formatter },
                { key: 'roadmaps', label: 'Roadmaps Completed', formatter },
                { key: 'total', label: 'Total', formatter },
            ]
            this.periods.forEach((p) => {
                report[`utilization_map_by_state${p.postfix}`] = {}
                const utilizationByStateMap = field => (record) => {
                    record.key = record.state || record.PersonMailingState || NA
                    record[field] = record.total || 0
                    report[`utilization_map_by_state${p.postfix}`][record.key] = {
                        ...(report[`utilization_map_by_state${p.postfix}`][record.key] || { total: 0 }),
                        ..._.omit(record, ['total'])
                    }
                    report[`utilization_map_by_state${p.postfix}`][record.key].total = (report[`utilization_map_by_state${p.postfix}`][record.key].total || 0) + (record[field] || 0)
                }
                ;(report[`consultations_scheduled_by_state${p.postfix}`] || []).map(utilizationByStateMap('consultations', p));
                (report[`communications_by_state${p.postfix}`]?.records || []).map(utilizationByStateMap('communications', p));
                (report[`roadmaps_completed_by_state${p.postfix}`] || []).map(utilizationByStateMap('roadmaps', p))
                // add N/A row
                if (!report[`utilization_map_by_state${p.postfix}`][NA]) {
                    report[`utilization_map_by_state${p.postfix}`][NA] = { key: NA, consultations: 0, communications: 0, roadmaps: 0, total: 0 }
                }
                const values = _.values(report[`utilization_map_by_state${p.postfix}`])
                values.sort((m1, m2) => {
                    if (m1.key === NA) return 1
                    if (m2.key === NA) return -1
                    return m1.key < m2.key ? -1 : 1
                })
                report[`utilization_table_by_state${p.postfix}`] = values
            })

            report.hr_utilization_by_month_fields = [
                { key: 'key', label: 'Month' },
                { key: 'communications', label: 'Calls/Emails', formatter },
            ]
            report.hr_utilization_map_by_month = {}
            const hrUtilizationByMonthMap = (field, period) => (record) => {
                record.date = record.year && record.month ? moment(`${record.month}/01/${record.year}`, 'MM/DD/YYYY').startOf('month') : moment(record.month, 'YYYY-MM-DD').startOf('month')
                record.datestr = record.date.format('MM/DD/YYYY')
                record.year = record.year || record.date.year()
                record.month = record.month || record.date.month()
                record.key = `${record.date.format('MMM')} ${record.year}`
                record[field] = record.total || 0
                report.hr_utilization_map_by_month[record.key] = { ...(report.hr_utilization_map_by_month[record.key] || {}), [field]: record[field] }
                report.hr_utilization_map_by_month[record.key].total += (record[field] || 0)
            }
            this.periods.forEach((p) => {
                p.months.forEach((m) => {
                    report.hr_utilization_map_by_month[m.key] = { key: m.key, date: m.date, datestr: m.datestr, month: m.month, year: m.year, total: 0 }
                })
                ;(report[`hr_communications_by_month${p.postfix}`]?.records || []).map(hrUtilizationByMonthMap('communications', p))
            })

            report.enrolled_opportunities_by_month_fields = [
                { key: 'key', label: 'Month' },
                { key: 'transitions', label: 'Transitions', formatter },
            ]
            report.enrolled_opportunities_map_by_month = {}
            const enrolledOppsByMonthMap = (field, period) => (record) => {
                record.date = record.year && record.month ? moment(`${record.month}/01/${record.year}`, 'MM/DD/YYYY').startOf('month') : moment(record.month, 'YYYY-MM-DD').startOf('month')
                record.datestr = record.date.format('MM/DD/YYYY')
                record.year = record.year || record.date.year()
                record.month = record.month || record.date.month()
                record.key = `${record.date.format('MMM')} ${record.year}`
                record[field] = record.total || 0
                report.enrolled_opportunities_map_by_month[record.key] = { ...(report.enrolled_opportunities_map_by_month[record.key] || {}), [field]: record[field] }
                report.enrolled_opportunities_map_by_month[record.key].total += (record[field] || 0)
            }
            this.periods.forEach((p) => {
                p.months.forEach((m) => {
                    report.enrolled_opportunities_map_by_month[m.key] = { key: m.key, date: m.date, datestr: m.datestr, month: m.month, year: m.year, total: 0 }
                })
                ;(report[`enrolled_opportunities_by_month${p.postfix}`]?.records || []).map(enrolledOppsByMonthMap('transitions', p))
                const transitions = _.sumBy(report[`enrolled_opportunities_by_month${p.postfix}`].records, 'transitions')
                report.enrolled_opportunities_map_by_month[p.label] = {
                    date: p.date,
                    key: p.label,
                    transitions,
                    variant: 'warning',
                    total: transitions
                }
                return report
            })

            let plansByTypeByMonthTotalUnknows = 0
            report.plans_by_type_by_month_map = {}
            const plansByTypeByMonthMap = field => (record) => {
                record.date = record.year && record.month ? moment(`${record.month}/01/${record.year}`, 'MM/DD/YYYY').startOf('month') : moment(record.month, 'YYYY-MM-DD').startOf('month')
                record.datestr = record.date.format('MM/DD/YYYY')
                record.year = record.year || record.date.year()
                record.month = record.month || record.date.month()
                record.key = `${record.date.format('MMM')} ${record.year}`
                record[field] = record.total || 0
                report.plans_by_type_by_month_map[record.key] = { ...(report.plans_by_type_by_month_map[record.key] || {}), [field]: record[field] }
                report.plans_by_type_by_month_map[record.key].total += (record[field] || 0)
            }
            this.periods.forEach((p) => {
                p.months.forEach((m) => {
                    report.plans_by_type_by_month_map[m.key] = { key: m.key, date: m.date, datestr: m.datestr, month: m.month, year: m.year, total: 0 }
                })
                const records = report[`plans_by_type_by_month${p.postfix}`]?.records || []
                const mapdsByMonth = records.filter(r => r.Plan_Type__c === 'MAPD')
                const suppsByMonth = records.filter(r => r.Plan_Type__c === 'SUPP')
                const pdpsByMonth = records.filter(r => r.Plan_Type__c === 'PDP')
                const unknownsByMonth = records.filter(r => r.Plan_Type__c !== 'MAPD' && r.Plan_Type__c !== 'SUPP' && r.Plan_Type__c !== 'PDP')
                mapdsByMonth.map(plansByTypeByMonthMap('mapds', p))
                suppsByMonth.map(plansByTypeByMonthMap('supps', p))
                pdpsByMonth.map(plansByTypeByMonthMap('pdps', p))
                unknownsByMonth.map(plansByTypeByMonthMap('unknowns', p))
                const mapds = _.sumBy(mapdsByMonth, 'mapds')
                const supps = _.sumBy(suppsByMonth, 'supps')
                const pdps = _.sumBy(pdpsByMonth, 'pdps')
                const unknowns = _.sumBy(unknownsByMonth, 'unknowns') || 0
                report.plans_by_type_by_month_map[p.label] = {
                    date: p.date,
                    key: p.label,
                    mapds,
                    supps,
                    pdps,
                    unknowns,
                    variant: 'warning',
                    total: mapds + supps + pdps + unknowns
                }
                plansByTypeByMonthTotalUnknows += unknowns
            })
            report.plans_by_type_by_month_fields = [
                { key: 'key', label: 'Month' },
                { key: 'mapds', label: 'Advantage Plan (Part C)', formatter },
                { key: 'supps', label: 'Supplemental Plan (Medigap)', formatter },
                { key: 'pdps', label: 'Prescription Plan (Part D)', formatter },
                ...(
                    plansByTypeByMonthTotalUnknows
                        ? [{ key: 'unknowns', label: 'Unknown', formatter }]
                        : []
                ),
                { key: 'total', label: 'Total', formatter },
            ]
            return report
        },
        getParsedData () {
            return this.parsedData
        },
        getParsedReport () {
            return this.getParsedData()
        },
        onTextEdit ({ key, value } = {}) {
            this.onKeyEdit({ key, value })
            this.$emit('input', this.parsedData)
        },
        onCellEdit: _.debounce(function ({ key, value }) {
            return this.onKeyEdit({ key, value })
        }, 300),
        onCellBlur () {
            if (this.parsedData.manually_updated) {
                this.$emit('input', this.parsedData)
            }
        },
        onKeyEdit ({ key, value } = {}) {
            if (key) {
                const oldValue = _.get(this.parsedData, key)
                const oldType = typeof (resolveType(oldValue))
                value = resolveType(value) || (oldType === 'number' || oldType === 'undefined' ? 0 : null)
                _.set(this.parsedData, key, value)
                this.$set(this.parsedData, 'manually_updated', +new Date())
                this.$set(this.parsedData, 'manually_updated_by', this.user)
            }
        },
        saveChartsAsPng () {
            if (!this.$refs.utilizationChartCanvas) {
                return
            }
            const utilizationCanvas = this.$refs.utilizationChartCanvas.$el.querySelector('canvas')
            if (utilizationCanvas) {
                this.onKeyEdit({ key: 'utilizationChartPng', value: utilizationCanvas.toDataURL('image/png') })
            }
        }
    },
}
