import _ from 'lodash'
import Vue from 'vue'

function init (Survey, params = {}) {
    const widget = {
        name: 'vcomponent',
        title: 'Custom Vue Component',
        widgetIsLoaded () {
            return true
        },
        isFit (question) {
            return question.getType() === 'vcomponent'
        },
        isDefaultRender: true,
        activatedByChanged () {
            Survey.Serializer.addClass('vcomponent', [], null, 'empty')
            Survey.JsonObject.metaData.addClass('vcomponent', [], null, 'empty')
            Survey.JsonObject.metaData.addProperties('vcomponent', [
                {
                    name: 'component',
                    default: {},
                    category: 'general'
                },
                {
                    name: 'propsData',
                    type: 'object',
                    default: {},
                    category: 'general'
                },
            ])
        },
        afterRender (question, el) {
            const component = question.component || params.component
            if (!component) {
                throw new Error('Question type vcomponent requires a component name')
            }
            const Component = require(`~/components/${component}`)
            let propsData = question.propsData
            if (!propsData) {
                propsData = {}
            }
            if (typeof propsData === 'string') {
                try {
                    propsData = JSON.parse(propsData)
                } catch (e) {
                    propsData = {}
                    console.warn(`[vcomponent] Could not JSON.parse ${propsData}`)
                }
            }
            if (typeof propsData === 'function') {
                propsData = propsData({
                    $root: window.$root,
                    $store: window.$root.$store,
                    $router: window.$root.$router,
                    $route: window.$root.$route,
                    question,
                })
            }

            const getQuestionValuesHash = (question) => {
                let hash = question.survey.valuesHash
                if (question.parentQuestion && question.parentQuestion.getType && question.parentQuestion.getType() === 'paneldynamic') {
                    const dynPanels = question?.parentQuestion?.propertyHash?.panels
                    let dynPanelIndex = -1
                    dynPanels.some((dynPanel, dpi) => {
                        const elements = dynPanel.elements
                        if (elements.some(element => element.id === question.id)) {
                            dynPanelIndex = dpi
                            return true
                        }
                        return false
                    })
                    if (dynPanelIndex > -1) {
                        hash = question.parentQuestion.value[dynPanelIndex]
                    } else {
                        console.warn(`setQuestionValue question: ${question.name}, pick:${question?.propertyHash?.pick}, couldn't find dynamic panel index`)
                    }
                }
                return hash
            }

            const getQuestionValue = () => {
                if (question?.propertyHash?.pick) {
                    const hash = getQuestionValuesHash(question)
                    return _.pick(hash, question.propertyHash.pick.split(','))
                }
                return question.value
            }

            const setQuestionValue = (value) => {
                let hash = question.survey.valuesHash
                if (question?.propertyHash?.pick) {
                    const fields = question.propertyHash.pick.split(',')
                    hash = getQuestionValuesHash(question)
                    for (const field of fields) {
                        hash[field] = value[field]
                    }
                }
                question.value = value
            }
            const C = Component.default || Component
            if (!C.mixins) {
                C.mixins = []
            }
            C.mixins.push({
                computed: {
                    question () {
                        return question
                    },
                    surveyModel () {
                        return question.survey
                    }
                }
            })
            const vm = new Vue(Object.assign({}, C, {
                // thank you love! https://stackoverflow.com/a/64173971/493756
                // todo: maybe try to get a hold of the survey ref here
                parent: window.$root,
                propsData: {
                    value: getQuestionValue(),
                    ...(params.propsData || {}),
                    ...propsData
                },
            }))

            vm.$mount(el)
            if (vm.$el?.classList && !question?.propertyHash?.skipClasses) {
                vm.$el.classList.add('white-space-initial')
                vm.$el.classList.add('mb-4')
                vm.$el.classList.add('min-height-300')
            }
            vm.$on('input', (value) => {
                setQuestionValue(value)
            })
            vm.$on('set-value', ({ list, index, key, value }) => {
                if (list) {
                    const listValue = _.cloneDeep(question.survey.getValue(list))
                    if (listValue[index]) {
                        listValue[index][key] = value
                    }
                    question.survey.setValue(list, listValue)
                } else {
                    question.survey.setValue(key, value)
                }
            })
            vm.$on('start-over', () => {
                question.survey.currentPageNo = 0
            })
            vm.$on('visit-page', (pageName) => {
                const p = question.survey.getPageByName(pageName)
                if (p) {
                    question.survey.currentPageNo = p.visibleIndex
                }
            })
            vm.$on('next', () => {
                return question.survey.isLastPage ? question.survey.doComplete() : question.survey.nextPage()
            })
            vm.$on('previous', () => {
                return question.survey.prevPage()
            })

            // todo:
            // when replace with the bottom block
            // providers, drugs, pharmacies first value was not being added in the UI,
            // only on refresh.
            question.valueChangedCallback = function () {
                const v = getQuestionValue()
                if (!_.isEqual(vm.value, v)) {
                    // TODO: WARNING: SETTING PROP VALUE
                    vm.value = v
                }
            }
            // question.valueChangedCallback = function () {
            //     const v = getQuestionValue()
            //     if (!_.isEqual(vm.value, v)) {
            //         vm.$emit('update:value', v)
            //     }
            // }
        },
        willUnmount (question, el) {
            question.readOnlyChangedCallback = null
        },
        ...params
    }

    Survey.CustomWidgetCollection.Instance.addCustomWidget(
        widget,
        'customtype'
    )
}

export default init
