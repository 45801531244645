
import _ from 'lodash'
import { mapGetters, mapState } from 'vuex'
import { slugify } from '~/util/string'

export default {
    props: {
        drug: {
            type: Object,
            default: null,
        },
    },
    data () {
        return {
            costs: null,
            loading: false
        }
    },
    computed: {
        ...mapGetters([
            'canAgent',
            'frequencies',
            'year',
        ]),
        ...mapState({
            zip_code: state => state.survey?.zip_code,
            fips: state => state.survey?.fips,
            pharmacies: state => state.survey.pharmacies
        }),
        label () {
            return (this.drug?.label || this.drug?.name || this.drug?.text || '').trim()
        },
        slug () {
            return slugify(this.label)
        },
        package () {
            return this.drug?.package
        },
        dosage () {
            return this.drug?.dosage?.strength && this.drug?.dosage?.strength_units ? `${this.drug.dosage.strength}${this.drug.dosage.strength_units.toLowerCase()}` : null
        },
        form () {
            return this.drug?.dosage?.form ? this.drug.dosage.form.toLowerCase() : null
        },
        quantity () {
            return this.drug?.quantity
        },
        frequency () {
            return this.drug?.frequency
        },
        goodrxUrl () {
            if (!this.costs) {
                return null
            }
            return `${this.costs?.goodrx?.link}`
        },
        goodrxPrice0 () {
            const p = _.get(this.costs || {}, 'goodrx.prices.0.coupons.0')
            return p == null ? _.get(this.costs || {}, 'goodrx.prices.0.est_cash_price') : p
        },
        costplusdrugsPrice0 () {
            return _.get(this.costs || {}, 'costplusdrugs.prices.0')
        },
        eaglePrice0 () {
            return _.get(this.costs || {}, 'eagle.prices.0')
        },
        costplusdrugs () {
            return _.get(this.costs || {}, 'costplusdrugs.matches')
        },
        eagle () {
            return _.get(this.costs || {}, 'eagle', {})
        },
        medicaregovUrl () {
            return null
        },
        medicaregovPdpPrice0 () {
            return _.get(this.costs || {}, 'medicaregov.pdps.0.drug_costs.0.estimated_yearly_total') / 12 * this.frequencyPerMonths
        },
        needymedsUrl () {
            return `https://www.needymeds.org/search-programs?initialSearchTab=drugs&drugQuery=${this.label}`
        },
        costplusdrugsUrl () {
            return _.get(this.costs || {}, 'costplusdrugs.link')
        },
        eagleUrl () {
            return _.get(this.costs || {}, 'eagle.link')
        },
        key () {
            return this.ndc || this.label
        },
        ndc () {
            return (this.package?.ndc || '').trim()
        },
        frequencyPerDays () {
            return parseInt(this.frequency?.match(/(\d){2}/))
        },
        frequencyPerMonths () {
            return this.frequencyPerDays / 30
        },
    },
    watch: {
        drug: {
            deep: true,
            handler: _.debounce(function () {
                return this.maybeLoadDrugQuotes()
            }, 200)
        },
    },
    mounted () {
        this.setGlobal('$costs', this)
    },
    methods: {
        async loadDrugCosts () {
            this.costs = null
            this.loading = true
            try {
                const costs = await this.$api.getDrugCosts({
                    zip_code: this.zip_code,
                    fips: this.fips,
                    label: this.label,
                    dosage: this.dosage,
                    form: this.form,
                    quantity: this.quantity,
                    frequency: this.frequency,
                    year: this.year,
                    ndc: this.ndc,
                    pharmacies: this.pharmacies?.length ? _.map(this.pharmacies, 'npi').join(',') : null,
                })
                this.costs = costs
            } catch (e) {
                this.$store.$alert(e)
            } finally {
                this.loading = false
            }
        },
        maybeLoadDrugQuotes () {
            if (this.costs) {
                return this.loadDrugCosts()
            }
        },
    },
}
