
export default {
    props: {
        id: {
            type: [String, Number],
            default: null,
        },
    },
    data () {
        return {
            groupData: null
        }
    },
    async fetch () {
        if (this.id) {
            this.groupData = await this.$api.getGroup(this.id)
        }
    }
}
