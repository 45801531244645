
import { mapGetters, mapActions } from 'vuex'

export default {
    props: {
        nextLabel: {
            type: String,
            default () {
                return this.$store.getters.nextSurveyRoute ? 'Next' : 'Done'
            }
        }
    },
    computed: {
        ...mapGetters(['survey', 'uid', 'previousSurveyRoute', 'nextSurveyRoute', 'isAEPScheduling']),
        asAEP () {
            return this.isAEPScheduling && /aep/.test(this.$route.path)
        }
    },
    methods: {
        ...mapActions(['onSurveyDone'])
    }
}
