
export default {
    props: {
        drug: {
            type: Object,
            default: null
        },
        readonly: {
            type: Boolean,
            default: false
        },
    },
    data () {
        return {
            editing: false
        }
    },
}
