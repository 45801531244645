
export default {
    props: {
        plan: {
            type: Object,
            default: null
        },
        collapsibleVisible: {
            type: Boolean,
            default: false
        },
        compact: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        pharmacy0 () {
            return this.plan.drug_cost_by_pharmacy_list[0]
        },
    },
}
