import { render, staticRenderFns } from "./survey.vue?vue&type=template&id=5bc7bcbc&scoped=true&lang=pug&"
import script from "./survey.vue?vue&type=script&lang=js&"
export * from "./survey.vue?vue&type=script&lang=js&"
import style0 from "./survey.vue?vue&type=style&index=0&id=5bc7bcbc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bc7bcbc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/codebuild/output/src60861037/src/frontend/components/Header.vue').default,Body: require('/codebuild/output/src60861037/src/frontend/components/Body.vue').default,Footer: require('/codebuild/output/src60861037/src/frontend/components/Footer.vue').default,ConsultationNotes: require('/codebuild/output/src60861037/src/frontend/components/ConsultationNotes.vue').default,ConsultationChecklist: require('/codebuild/output/src60861037/src/frontend/components/ConsultationChecklist.vue').default,ConsultationScripts: require('/codebuild/output/src60861037/src/frontend/components/ConsultationScripts.vue').default,ConsultationAi: require('/codebuild/output/src60861037/src/frontend/components/ConsultationAi.vue').default,Icon: require('/codebuild/output/src60861037/src/frontend/components/Icon.vue').default})
