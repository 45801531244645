
import _ from 'lodash'

export default {
    props: {
        color: {
            type: String,
            default: 'primary',
        },
        cover: {
            type: Boolean,
            default: false,
        },
        backdrop: {
            type: Boolean,
            default: false,
        },
        fixed: {
            type: Boolean,
            default: false,
        },
        size: {
            type: Number,
            default: 32,
        },
        width: {
            type: Number,
            default: 4,
        },
        messages: {
            type: Array,
            default: () => [],
        },
        interval: {
            type: Number,
            default: 2500,
        },
    },
    data () {
        return { intervalId: null, message: null }
    },
    computed: {
        styles () {
            return {
                width: `${this.size}px`,
                height: `${this.size}px`,
                'border-width': `${this.width}px`,
            }
        },
    },
    mounted () {
        this.startMessages()
    },
    destroyed () {
        this.stopMessages()
    },
    methods: {
        selectMessage () {
            this.message = _.sample(this.messages)
        },
        startMessages () {
            if (this.messages?.length) {
                this.selectMessage()
                this.intervalId = setInterval(this.selectMessage, this.interval)
            }
        },
        stopMessages () {
            clearInterval(this.intervalId)
        },
    },
}
