
import { mapGetters } from 'vuex'
import surveyForm from '~/mixins/survey-form'

export default {
    mixins: [surveyForm],
    autosave: true,
    props: {
        dob: {
            type: Boolean,
            default: true
        },
        location: {
            type: Boolean,
            default: true
        },
        gender: {
            type: Boolean,
            default: true
        },
    },
    data () {
        return {
            form: {
                dob: null,
                manual_age: null,
                zip_code: null,
                fips: null,
                gender: null,
            },
        }
    },
    computed: {
        ...mapGetters(['genders']),
    },
    methods: {
        onDobOrAge ($event) {
            if ($event) {
                this.$set(this.form, 'dob', $event.dob)
                this.$set(this.form, 'manual_age', $event.manual_age)
            } else {
                this.$set(this.form, 'dob', null)
                this.$set(this.form, 'manual_age', null)
            }
        },
        onLocation ($event) {
            if ($event) {
                this.$set(this.form, 'zip_code', $event.zip_code)
                this.$set(this.form, 'fips', $event.fips)
            } else {
                this.$set(this.form, 'zip_code', null)
                this.$set(this.form, 'fips', null)
            }
        }
    }
}
