

export default {
    props: {
        hint: {
            type: Object,
            default: null
        },
    },
    data () {
        return {
            messages: this.hint?.message.split('\n')
        }
    },
    watch: {
        hint () {
            this.messages = this.hint?.message.split('\n')
        }
    }
}
