import _ from 'lodash'

const createTry = function ({ store, $axios, $api, $sentry }) {
    return async (method, ...args) => {
        let ret
        let isFull = false
        const options = args[args.length - 1] || {}
        const { spinner, delay, alert } = options
        try {
            if (spinner !== false) {
                await store.dispatch('busy')
            }
            if (typeof $api[method] === 'function') {
                ret = await $api[method](...args)
            } else {
                isFull = true
                ret = await $axios[method](...args)
            }
        } catch (e) {
            console.error(e)
            if (alert !== false) {
                $sentry.captureException(e)
                await store.dispatch('alert', e)
            }
            return { error: store.getters.parseError(e) }
        } finally {
            if (delay) {
                setTimeout(() => {
                    store.dispatch('idle')
                }, delay)
            } else {
                await store.dispatch('idle')
            }
        }
        if (isFull) {
            return ret?.data || ret
        }
        return ret
    }
}

export default (context, inject) => {
    const $try = createTry(context)
    inject('try', $try)
    inject('alert', (e) => {
        return context.store.dispatch('alert', e)
    })
    inject('unalert', () => {
        return context.store.dispatch('unalert')
    })
    inject('danger', (message) => {
        return context.store.dispatch('alert', { variant: 'danger', ...(_.isObject(message) ? message : { message }) })
    })
    inject('success', (message) => {
        return context.store.dispatch('alert', { variant: 'success', ...(_.isObject(message) ? message : { message }) })
    })
    inject('info', (message) => {
        return context.store.dispatch('alert', { variant: 'info', ...(_.isObject(message) ? message : { message }) })
    })
    inject('warning', (message) => {
        return context.store.dispatch('alert', { variant: 'warning', ...(_.isObject(message) ? message : { message }) })
    })
    inject('busy', (message) => {
        return context.store.dispatch('busy', message)
    })
    inject('idle', () => {
        return context.store.dispatch('idle')
    })
}
